import { useState } from "react";
import "./DateSelector.css";

function DateSelector({
	handleChange,
}: {
	handleChange: (days: number) => void;
}) {
	const [activeTab, setActiveTab] = useState(1);

	const items = [
		{
			label: "Last day",
			key: 1,
		},
		{
			label: "Last week",
			key: 7,
		},
		{
			label: "Last month",
			key: 30,
		},
	];

	const handleMenuClick = (key: number) => {
	
		setActiveTab(key);
		handleChange(key);
	};

	return (
		<div className="date-selector">
			{items.map((item) => (
				<p
					key={item.key}
					className={`date-selector-btn ${
						item.key === activeTab ? "active" : ""
					}`}
					onClick={() => handleMenuClick(item.key)}
				>
					{item.label}
				</p>
			))}
		</div>
	);
}

export default DateSelector;
