import { Route, BrowserRouter, Routes } from "react-router-dom";
import HomePage from "../HomePage/homePage";
// import Login from "../Screens/Auth/Login/login";
import Dashboard from "../Screens/Dashboard/dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../Screens/Auth/Login/login";
import AuthRoute from "./AuthRoute";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<AuthRoute Component={Login} />} />

        {/* <Route
          path="/dashboard"
          element={<ProtectedRoute Component={Dashboard} />}
        /> */}
        <Route
          path="/dashboard"
          element={<Dashboard/>}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
