import React, { useEffect, useState } from "react";
import {
  fetchAllUsernames,
  fetchIncludedUsername,
  fetchIncludedUsers,
} from "../../../SupabaseServices/UserDatabase";
import CreatorFilter from "../../../Components/CreatorFilter/creatorFilter";
import { supabase } from "../../../Config/SupabaseClient";
import { useLoadCustomCategories } from "../../../Utils/CustomHooks/useLoadCustomCategories";
import CustomCategoriesSection from "./CustomCategoriesSection";
import "./customCategories.css";
import { Button } from "antd";
import CustomCategoryModal from "./CustomCategoryModal";
import { useLoadCreators } from "../Creators/useLoadCreators";
import { useQuery } from "react-query";
import PuffLoader from "react-spinners/PuffLoader";
const CustomCategories = () => {
  const page = "admin";
  const [users, setUsers] = useState<any>("");
  const [user_id, setUserId] = useState("");
  const [selectedCreator, setSelectedCreator] = useState<any>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<any>("");
  const [usernames, setUsernames] = useState<any>([]);

  useQuery({
    queryFn: () => {
      fetchIncUsers();
    },
    refetchOnMount: true,
  });
  async function fetchIncUsers() {
    const users = await fetchIncludedUsername();
    const userIds = await fetchIncludedUsers();
    console.log("dvhs", userIds, users);
    setSelectedIds(userIds?.[0]);
    setSelectedCreator(users);
    setUsernames(users);
  }

  const {
    isLoading,
    data,

    refetch: refetchCustomCategories,
  } = useLoadCustomCategories(selectedIds || "", page);
  console.log("sele",  data);

  // async function fetchUsers() {
  //   const users = await fetchAllUsernames();

  //   if (users) {
  //     const obj: any = {};
  //     for (var i = 0; i < users.usersArray.length; i++) {
  //       obj[users.usersArray[i]] = users.controls[i];
  //     }
  //     setUsers(obj);
  //   }
  // }
  return (
    <div className="d-side">
      CustomCategories
      {selectedCreator === undefined || selectedIds === undefined || data ===undefined || data===false? (
        <PuffLoader
          color="#5274f9"
          // loading={loading}
          className="loader"
          size={150}
        />
      ) : data && data.length ? (

        <>
        <p><b> Creator :- {selectedCreator?.[0]}</b></p>
        <Button onClick={() => setIsModalOpen(true)}>Create Category</Button>

          <CustomCategoriesSection
            data={data}
            user_id={selectedIds}
            page="admin"
            refetchCustomCategories={refetchCustomCategories}
            selectedCreator={selectedCreator?.[0]}
          />
        </>
      ) : (
        <>
        <p><b> Creator :- {selectedCreator?.[0]}</b></p>

        <p>No Custom Categories present</p>
        <Button onClick={() => setIsModalOpen(true)}>Create Category</Button>

        </>
      )}
      {/* <CreatorFilter
        allUsers={userData}
        getSelectedCreator={getSelectedCreator}
      /> */}
      <br />
      {/* {selectedCreator && selectedCreator !== "None" ? (
      ) : (
        ""
      )} */}
      <br />
      {/* {selectedCreator && selectedCreator !== "None" ? ( */}
      {/* <>
          {data && data.length ? (
            <>
              <CustomCategoriesSection user_id={user_id} page="admin" />
            </>
          ) : (
            <p>No Custom Categories present</p>
          )}
        </> */}
      {/* ) : (
        <p>No User selected</p>
      )} */}
      <CustomCategoryModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        user_id={selectedIds}
        page="live"
      />
    </div>
  );
};

export default CustomCategories;
