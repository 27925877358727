import React, { useState } from "react";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";
import { supabase } from "../../../../Config/SupabaseClient";
import { Alert, Button } from "antd";

const ProfilePicture = (props: any) => {
  const { user } = props;
  const [postId, setPostId] = useState("");
  const [caption, setCaption] = useState("");
  const [permalink, setPermalink] = useState("");
  const [postDate, setPostDate] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const handleUploadFiles = (files: any) => {
    const uploaded = [...uploadedFiles];
    files.some((file: any) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    setUploadedFiles(uploaded);
  };
  const handleFileEvent = (e: any) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  async function uploadFiles(user: any) {
    const userData: any = await getUserData(user);
    // uploadedFiles.map(async (file: any) => {
    const file = uploadedFiles[0];
    const mediaType = file.type.split("/")[0];
    const mediaExt = file.type.split("/")[1];
    //   let postNumber = Math.floor(Math.random() * 10000 + 1);

    await supabase.storage
      .from("profile-picture")
      .upload(`${user}.${mediaExt}`, file, {
        contentType: `${file.type}`,
      });
    var { data: url }: any = await supabase.storage
      .from("profile-picture")
      .getPublicUrl(`${user}.${mediaExt}`);

    if (url !== "undefined") {
      var { data, error }: any = await supabase
        .from("user")
        .update({ picture: url.publicUrl, storage_url: url.publicUrl })
        .eq("username", user);
      setUploadedFiles([]);
      const files: any = document.getElementById("fileUpload");

      files.value = "";
      // props.setIsModalOpen(false);
    } else {
      <Alert
        type="error"
        message="Error in uploading profile picture"
        showIcon
        className="error-alert"
      />;
    }
    // });
  }
  return (
    <div className="content-wrapper">
      <h5>Upload Profile Picture </h5>

      <input
        id="fileUpload"
        type="file"
        accept="application/pdf, image/png, image/jpeg, video/mp4, image/webp"
        onChange={handleFileEvent}
        className="choose-btn"
        placeholder={uploadedFiles}
        
      />
      <Button type="primary" className="upload-btn primary-color" onClick={() => uploadFiles(user)}>
        {" "}
        Upload Files{" "}
      </Button>
    </div>
  );
};

export default ProfilePicture;
