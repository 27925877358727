import { useQuery } from "react-query";
import { supabase } from "../../Config/SupabaseClient";
import { getUserData } from "../../SupabaseServices/UserDatabase";

/**
 * Load User info
 */
export function useLoadUser(username: any) {
  async function getUser() {
    const userData: any = await getUserData(username);

    return userData;
  }

  return useQuery(["getUser", username], getUser, {
    refetchOnWindowFocus: true,
  });
}

