import { useState } from "react";
import { useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadProductBankPages(
  userIds: any,

  postsPerPage: number,
  filter: any,
  usernames: any
) {
  async function getCount() {
    let feedCount;
    let postIds;
    const { data, error } = await supabase.rpc("get_productbankcount", {
      query_string: filter,
      userid: userIds
    });
    console.log("count",feedCount)
    feedCount = data?.[0]?.count;
    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
  }

  return useQuery(["paginatedProductBankCount", userIds], getCount, {
    enabled: Boolean(userIds),
  });
}
