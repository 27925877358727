import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";

// Hook for adding or removing tags
export default function useUpdatePermalink() {
	const queryClient = useQueryClient();

	const submitPermalink = async ({
		tagString,
		post_id,
		usernames,
	}: {
		currentPage:any,
		activeKey: string;
		tagString: string;
		post_id: string;
		pageIndex: number;
		index: number;
		filter: string | null;
		usernames:any
	}) => {
		
		const { error }:any = await supabase
			.from("content_new")
			.update({ permalink: tagString })
			.eq("post_id", `${post_id}`);
			const { error:tableError }:any = await supabase
			.from(`${usernames[0]}`)
			.update({ permalink: tagString })
			.eq("post_id", `${post_id}`);
            const { error :instagramTableError} :any= await supabase
			.from("content_instagram")
			.update({ permalink: tagString })
			.eq("post_id", `${post_id}`);
			const { error :adError} :any= await supabase
			.from("ads_content")
			.update({ permalink: tagString })
			.eq("post_id", `${post_id}`);
console.log("permmm error ",error,tableError,instagramTableError,adError)
		if (error || instagramTableError) {
			throw new Error(error.message);
		} else {
			return true;
		}
	};

	// Tag optimistic update
// 	return useMutation(submitPermalink, {
// 		// When mutate is called:
// 		onMutate: async ({currentPage, tagString, pageIndex, index, activeKey, filter }) => {
// 			await queryClient.cancelQueries(["paginatedPosts", activeKey, filter]);
// 			const previousData = queryClient.getQueryData([
// 				"paginatedPosts",
// 				activeKey,
// 				filter,
// 			]);
// 			if (previousData) {
// 				// Deep clone the object, otherwise the objects inside will have the same reference in memory
// 				const newData = JSON.parse(JSON.stringify(previousData)) as {
// 					pageParams: any;
// 					pages: any[];
// 				};
// 				newData.pages[currentPage][index].permalink = tagString;
// 				queryClient.setQueryData(
// 					["paginatedPosts", activeKey, filter],
// 					newData
// 				);
// 			}
// 			return { previousData };
// 		},
// 		// If the mutation fails, use the context returned from onMutate to roll back
// 		onError: (_error, { activeKey, filter }, context) => {
// 			queryClient.setQueryData(
// 				["paginatedPosts", activeKey, filter],
// 				context?.previousData
// 			);
// 		},
// 		// Always refetch after error or success:
// 		onSettled: (_data, _error, { currentPage,pageIndex, activeKey, filter }) => {
// 			queryClient.invalidateQueries(["paginatedPosts", activeKey, filter], {
// 				refetchPage: (page, index) => {
// 					return index === currentPage;
// 				},
// 			});
// 		},
// 	});
// }

return useMutation(submitPermalink, {
    onMutate: async ({ tagString, post_id, usernames }) => {
      await queryClient.cancelQueries("paginatedPosts");

      // Optimistically update the local data
      const previousData = queryClient.getQueryData(["paginatedPosts"]);

      queryClient.setQueryData(["paginatedPosts"], (oldData: any) => {
        if (oldData) {
          // Find and update the specific post with new permalink
          const newData = oldData.map((page: any) =>
            page.map((post: any) =>
              post.post_id === post_id ? { ...post, permalink: tagString } : post
            )
          );
          return newData;
        }
        return oldData;
      });

      return { previousData }; // Return context for rollback
    },
    onError: (error, variables, context) => {
      // Rollback on error by restoring the previous data
      queryClient.setQueryData(["paginatedPosts"], context?.previousData);
    },
    onSettled: () => {
      // Invalidate and refetch the query to update the data
      queryClient.invalidateQueries("paginatedPosts");
    },
  });
}