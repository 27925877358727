import { useEffect, useState } from "react";
import { supabase } from "../../../Config/SupabaseClient";
import { useQuery } from "react-query";
import { getEmbedding } from "../../../SupabaseServices/ContentDatabase";

/**
 *  Use this hook to control feed pagination
 * @param usernames
 * @param index - "active" || "archive"
 * @param postsPerPage
 *
 * @returns
 */
export function usePostsPagination(
  userIds: any[],
  index: string,
  postsPerPage: number,
  filter: any,
  usernames: any
) {
  const getCount = async () => {
    let feedCount;
    if (filter) {
      filter = filter.replace(/[-_\s]/g, " ");

      console.log("ind", index);
      let filterArray = filter
        .trim()
        .split(" ")
        .filter((word: any) => word.trim() !== "")

        .map((word: any) => word.trim());
      // Join the words with |
      let orJoinedFilter = filterArray.join(" | ");
      console.log("1", filter);
      let andfilterArray = filter
        .trim()
        .split(" ")
        .filter((word: any) => word.trim() !== "")
        .map((word: any) => word.trim());

      console.log("2", andfilterArray);
      let andJoinedFilter = andfilterArray.join(" & ");
      console.log("fdbs and", andJoinedFilter);
      if (index === "Insta Highlights") {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardcontentcounthighlights",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            query_str: orJoinedFilter,
            platform: ["instagram"],
            post_type: ["HIGHLIGHTS"],
            exact_str: andJoinedFilter,
          }
        );
        console.log("fdbs", count[0]);
        feedCount = count[0].count;
      } else if (index === "Insta Story") {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardcontentcountposts",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            query_str: orJoinedFilter,
            platform: ["instagram"],
            post_type: ["STORY"],
          }
        );
        feedCount = count[0].count;
      } else if (index === "Youtube") {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardcontentcountposts",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            query_str: orJoinedFilter,
            platform: ["youtube"],
            post_type: ["VIDEO"],
          }
        );
        feedCount = count[0].count;
      } else if (index === "Tiktok") {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardcontentcountposts",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            query_str: orJoinedFilter,
            platform: ["tiktok"],
            post_type: ["TIKTOK"],
          }
        );
        feedCount = count[0].count;
      } else if (index === "Podcast") {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardcontentcountposts",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            query_str: orJoinedFilter,
            platform: ["podcast"],
            post_type: ["PODCAST"],
          }
        );
        feedCount = count[0].count;
      } else if (index === "Pinterest") {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardcontentcountposts",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            query_str: orJoinedFilter,
            platform: ["pinterest"],
            post_type: ["PIN"],
          }
        );
        feedCount = count[0].count;
      } else if (index === "Newsletter") {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardcontentcountposts",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            query_str: orJoinedFilter,
            platform: ["newsletter"],
            post_type: ["MAIL"],
          }
        );
        feedCount = count[0].count;
      } else {
        const { data: count, error } = await supabase.rpc(
          "get_dashboardcontentcountpostss",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: index,
            query_str: orJoinedFilter,
            platform: ["instagram"],
            post_type: ["FEED", "REELS"],
            exact_str: andJoinedFilter,
          }
        );
        console.log("dbsd", count);
        feedCount = count[0].count;
      }
    } else {
      console.log("indd", index);
      if (index === "Insta Highlights") {
        const { data: count } = await supabase.rpc(
          "get_dashboardcontentnofiltercountpost",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            platform: ["instagram"],
            post_type: ["HIGHLIGHTS"],
          }
        );
        console.log("ccc", count);
        feedCount = count[0].count;
      } else if (index === "Insta Story") {
        const { data: count } = await supabase.rpc(
          "get_dashboardcontentnofiltercountpost",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            platform: ["instagram"],
            post_type: ["STORY"],
          }
        );
        console.log("ccc", count);
        feedCount = count[0].count;
      } else if (index === "Youtube") {
        const { data: count } = await supabase.rpc(
          "get_dashboardcontentnofiltercountpost",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            platform: ["youtube"],
            post_type: ["VIDEO"],
          }
        );
        console.log("ccc", count);
        feedCount = count[0].count;
      } else if (index === "Tiktok") {
        const { data: count } = await supabase.rpc(
          "get_dashboardcontentnofiltercountpost",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            platform: ["tiktok"],
            post_type: ["TIKTOK"],
          }
        );
        console.log("ccc", count);
        feedCount = count[0].count;
      } else if (index === "Podcast") {
        const { data: count } = await supabase.rpc(
          "get_dashboardcontentnofiltercountpost",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            platform: ["podcast"],
            post_type: ["PODCAST"],
          }
        );
        console.log("ccc", count);
        feedCount = count[0].count;
      } else if (index === "Pinterest") {
        const { data: count } = await supabase.rpc(
          "get_dashboardcontentnofiltercountpost",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            platform: ["pinterest"],
            post_type: ["PIN"],
          }
        );
        console.log("ccc", count);
        feedCount = count[0].count;
      } else if (index === "Newsletter") {
        const { data: count } = await supabase.rpc(
          "get_dashboardcontentnofiltercountpost",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: "active",
            platform: ["newsletter"],
            post_type: ["MAIL"],
          }
        );
        console.log("ccc", count);
        feedCount = count[0].count;
      } else {
        const { data: count } = await supabase.rpc(
          "get_dashboardcontentnofiltercountpost",
          {
            userid: userIds[0],

            user_name: usernames[0],
            index_type: index,
            platform: ["instagram"],
            post_type: ["FEED", "REELS"],
          }
        );
        console.log("ccc", count);
        feedCount = count[0].count;
      }
    }
    if (feedCount) {
      feedCount += 10;
      // setPages(Math.ceil(feedCount / postsPerPage));
      return { pages: Math.ceil(feedCount / postsPerPage), total: feedCount };
    }
  };
  // const getCount = async () => {
  //   if (userIds.length) {
  //     const { count }: any = await supabase
  //       .from("content_new")
  //       .select("*", { count: "exact", head: true })
  //       .in("user_id", userIds)
  //       .eq("index", index);

  //     if (count) {
  //       setPages(Math.ceil(count / postsPerPage));
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if(userIds && userIds.length){
  //   getCount();
  //   }
  // }, [ index,filter]);

  // return { pages };
  return useQuery(["getFeedPages", userIds, index, filter], getCount, {
    enabled: Boolean(userIds),
    // refetchOnWindowFocus: true,
  });
}
