import { Button, Input, Modal, Pagination, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import SearchInput from "../../../Components/SearchInput/SearchInput";
import { supabase } from "../../../Config/SupabaseClient";
import PuffLoader from "react-spinners/PuffLoader";
import ContentTable from "../Feed/ContentTable";
import { usePostsPagination } from "../Feed/usePostsPagination";
import { useQuery } from "react-query";
import {
  fetchIncludedUsername,
  fetchIncludedUsers,
} from "../../../SupabaseServices/UserDatabase";
import { useLoadContent } from "../Feed/useLoadContent";
import { useFeedFiltersCount } from "../Feed/useFeedFilters";
import { useLoadPages } from "./useLoadPages";
import { useLoadFirstEmbedding } from "./useLoadFirstSimilarity";
import { useLoadEmbeddingCount } from "./useLoadEmbeddingCount";
import { useLoadPosts } from "./useLoadPosts";
import { useLoadEmbeddingPosts } from "./useLoadEmbeddingPosts";
import { useLoadNecData } from "./useLoadNecData";
import ContentSearchTable from "./ContentSearchTable";
import { useLoadUser } from "../../../Utils/CustomHooks/useLoadUser";
const POSTS_PER_PAGE = 50;

const LiveViewSearch = () => {
  const [currentPage, setCurrentPage] = useState(1);

  // Handle page change
  const onPageChange = (page: any) => {
    setCurrentPage(page);
    // You can perform any actions you need when the page changes, such as fetching data for the new page.
  };

  const [usernames, setUsernames] = useState<any>([]);
  const [activeKey, setActiveKey] = useState("active");
  const [selectedCreator, setSelectedCreator] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<any>();
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const { isLoading: userLoading, data: userData }: any = useLoadUser(usernames[0]);

  const { data: pages } = useLoadPages(
    selectedIds || "",
    activeKey,
    POSTS_PER_PAGE,
    activeFilter,
    usernames
  );
  const { data: firstEmbedding }: any = useLoadFirstEmbedding(
    usernames[0] || "",
    selectedIds || "",
    activeFilter,

    ["instagram", "youtube", "tiktok", "podcast", "pinterest", "newsletter"],
    pages?.feedCount
  );

  const { data: pagesEmbed } = useLoadEmbeddingCount(
    selectedIds || "",
    activeKey,
    POSTS_PER_PAGE,
    activeFilter,
    usernames,
    firstEmbedding?.threshold
  );

  useQuery({
    queryFn: () => {
      fetchUsers();
    },
    refetchOnMount: true,
  });
  async function fetchUsers() {
    const users = await fetchIncludedUsername();
    const userIds = await fetchIncludedUsers();
    setSelectedIds(userIds);
    setSelectedCreator(users);
    setUsernames(users);
  }

  const [isLimitError, setLimitError] = useState(false);

  const {
    isLoading,
    // isError,
    error,
    data,
    hasNextPage,
    fetchNextPage,
    // isFetching,
    isFetchingNextPage,
    refetch,
  } = useLoadPosts(
    currentPage,
    activeKey,
    POSTS_PER_PAGE,
    pages?.totalPages,
    activeFilter,
    selectedCreator,
    usernames,
    selectedIds || "",
    pages?.postIds
  );

  const {
    isLoading: necLoading,
    // isError,
    error: necError,
    data: necData,

    refetch: refetchNec,
  }: any = useLoadNecData(
    selectedIds || "",
    activeKey,
    POSTS_PER_PAGE,
    activeFilter,
    usernames
  );
  const {
    isLoading: embedLoading,
    // isError,
    error: embedError,
    data: embedData,
    hasNextPage: hasEmbedNextPage,
    fetchNextPage: fetchEmbedNextPage,
    // isFetching,
    // isFetchingNextPage,
    refetch: refectchEmbed,
  }: any = useLoadEmbeddingPosts(
    currentPage,
    activeKey,
    POSTS_PER_PAGE,
    pagesEmbed?.totalPages,
    activeFilter,
    selectedCreator,
    usernames,
    selectedIds || "",
    pagesEmbed?.postIds,
    firstEmbedding?.threshold
  );

  console.log("first", firstEmbedding);
  console.log("fts", pages, data);
  console.log("nec", necData);
  console.log("embed", pagesEmbed, embedData);
  let necDataIncluded = false;

  const mergedData =
    pages && pages?.totalPages !== 0 && data?.pages
      ? processPages(
          data.pages,
          pages?.feedCount,

          necData,
          embedData?.pages
        )
      : pagesEmbed && pagesEmbed?.totalPages != 0
      ? processEmbeddingPages(
          embedData?.pages,
          pagesEmbed?.feedCount,

          necData
        )
      : necData?.length > 0
      ? processNecData(necData)
      : "";

  function processPages(
    pagesData: any[],
    dataCount: any,
    necData: any[],
    embeddingData?: any[]
  ) {
    return pagesData
      ? pagesData
          .flatMap((page: any) => page)
          .filter((post: any) => post?.post_id)
          .reduce((result: any[], post: any, index: number) => {
            const isAllowedDuplicate = post.index === "active,ad";
            const isDuplicate = result.some(
              (uniquePost) => uniquePost.post_id === post?.post_id
            );

            if (!isDuplicate || isAllowedDuplicate) {
              if (result.length + 1 < dataCount) {
                result.push(post);
              } else {
                result.push(post);

                if (!necLoading) {
                  if (necData && necData.length && !necDataIncluded) {
                    const filteredNecData = necData.filter(
                      (necPost) =>
                        !result.some(
                          (resPost) => resPost?.post_id === necPost?.post_id
                        )
                    );
                    filteredNecData.forEach((necPost) => {
                      result.push(necPost);
                    });
                    necDataIncluded = true;
                  } else {
                    if (embeddingData?.length) {
                      embeddingData
                        .flatMap((page: any) => page)
                        .filter((post: any) => post?.post_id)
                        .reduce((embedResult: any[], post: any) => {
                          const isAllowedDuplicate = post.index === "active,ad";
                          const isDuplicate = result.some(
                            (uniquePost) => uniquePost.post_id === post?.post_id
                          );
                          if (!isDuplicate || isAllowedDuplicate) {
                            result.push(post);
                          }
                        });
                    }
                  }
                }
                if (necDataIncluded && embeddingData?.length) {
                  embeddingData
                    .flatMap((page: any) => page)
                    .filter((post: any) => post?.post_id)
                    .reduce((embedResult: any[], post: any) => {
                      const isAllowedDuplicate = post.index === "active,ad";
                      const isDuplicate = result.some(
                        (uniquePost) => uniquePost.post_id === post?.post_id
                      );
                      if (!isDuplicate || isAllowedDuplicate) {
                        result.push(post);
                      }
                    });
                }
              }
            }

            return result;
          }, [])
      : "";
  }
  function processEmbeddingPages(
    pagesData: any[],
    dataCount: any,

    necData: any[]
  ) {
    return pagesData
      ? pagesData
          .flatMap((page: any) => page)
          .filter((post: any) => post?.post_id)
          .reduce((result: any[], post: any) => {
            const isAllowedDuplicate = post?.index === "active,ad";
            const isDuplicate = result.some(
              (uniquePost) => uniquePost?.post_id === post?.post_id
            );
            if (!isDuplicate || isAllowedDuplicate) {
              if (userData?.is_nec_present) {
              console.log("usndsd", post, necData, necLoading);

              if (
                necData !== undefined &&
                necData &&
                necData.length &&
                !necDataIncluded
              ) {
                const filteredNecData = necData.filter(
                  (necPost) =>
                    !result.some(
                      (resPost) => resPost?.post_id === necPost?.post_id
                    )
                );
                // result.push(...filteredNecData);
                filteredNecData.forEach((necPost) => {
                  result.push(necPost);

                  // Add an ad after every 10 posts
                });
                necDataIncluded = true;
              } else {
                if (
                  !result.some((resPost) => resPost?.post_id === post?.post_id)
                ) {
                  result.push(post);
                }
              }

              if (necDataIncluded) {
                if (
                  !result.some((resPost) => resPost?.post_id === post?.post_id)
                ) {
                  result.push(post);
                }
              }
            } else {
              if (
                !result.some((resPost) => resPost?.post_id === post?.post_id)
              ) {
                result.push(post);
              }
            }
            }

            return result;
          }, [])
      : "";
  }
  function processNecData(necData: any[]) {
    return necData
      ? necData
          .flatMap((page: any) => page)
          .filter((post: any) => post?.post_id)
          .reduce((result: any[], post: any, index: number) => {
            const isAllowedDuplicate = post.index === "active,ad";
            const isDuplicate = result.some(
              (uniquePost) => uniquePost.post_id === post?.post_id
            );

            if (!isDuplicate || isAllowedDuplicate) {
              if (necData && necData.length && !necDataIncluded) {
                const filteredNecData = necData.filter(
                  (necPost) =>
                    !result.some(
                      (resPost) => resPost?.post_id === necPost?.post_id
                    )
                );
                // result.push(...filteredNecData);
                filteredNecData.forEach((necPost) => {
                  result.push(necPost);

                  // Add an ad after every 10 posts
                });
                necDataIncluded = true;
              }
            }

            return result;
          }, [])
      : "";
  }

  console.log("merged", pages?.feedCount + pagesEmbed?.feedCount, mergedData);
  useEffect(() => {
    refetch();
  }, [selectedCreator]);
  return (
    <div>
      <div className="d-side">
      <div className="details">
        <h1 className="dashboard-mobile-hide">Live View Search</h1>
      </div>

      {activeKey === "active" ? (
        <p>
          Note:{" "}
          <i>
            Active content, if relevant, will be shown in search results while
            Archived content will never be shown in search results.
          </i>
        </p>
      ) : (
        ""
      )}

      <div className="searc-input">
        <SearchInput
          placeholder={`Search on ${activeKey} tab`}
          id="search-feed"
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setActiveFilter(null);
                return;
              }
              elem.blur();
              setActiveFilter(searchVal);
            }
          }}
          isActiveFilter={Boolean(activeFilter)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setActiveFilter(searchVal);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setActiveFilter(null);
          }}
        />
      </div>
      {/* {isLoading || !Boolean(data?.pages) && !Boolean(embedData?.pages) ? (
        <PuffLoader
          color="#5274f9"
          loading={isLoading}
          className="loader"
          size={150}
        />
      ) : (
        <>
          <ContentTable
            usernames={usernames}
            activeKey={activeKey}
            data={mergedData}
            setLimitError={setLimitError}
            filter={activeFilter}
            refetchData={refetch}
            userIds={selectedIds}
            currentPage={currentPage}
          />
          <div style={{ textAlign: "center", marginTop: "6%" }}>
            <Pagination
              current={currentPage}
              total={pages?.feedCount + pagesEmbed?.feedCount} // Set the total number of items
              pageSize={POSTS_PER_PAGE}
              onChange={onPageChange}
              showSizeChanger={false}
              showQuickJumper // Optionally, you can hide the page size changer
            />
          </div>
        </>
      )} */}

      {!isLoading && // Render only if not loading
        (!data?.pages && !embedData?.pages && !necLoading ? ( // Check if data or embedData is not available
          <div>No data available</div>
        ) : (
          <>
            <ContentSearchTable
              usernames={usernames}
              activeKey={activeKey}
              data={mergedData}
              setLimitError={setLimitError}
              filter={activeFilter}
              refetchData={refetch}
              userIds={selectedIds}
              currentPage={currentPage}
              refectchEmbed={refectchEmbed}
              refetchNec={refetchNec}
            />
            <div className="pagination">
              <Pagination
                current={currentPage}
                total={pages?.feedCount + pagesEmbed?.feedCount}
                pageSize={POSTS_PER_PAGE}
                onChange={onPageChange}
                showSizeChanger={false}
                showQuickJumper
              />
            </div>
          </>
        ))}
        </div>
    </div>
  );
};

export default LiveViewSearch;
