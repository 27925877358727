import { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { Tooltip } from "react-tooltip"
import "./Account.css";
import { useAuth } from "../../../hooks/useAuth";
import Username from "./AccountSection/Username";
import Name from "./AccountSection/Name";
import Email from "./AccountSection/Email";
import Phone from "./AccountSection/Phone";
import ProfilePicture from "./AccountSection/ProfilePicture";
import AccountSkeleton from "../../../Components/skeleton/DashboardView/accountSkeleton";
import { useLoadUser } from "../../../Utils/CustomHooks/useLoadUser";
import { StaticVariables } from "../../../StaticVariables";
import AccountDescription from "../../../Components/AccountDescription/AccountDescription";
import IntegrationsBox from "../../../Components/IntegrationsBox/IntegrationsBox";

const Account = (props: any) => {
  const { user: sessionUser } = useAuth();
  const { username,user_id } = props;
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);
console.log("dfjdf",username,user_id)
  const {
    isLoading: userLoading,
    data: userData,
    refetch,
  }: any = useLoadUser(username);

  return (
    <div>
      {userLoading ? (
        <AccountSkeleton />
      ) : (
        <>
        <div className="d-side">
          <div className="details">
            <h1 className="dashboard-mobile-hide">
              {StaticVariables().ACCOUNT_TITLE}
            </h1>
            <div className="account-values-grid">
              <Username username={username} sessionUser={sessionUser} />
              <Name
                username={username}
                userData={userData}
                setIsName={props.setIsName}
                user_id={user_id}
              />

              <Email
                username={username}
                userData={userData}
                setIsEmail={props.setIsEmail}
                setIsEmailVerified={props.setIsEmailVerified}
                user_id={user_id}
              />
              <Phone
                username={username}
                userData={userData}
                setIsPhone={props.setIsPhone}
                user_id={user_id}
              />
              {/* <ProfilePicture
                username={username}
                userData={userData}
                setIsPhone={props.setIsPhone}
                user_id={sessionUser?.id}
              /> */}
              <div>
                <a
                  href="https://payments.searchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
                  target="_blank"
                >
                  <button className="manage-subscription">
                    Manage Subscription
                  </button>
                </a>
              </div>
            </div>
            <div className="account-values-profiles">
              <ProfilePicture username={username} userData={userData} refetch={refetch} />
            </div>
            {/* <div className="account-values-profiles">
             
             <SearchBarText isLoading={userLoading} userData={userData} refetch={refetch}/>
             </div>
             <div className="account-values-profiles">

             <SearchBarTags searchBarText={userData?.search_bar_text?userData?.search_bar_text:`Search ${userData?.name} Content`} username={userData?.username} isLoading={userLoading} userData={userData} refetch={refetch}/>
            </div> */}
            <div className="account-values-profiles">
              <AccountDescription
                isLoading={userLoading}
                userData={userData}
                refetch={refetch}
              />
            </div>

            <div className="account-values-profiles">
              <div className="account-social-logins-heading">
                <h5>Social Integrations</h5>
              </div>
              <IntegrationsBox
                subscription_type={userData?.subscription_type}
                first_connected_platform={userData?.first_connected_platform}
                user_id={user_id}
              />
            </div>
          </div>
          </div>
        </>
      )}
      <Tooltip
        className="tool-tip"
        id="displayName"
        place={"top"}
        offset={4}
        html="How your name will be shown on your page."
      />
      <Tooltip
        className="tool-tip"
        id="username"
        place={"top"}
        offset={4}
        html="This is your Bubble username."
      />
      <Tooltip
        className="tool-tip"
        id="email"
        place={"top"}
        offset={4}
        html="This is the email connected to your account."
      />
      <Tooltip
        className="tool-tip"
        id="phone"
        place={"top"}
        offset={4}
        html="This is the phone number connected to your account."
      />
    </div>
  );
};

export default Account;
