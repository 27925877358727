import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";

import data from "../../../Utils/CountryCodes.json";
import {
  signUpPhone,
  verifyPhoneNumberSignIn,
} from "../../../SupabaseServices/UserDatabase";
import ErrorMsg from "../../../Components/ErrorMsg/errorMsg";

const PhoneLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [signedUp, setSignedUp] = useState(false);
  const [phone, setPhone] = useState<any>();
  const [error, setError] = useState<any>("");
  const [phnWithCode, setPhnWithCode] = useState<any>("");
  const [resendOTP, setResendOTP] = useState(false);
  const [loading, setLoading] = useState(false);

  const countryCodes = data;
  const [code, setCode] = useState("+1");

  const handleOption = (e: any) => {
    const phn = e.target.value;
    setCode(phn);
  };

  function loginSuccess(name: any) {
    localStorage.setItem("name", name);

    localStorage.setItem("loggedIn", "true");
    navigate(`/dashboard`);
  }

  async function signUpWithPhone(e: any) {
    setLoading(true);
    const phoneNumber = code + phone;

    setPhnWithCode(phoneNumber);
    e.preventDefault();
    const isSignUp = await signUpPhone(phoneNumber);

    if (isSignUp) {
      setSignedUp(true);
      setTimeout(() => {
        setResendOTP(true);
      }, 0);
    } else {
      setError("Enter valid phone number.");
    }
    setLoading(false);
  }

  function SubmitButton(props: any) {
    if (isFinite(phone)) {
      return <button className="submit-btn">{props.name}</button>;
    } else {
      return (
        <button className="submit-btn" disabled>
          {props.name}
        </button>
      );
    }
  }
  async function validateOtp(e: any) {
    setError("");
    e.preventDefault();
    const otpCode = e.target.value;

    if (otpCode.length === 6) {
      setLoading(true);
      const { error: verifyTokenError, message: verifyTokenMessage } =
        await verifyPhoneNumberSignIn(phnWithCode, otpCode, loginSuccess);
      if (verifyTokenError && verifyTokenMessage) {
        setError(verifyTokenMessage);
        setLoading(false);
      }
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="auth-phone-signup-container">
        {!signedUp ? (
          <form className="input-form" onSubmit={signUpWithPhone}>
            <div className="input-div">
              <Dropdown id="dropdown">
                <select
                  onChange={(e: any) => handleOption(e)}
                  className="option-select"
                >
                  {countryCodes.map((elem: any, idx: any) => (
                    <option key={idx} value={elem.dial_code}>
                      {elem.name}
                    </option>
                  ))}
                </select>
              </Dropdown>{" "}
              <input
                placeholder="Enter Phone Number"
                onChange={(e: any) => {
                  if (error) {
                    setError("");
                  }
                  setPhone(e.target.value);
                }}
                required
              />
            </div>

            {error ? <ErrorMsg error={error} /> : ""}

            <SubmitButton name="Send Verification Code" />
          </form>
        ) : (
          <form className="input-form">
            <div className="input-div">
              {" "}
              <input
                placeholder="Enter Verification Code"
                onChange={(e: any) => validateOtp(e)}
                required
              />
            </div>
            <p className="otp-text">Enter 6 digit OTP</p>
            {resendOTP ? (
              <div className="resend-msg">
                <p>
                  Didn't get the code?{" "}
                  <button
                    className="resend-btn"
                    onClick={async (e) => {
                      e.preventDefault();
                      const res = await signUpPhone(phnWithCode);
                      if (!res) {
                        setError(
                          "You only can request a code once every 60 seconds"
                        );
                      }
                      setResendOTP(false);
                    }}
                  >
                    Resend OTP
                  </button>
                </p>
              </div>
            ) : (
              ""
            )}
            {error ? <ErrorMsg error={error} /> : ""}
          </form>
        )}
      </div>
    </Spin>
  );
};

export default PhoneLogin;
