import { supabase } from "../Config/SupabaseClient";

export async function addProduct(
  name: any,
  url: any,
  image: any,
  user_id: any,
  platform: any,
  category: any,createdDate:any
) {

  function generateRandom12DigitNumber() {
    // Generate a random number between 100000000000 and 999999999999
    return (
      Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
      1000000000000000
    );
  }

  // Example usage:
  let randomNumber = generateRandom12DigitNumber();
  const { data, error } = await supabase.from("product_bank").insert([
    {
      name,
      url,
      image,
      user_id,
      product_id: randomNumber,
      platform,
      category,
      created_at: createdDate  // Only include created_at if createdDate is defined
    },
  ]);
  if (error !== null) {
    function generateRandom12DigitNumber() {
      // Generate a random number between 100000000000 and 999999999999
      return (
        Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
        1000000000000000
      );
    }

    // Example usage:
    let randomNumber = generateRandom12DigitNumber();
    const { data, error } = await supabase.from("product_bank").insert([
      {
        name,
        url,
        image,
        user_id,
        product_id: randomNumber,
        platform,
        category,
        created_at: createdDate  // Only include created_at if createdDate is defined

      },
    ]);
  }
}

// export async function addProductBulk(
//   username: any,
//   user_id: any,
//   name: any,
//   url: any,
//   image: any,
//   tags: any,
//   category: any,
//   platform: any,
//   price:any
// ) {
//   const categoryUpload= category.split(" ")[0];
//   if (image && image != "") {
//     let response: any;
//     try {
//       response = await fetch(image);
//     } catch (err) {
    
//     }

//     const imageBlob = await response.blob();
//     const mediaType = imageBlob.type.split("/")[0];
//     const mediaExt = imageBlob.type.split("/")[1];
//     function generateRandom12DigitNumber() {
//       // Generate a random number between 100000000000 and 999999999999
//       return (
//         Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
//         1000000000000000
//       );
//     }

//     // Example usage:
//     let post_id = generateRandom12DigitNumber();
//     const { data, error }: any = await supabase.storage
//       .from("product_bank")
//       .upload(`${username}/${categoryUpload}/${mediaType}${post_id}`, imageBlob, {
//         contentType: `${imageBlob.type}`,
//       });
//     if (error != null) {
//       function generateRandom12DigitNumber() {
//         // Generate a random number between 100000000000 and 999999999999
//         return (
//           Math.floor(
//             Math.random() * (9999999999999999 - 1000000000000000 + 1)
//           ) + 1000000000000000
//         );
//       }

//       // Example usage:
//       let post_id = generateRandom12DigitNumber();
//       const { data, error }: any = await supabase.storage
//         .from("product_bank")
//         .upload(`${username}/${categoryUpload}/${mediaType}${post_id}`, imageBlob, {
//           contentType: `${imageBlob.type}`,
//         });
//     }

//     var { data: imageUrl }: any = await supabase.storage
//       .from("product_bank")
//       .getPublicUrl(`${username}/${categoryUpload}/${mediaType}${post_id}`);

//     const { data: store, error: storeError } = await supabase
//       .from("product_bank")
//       .insert([
//         {
//           name,
//           url,
//           image: imageUrl.publicUrl,
//           user_id,
//           product_id: post_id,
//           category: category,
//           tagg: tags,
//           platform,
//           price
//         },
//       ]);
//   }
// }


export async function addProductBulk(
  username: any,
  user_id: any,
  name: any,
  url: any,
  image: any,
  tags: any,
  category: any,
  platform: any,
  price: any
) {
  const categoryUpload = category.split(" ")[0];

  // Default dummy image URL in case image is empty or null
  const dummyImageUrl = "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/dummy_image/ProductBank/download.jpeg";
  let imageUrl = dummyImageUrl; // Set the image URL to the dummy by default
  let post_id = generateRandom16DigitNumber(); // Generate unique post_id once

  // Function to generate a random 16-digit number
  function generateRandom16DigitNumber() {
    return (
      Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
      1000000000000000
    );
  }

  // Check if image is provided and non-empty, then fetch and upload it
  if (image && image !== "") {
    try {
      const response = await fetch(image);
      const imageBlob = await response.blob();
      const mediaType = imageBlob.type.split("/")[0];
      const mediaExt = imageBlob.type.split("/")[1];

      const { data, error } = await supabase.storage
        .from("product_bank")
        .upload(
          `${username}/${categoryUpload}/${mediaType}${post_id}`,
          imageBlob,
          { contentType: `${imageBlob.type}` }
        );

      if (!error) {
        const publicUrlData = await supabase.storage
          .from("product_bank")
          .getPublicUrl(`${username}/${categoryUpload}/${mediaType}${post_id}`);
        imageUrl = publicUrlData.data.publicUrl; // Update imageUrl if upload succeeds
      } else {
        console.error("Error uploading image:", error.message);
      }
    } catch (err:any) {
      console.error("Error fetching image:", err.message);
    }
  }

  // Insert the product into the database with the image URL
  const { data: store, error: storeError } = await supabase
    .from("product_bank")
    .insert([
      {
        name,
        url,
        image: imageUrl, // Use either the uploaded image URL or the dummy URL
        user_id,
        product_id: post_id,
        category,
        tagg: tags,
        platform,
        price
      },
    ]);

  if (storeError) {
    console.error("Error storing product:", storeError.message);
  }
}
