import { useQueries, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  getPageAnalytics,
  getPostClicksAnalytics,
  getPostClicksViewsAnalytics,
} from "../../../../SupabaseServices/AnalyticsDatabase";

function useGraphicalAnalytics(
  username: any,
  user_id: any,
  startDate: any,
  endDate: any,
  isYesterday: any,
  daysNumber: any
) {
  //Based on usernames

  const { data: pageAnalytics, isLoading: loadingPageAnalytics } = useQuery(
    [`overview-page-analytics`, username, startDate],
    () => {
      return getPageAnalytics(
        username,
        startDate,
        endDate,
        user_id,
        isYesterday,
        daysNumber
      );
    },
    { refetchOnWindowFocus: true }
  );

  return {
    loadingPageAnalytics,
    pageAnalytics,
  };
}

export default useGraphicalAnalytics;
