import React from "react";

const ErrorMsg = (props:any) => {
  return (
    <div className="error-msg">
      <p>{props.error}</p>
    </div>
  );
};

export default ErrorMsg;
