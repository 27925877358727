import { Pagination } from "antd";
import "../Analytics.css";
import { useState } from "react";

function PostClicksTable(props: any) {
  const { postClicks } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  return (
    <>
      {postClicks && postClicks.length > pageSize ? (
        <>
          <div className="post-clicks-container">
            <table className="analytics-table">
              <thead>
                <tr>
                  <th>Post</th>
                  <th>Clicks</th>
                </tr>
              </thead>
              <tbody>
                {postClicks
                  ?.slice(startIndex, endIndex)
                  ?.map((oneKey: any, index: any) => (
                    <tr key={index}>
                      <td>
                        <div className="analytics-postClicks-thumbnail-div">
                          {oneKey.additionalData &&
                          oneKey.additionalData.media_url ? (
                            oneKey.additionalData.media_url
                              .split(",")[0]
                              .includes("video") ? (
                              <img
                                className="analytics-postClicks-thumbnail"
                                src={
                                  oneKey.additionalData.thumbnail.split(",")[0]
                                }
                                alt="feed"
                              />
                            ) : (
                              <img
                                className="analytics-postClicks-thumbnail"
                                src={
                                  oneKey.additionalData.media_url.split(",")[0]
                                }
                                alt="feed"
                              />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td>{oneKey.count}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <Pagination
              current={currentPage}
              total={postClicks?.length}
              pageSize={pageSize}
              onChange={handlePageChange}
              className="text-center"
            />
          </div>
        </>
      ) : (
        <table className="analytics-table">
          <thead>
            <tr>
              <th>Post</th>
              <th>Clicks</th>
            </tr>
          </thead>

          {postClicks?.map((oneKey: any, index: any) => (
            <tbody key={index}>
              <tr>
                <td>
                  <div className="analytics-postClicks-thumbnail-div">
                    {oneKey.additionalData &&
                    oneKey.additionalData.media_url ? (
                      oneKey.additionalData.media_url
                        .split(",")[0]
                        .includes("video") ? (
                        <img
                          className="analytics-postClicks-thumbnail"
                          src={oneKey.additionalData.thumbnail.split(",")[0]}
                          alt="feed"
                        />
                      ) : (
                        <img
                          className="analytics-postClicks-thumbnail"
                          src={oneKey.additionalData.media_url.split(",")[0]}
                          alt="feed"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </td>
                <td>{oneKey.count}</td>
              </tr>
            </tbody>
          ))}
        </table>
      )}
    </>
  );
}

export default PostClicksTable;
