
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useGraphicalAnalytics from "../Overview/useGraphicalAnalytics";
import { Spin } from "antd";

const Charts = ({
  username,
  user_id,
  startDate,
  endDate,
  isYesterday,
  daysNumber,
}: any) => {
  const { loadingPageAnalytics, pageAnalytics } = useGraphicalAnalytics(
    username,
    user_id,
    startDate,
    endDate,
    isYesterday,
    daysNumber
  );

  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip tooltip"
        >
          <p className="date-format">{data.formatted_date}</p>
          <p className="user-chart">
            Users: {data.users}
          </p>
          <p className="search-chart">
            Searches: {data.searches}
          </p>
          <p className="clicks-chart">
            Shoppable link clicks: {data["links"]}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomLegend = () => (
    <div className="custom-legend">
      <div className="mr-20">
        <div className="search-chart">
          <ul>
            <li> Searches</li>
          </ul>
        </div>
        {/* <div style={{ width: "20px", height: "2px", background: "#404040" }} /> */}
      </div>
      <div className="mb-20">
        {/* <div style={{ width: "20px", height: "2px", background: "#8685EF" }} /> */}

        <div className="user-chart">
          <ul>
            <li> Users</li>
          </ul>
        </div>
        {/* <div style={{ width: "20px", height: "2px", background: "#8685EF" }} /> */}
      </div>

      <div>
        <div className="clicks-chart">
          <ul>
            <li>Shoppable links clicks</li>
          </ul>
        </div>
        {/* <div style={{ width: "20px", height: "2px", background: "#00C6CF" }} /> */}
      </div>
    </div>
  );

  return (
    <div
      className="page-loader"
    >
      <div className="full-div">
        {loadingPageAnalytics ? (
          <div
            className="page-loader"
          >
            <Spin />
          </div>
        ) : (
          <ResponsiveContainer width="99%" height={550}>
            <div>
              <LineChart
                data={pageAnalytics}
                width={640}
                height={550}
                margin={{
                  top: 15,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="formatted_date" stroke="grey" />
                <YAxis stroke="grey" />
                <Tooltip content={renderCustomTooltip} />
                <Legend content={CustomLegend} />
                <Line
                  type="monotone"
                  dataKey="users"
                  stroke="#8685EF"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="searches" stroke="#404040" />
                {/* Change dataKey to your custom key */}
                <Line type="monotone" dataKey="links" stroke="#00C6CF" />
              </LineChart>
            </div>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default Charts;
