import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { Switch, Modal } from "antd";
import { HiTrash } from "react-icons/hi";

import { useParams } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { deleteInstagram, deleteNewsletter, deletePinterest, deletePodcast, deleteTiktok, deleteYoutube } from "../../Screens/Dashboard/Account/UserDatabase";

const IntegrationPlatforms = (props: any) => {
  const { username } = useParams();
  const {
    platformName,
    isConnected,
    isProcessing,
    isPlatformEnabled,
    onTogglePlatform,
    onConnectPlatform,
    onDeletePlatform,
    showConfirmation,
    platformTodelete,
    userId,
    refetchData,
    subscription_type,
    first_connected_platform,
  } = props;
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const handleCancelDelete = () => {
    props.setShowConfirmation(false);
  };
  const handleConfirmDelete = async (platform: any) => {
    // Add your delete logic here

    switch (platform) {
      case "Instagram":
        // Call the function for Instagram delete
        // onDeleteInstagram();
        await deleteInstagram(username, userId).then(() => {
          refetchData();
        });
        break;

      case "Youtube":
        // Call the function for Youtube delete
        // onDeleteYoutube();
        await deleteYoutube(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Podcast":
        // Call the function for Podcast delete
        // onDeletePodcast();
        await deletePodcast(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Newsletter":
        // Call the function for Newsletter delete
        // onDeleteNewsletter();
        await deleteNewsletter(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Tiktok":
        // Call the function for Tiktok delete
        // onDeleteTiktok();
        await deleteTiktok(username, userId).then(() => {
          refetchData();
        });

        break;

      case "Pinterest":
        // Call the function for Pinterest delete
        // onDeletePinterest();
        await deletePinterest(username, userId).then(() => {
          refetchData();
        });

        break;

      // Add other cases for different platforms if needed

      default:
        // Handle the default case
        break;
    }

    // Set the confirmation state to false
    props.setShowConfirmation(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleOk = () => {
    return (
      // <div>
      <a
        href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
        target="_blank"
      >
        <button className="upgrade-subscription">Upgrade</button>
      </a>
      // {/* </div> */}
    );
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  function disabledPlatform() {}
  return (
    <>
      <div className="account-social-content-connect-row-platform">
        <div className="account-social-content-connect-row">
          <div className="account-social-content-connect-row-cont">
            <p style={{ opacity: isConnected ? 1 : 0.5 }}>{platformName==="Twitter"?"X":platformName}</p>
            {first_connected_platform === platformName ? (
              <>
                {isConnected ? (
                  !isProcessing ? (
                    <div className="account-social-content-connect-status">
                      <p>Processing</p>
                    </div>
                  ) : (
                    <div className="account-social-content-connect-status active">
                      <BsCheck className="font-16" />
                    </div>
                  )
                ) : (
                  <div
                    className="account-social-content-connect-status"
                    onClick={onConnectPlatform}
                  >
                    <p>Connect</p>
                  </div>
                )}
              </>
            ) : subscription_type === "basic" ? (
              first_connected_platform === platformName ? (
                <>
                  {isConnected ? (
                    !isProcessing ? (
                      <div className="account-social-content-connect-status">
                        <p>Processing</p>
                      </div>
                    ) : (
                      <div className="account-social-content-connect-status active">
                        <BsCheck className="font-16" />
                      </div>
                    )
                  ) : (
                    <div
                      className="account-social-content-connect-status"
                      onClick={onConnectPlatform}
                    >
                      <p>Connect</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div
                    className="lock-style"
                  >
                    <FaLock
                      style={{ color: "grey" }}
                      onClick={() => {
                        setIsModalOpen(true);
                        setSelectedPlatform(platformName);
                      }}
                    />
                    {/* <a
                    href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className="upgrade-subscription"
                      style={{ fontSize: "0.8rem" }}
                    >
                      Upgrade
                    </button>
                  </a> */}
                  </div>
                </>
              )
            ) : (
              <>
                {isConnected ? (
                  !isProcessing ? (
                    <div className="account-social-content-connect-status">
                      <p>Processing</p>
                    </div>
                  ) : (
                    <div className="account-social-content-connect-status active">
                      <BsCheck className="font-16" />
                    </div>
                  )
                ) : (
                  <div
                    className="account-social-content-connect-status"
                    onClick={onConnectPlatform}
                  >
                    <p>Connect</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="platform-switch">
          <Switch
            style={{
              backgroundColor: isPlatformEnabled ? "green" : "rgb(241, 98, 98)",
            }}
            checked={isPlatformEnabled}
            onChange={onTogglePlatform}
            disabled={!isConnected}
          />
          {/* <HiTrash
            style={{
              fontSize: "19px",
              color: isConnected === false ? "grey" : "#414141",
              float: "right",
            }}
            onClick={isConnected ? onDeletePlatform : disabledPlatform}
            aria-disabled={isConnected === false}
            // aria-disabled={isConnected===false?true:false}
          /> */}
        </div>
      </div>
      {showConfirmation && platformTodelete === platformName && (
        <div className="confirmation-dialog">
          <p className="confirmation-dialog-p">
            Are you sure you want to delete {platformName}'s integration and its
            data?
          </p>
          <button className="confirm-btn cancel" onClick={handleCancelDelete}>
            Cancel
          </button>
          <button
            className="confirm-btn confirm confirm-button"
            onClick={() => handleConfirmDelete(platformName)}
          >
            Confirm
          </button>
        </div>
      )}
      <hr
        className="height-space"
      />
      <Modal
        title={`Upgrade to Unlock ${selectedPlatform} Integration`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="modal-p">
          {`Unlocking the Social Integrations will allow you to integrate your ${selectedPlatform}'s data in the feed.`}
        </p>
        <div className="text-right">
          <a
            href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
            target="_blank"
          >
            <button className="upgrade-subscription">Upgrade</button>
          </a>
        </div>
      </Modal>
    </>
  );
};

export default IntegrationPlatforms;
