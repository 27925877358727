import { useState } from "react";
import { useQuery } from "react-query";

import dayjs from "dayjs";
import { supabase } from "../../../Config/SupabaseClient";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadPages(
  userIds: any[],
  index: string,
  postsPerPage: number,
  filter: any,
  usernames: any
) {
  //   filter = filter.toLowerCase().replace(/\s+/g, " ").trim();

  async function getCount() {
    let feedCount;
    let postIds: any = [];

    if (userIds && userIds.length === 1) {
      if (filter) {
        const elementsCount = 5000;
        // const embedding = await getEmbedding(queryTerm);
        const connectingWords = [
          "and",
          "or",
          "but",
          "if",
          "because",
          "although",
          "since",
          "while",
          "when",
          "after",
          "before",
          "as",
          "that",
          "which",
          "who",
          "whom",
          "whose",
          "where",
          "what",
          "how",
          "the",
          "an",
          "a",
          "in",
          "on",
          "at",
          "with",
          "by",
          "for",
          "of",
          "to",
          "from",
          "through",
          "into",
          "during",
          "within",
          "against",
          "between",
          "among",
          "under",
          "above",
          "below",
          "over",
          "throughout",
          "around",
          "beside",
          "beneath",
          "up",
          "down",
          "off",
          "on",
          "out",
          "over",
          "through",
          "together",
          "apart",
          "forward",
          "backward",
          "away",
          "back",
          "with",
          "without",
          "about",
          "for",
          "against",
          "like",
          "unlike",
          "as",
          "with",
          "without",
          "are",
          "is",
          "do",
          "you",
          "am",
          "I",
        ];

        // Split the sentence into words
        let words = filter.split(" ");

        let singleWords: any = [];

        // Filter out connecting words
        const filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );
        if (words.length <= 2) {
          // Join the filtered words back into a sentence
          const result = filteredWords.join(" ");
          const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

          // const embedding = await getEmbedding(pgQuery);
          const { data: countResult, error: countError }: any =
            await supabase.rpc(
              // "semantic_searchfiltercount",
              // "semantic_ssearchcounthybridexact",
              // "semantic_ssearchcountexact",
              // "semantic_ssearchcounthybrid",
              // "semantic_ssearchcountftsss",
              "semantic_ssearchcountftspostidd",
              {
                query_string: pgQuery,
                // query_embedding: embedding,
                similarity_threshold: 0.8,
                match_count: elementsCount,
                userid: userIds[0],
                username: usernames[0],
                socials: [
                  "instagram",
                  "youtube",
                  "tiktok",
                  "podcast",
                  "pinterest",
                  "newsletter",
                ],
                single: singleWords[0] || null,
                plural: singleWords[1] || null,
                // filter:filter
              }
            );
          let resCount = countResult.length;
          postIds = countResult.map((item: any) => item.post_id);

          feedCount = resCount;
        } else {
          feedCount = 0;
        }
      }else{
        const { data: content, error: contentError } = await supabase.rpc(
          "get_generalfffeedcount",
          {
            userid: userIds[0],
            // start_number: start,
            // end_num: end,
            filter: ["FEED","REELS","STORY","HIGHLIGHTS","PIN","VIDEO","PODCAST","TIKTOK"],
            query_str: "",
            user_name: usernames[0],
            socials:     ["instagram", "youtube", "tiktok", "podcast", "pinterest", "newsletter"],
            

            // extra_ids: extraId,
          }
        );
console.log("fdhbfd",content)
        feedCount = content[0].count;
      }
    }

   
    
    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
   
  }

  return useQuery(["getLoadPages", filter, userIds, index], getCount, {
  });
}
