import React, { useState } from "react";
import { supabase } from "../../../../Config/SupabaseClient";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";
import { Alert, Modal } from "antd";
import axios from "axios";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

const ProductsContent = (props: any) => {
  const { user ,setSelectedContentType} = props;
  const [caption, setCaption] = useState("");
  const [shoplink, setShoplink] = useState("");
  const [tags, setTags] = useState("");
const [platform,setPlatform]=useState("")
  const [category, setCategory] = useState("");
  const [postDate, setPostDate] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const handleUploadFiles = (files: any) => {
    const uploaded = [...uploadedFiles];
    files.some((file: any) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    setUploadedFiles(uploaded);
  };
  const [isModalOpen, setIsModalOpen] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleFileEvent = (e: any) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  async function uploadFiles(user: any) {
    const userData: any = await getUserData(user);
    uploadedFiles.map(async (file: any) => {
      const mediaType = file.type.split("/")[0];
      let post_id = file?.name?.split("._")[0];
      let tagsToProcess = tags.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );
      const now = new Date();
      const post_date = `${now.getFullYear()}-${String(
        now.getMonth() + 1
      ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")} ${String(
        now.getHours()
      ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(
        now.getSeconds()
      ).padStart(2, "0")}+00`;

      console.log(post_date);

      console.log("fbdfd", post_id, post_date, caption, file);

      await supabase.storage
        .from("content")
        .upload(`${user}/${mediaType}${post_id}`, file, {
          contentType: `${file.type}`,
        });
      var { data: url }: any = await supabase.storage
        .from("content")
        .getPublicUrl(`${user}/${mediaType}${post_id}`);

      if (url !== "undefined") {
        var { data, error }: any = await supabase
          .from("content_product")
          .insert([
            {
              post_id: post_id,
              user_id: userData.user_id,
              provider_id: userData.id,
              caption: caption ? caption : " ",
              media_url: url.publicUrl,
              type: "PRODUCT",
              storage_url: url.publicUrl,
              //   highlights_category: category,
              post_date: post_date,
              //   permalink: permalink ? permalink : "",
              shop_link: shoplink ? shoplink : "",
            },
          ])
          .select("*");

        await supabase.from("content_new").insert([
          {
            post_id: post_id,
            user_id: userData.user_id,
            caption: caption ? caption : " ",
            media_url: url.publicUrl,
            type: "PRODUCT",
            platform: platform,
            post_date: post_date,
            raw_content_id: data[0].id,
            // content_category: category,
            // permalink: permalink ? permalink : "",
            shop_link: shoplink ? shoplink : "",
            tagg:`|${platform}|` + ',' + modifiedArray.toString(),
            last_edited: new Date().toISOString(),
            combined_text: caption ? caption : " ",
          },
        ]);
        // const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
        // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;
        const thumbnailUrl = `https://dev.thesearchbubble.com/api/thumbnail`;

        axios.post(
          thumbnailUrl,
          { id: userData.user_id, user: user, from: "content" },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        // console.log("res", resThumbnail.data);
        alert("uploading product");
        // await supabase
        // .from("user")
        // .update({ show_instagram: true,is_instagram_on:true })
        // .eq("user_id", userData.user_id);
        setCaption("");
        setShoplink("");
        setPostDate("");
        setCategory("");
        setPlatform("")
        setTags("");
        setUploadedFiles([]);
        const files: any = document.getElementById("fileUpload");

        files.value = "";
        // props.setIsModalOpen(false);
        // setIsModalOpen(false);
      } else {
        <Alert
          type="error"
          message="Error in uploading products"
          showIcon
          style={{ margin: "10px 0" }}
        />;
      }
    });
  }
  console.log("opoen", isModalOpen);
  return (
    <>
      <Modal
        title="Upload Products"
        open={isModalOpen}
        onOk={() => {
          uploadFiles(user);
          setUploadedFiles([]);
        }}
        onCancel={handleCancel}
        okText="Upload"
      >
        <span className="content-block">
          <p className="input-title">Caption (if any):-</p>
          <input
            className="Caption-input input-value"
            type="text"
            placeholder="enter Caption"
            value={caption}
            onChange={(e: any) => setCaption(e.target.value)}
          />
        </span>
        <span className="content-block">
          <p className="input-title">platform (if any):-</p>
          <input
            className="Caption-input input-value"
            type="text"
            placeholder="enter Platform"
            value={platform}
            onChange={(e: any) => setPlatform(e.target.value)}
          />
        </span>
        <span className="content-block">
          <p className="input-title">Shoplink (if any):-</p>
          <input
            className="permalink-input input-value"
            type="text"
            placeholder="enter shoplink"
            value={shoplink}
            onChange={(e: any) => setShoplink(e.target.value)}
          />
        </span>
        <br />
        <span className="content-block">
          <p className="input-title">
            Tags:- (Put all tags with comma at once)
          </p>
          <input
            required
            className="permalink-input input-value"
            type="text"
            placeholder="enter tags"
            onChange={(e: any) => setTags(e.target.value)}
            value={tags}
          />
        </span>
        <br />

        <br />
        <input
          id="fileUpload"
          type="file"
          accept="application/pdf, image/png, image/jpeg, video/mp4, image/webp"
          onChange={handleFileEvent}
          placeholder={uploadedFiles}
        />
      </Modal>
    </>
  );
};

export default ProductsContent;
