import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  fetchIncludedUsername,
  fetchIncludedUsers,
} from "../../../SupabaseServices/UserDatabase";
import PuffLoader from "react-spinners/PuffLoader";
import AnalyticsTabs from "./analyticsTab";

const Analytics = () => {
  const [usernames, setUsernames] = useState<any>([]);
  const [selectedCreator, setSelectedCreator] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<any>();
  useQuery({
    queryFn: () => {
      fetchUsers();
    },
    refetchOnMount: true,
  });
  async function fetchUsers() {
    const users = await fetchIncludedUsername();
    const userIds = await fetchIncludedUsers();
    setSelectedIds(userIds);
    setSelectedCreator(users);
    setUsernames(users);
  }
  return (
    <div>
      {selectedCreator && selectedCreator.length ? <AnalyticsTabs username={usernames[0]} user_id={selectedIds[0]}/> : <PuffLoader
          color="#5274f9"
        //   loading={userLoading}
          className="loader"
          size={150}
        /> }
    </div>
  );
};

export default Analytics;
