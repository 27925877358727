import { Input, Modal } from "antd";
import React, { useState } from "react";
import useUpdateCategory from "./useUpdateCategoryQueries";
import { useQueryClient } from "react-query";
import { createCustomCategoryColumn } from "../../../SupabaseServices/ControlsDatabase";

const CustomCategoryModal = (props: any) => {

const queryClient = useQueryClient();
  const { isModalOpen, user_id ,page} = props;
  const [categoryName, setCategoryName] = useState("");
  const [terms, setTerms] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { mutate: updateCategory } = useUpdateCategory();
  const handleOk = () => {
    createCustomCategoryColumn(user_id, categoryName);
    queryClient.invalidateQueries(['useLoadCustomCategories', user_id,page]);
    setCategoryName("");
   
    props.setIsModalOpen(false);
  };

  const handleCancel = () => {
    setCategoryName("");
    props.setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="Create Custom Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Create"
      >
        <p>Enter Category Name:</p>
        <Input
          required
          value={categoryName}
          placeholder="Category name"
          onChange={(e: any) => {
            setCategoryName(e.target.value);
          }}
        />

        {errorMsg ? <p  className="errorMsg">{errorMsg}</p> : ""}
      </Modal>
    </div>
  );
};

export default CustomCategoryModal;
