import { Fragment, useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useLoadContent } from "./useLoadContent";
import { usePostsPagination } from "./usePostsPagination";
import { Button, Input, Modal, Pagination, Space, Tabs } from "antd";
import "../../../Styles/feedTab.css";
import ContentTable from "./ContentTable";
import SearchInput from "../../../Components/SearchInput/SearchInput";
import {
  fetchIncludedUsername,
  fetchIncludedUsers,
} from "../../../SupabaseServices/UserDatabase";
import CreatorFilter from "../../../Components/CreatorFilter/creatorFilter";
import { useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";
import { useFeedFiltersCount } from "./useFeedFilters";
import DatePickerRange from "../../../Components/DatePicker/DatePicker";

// use this constant to determine how many posts per page we will display
const POSTS_PER_PAGE = 25;

const FeedTab = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Set the number of items per page

  // Handle page change
  const onPageChange = (page: any) => {
    setCurrentPage(page);
    // You can perform any actions you need when the page changes, such as fetching data for the new page.
  };
  const [usernames, setUsernames] = useState<any>([]);
  const [activeKey, setActiveKey] = useState("active");
  const [selectedCreator, setSelectedCreator] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<any>();
  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  const { data: pages } = usePostsPagination(
    selectedIds || "",
    activeKey,
    POSTS_PER_PAGE,
    activeFilter,
    usernames
  );
  useQuery({
    queryFn: () => {
      fetchUsers();
    },
    refetchOnMount: true,
  });
  async function fetchUsers() {
    const users = await fetchIncludedUsername();
    const userIds = await fetchIncludedUsers();
    setSelectedIds(userIds);
    setSelectedCreator(users);
    setUsernames(users);
  }

  const [isLimitError, setLimitError] = useState(false);

  const handleOk = () => {
    setLimitError(false);
  };

  const {
    isLoading,
    // isError,
    error,
    data,
    hasNextPage,
    fetchNextPage,
    // isFetching,
    isFetchingNextPage,
    refetch,
  } = useLoadContent(
    currentPage,
    activeKey,
    POSTS_PER_PAGE,
    pages?.pages,
    activeFilter,
    selectedCreator,
    usernames,
    selectedIds || ""
  );

  const mergedData = data?.pages
    ? data.pages.flatMap((page: any) => page)
    : // .filter((post: any) => post?.id)
      [];
  // const mergedData = data?.pages
  //   ? Array.from(
  //       new Set(
  //         data.pages
  //           .flatMap((page: any) => page)
  //           .filter((post: any) => post?.id)
  //           .map((post: any) => post.id)
  //       )
  //     ).map((postId: any) =>
  //       data.pages
  //         .flatMap((page: any) => page)
  //         .find((post: any) => post.id === postId)
  //     )
  //   : [];
  function getSelectedCreator(creator: any) {
    if (creator === "All") {
      setSelectedCreator(usernames);
    } else {
      setSelectedCreator([creator]);
    }
  }
  useEffect(() => {
    refetch();
  }, [selectedCreator]);
  const onChangeTab = (key: string) => {
    setActiveKey(key);
  };

  const navTabs = [
    {
      key: "active",
      label: "Active",
    },
    {
      key: "active,ad",
      label: "Ads",
    },
    {
      key: "archived",
      label: "Archive",
    },
  ];
  const { isLoading: filtersLoading, data: filters } = useFeedFiltersCount(
    selectedIds || "",
    usernames
  );

  // Check if filters are available and not loading
  if (!filtersLoading && filters) {
    // Assuming filters is an array of objects with 'key' and 'label'
    filters?.forEach((filter: any) => {
      navTabs.push({
        key: filter,
        label: filter,
      });
    });
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [tag, setTag] = useState("");

  const handleApplyOk = async () => {
    setConfirmLoading(true);
    let filterArray: any = activeFilter
      ?.split(" ")
      .map((word: any) => word.trim());

  
    let dataFilter = data?.pages ? data.pages.flatMap((page: any) => page) : [];
    // let error = errorFilter;

    for (let [i, post] of dataFilter.entries()) {
      const post_id = post.post_id;

      // Perform the update for each post
      const { data: ct } = await supabase
        .from("content_new")
        .select("*")
        .eq("post_id", post_id)
        .eq("user_id", selectedIds[0]);
      let tagsToProcess = tag.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );

      // Stringify the modified array
      const resultString = modifiedArray.toString();
      // tagsToProcess.forEach((oneTag:any) => {

      const { data: updateData, error: updateError } = await supabase
        .from(`${usernames[0]}`) // Replace "your_table_name" with your actual table name
        .update({
          tagg: ct?.[0].tagg + "," + resultString,
          text: ct?.[0]?.text + " " + resultString,
        }) // Replace "your_updated_tagg_value" with the updated tagg value
        .eq("post_id", post_id);


        let combine = "";
        // if (data && data.length > 0) {
          combine += ct?.[0].caption || "";
          combine += " ";
          combine += ct?.[0].content_category || "";
          combine += " ";
          combine += ct?.[0].ocr || "";
          combine += " ";
          combine += ct?.[0].tagg || "";
          combine += " ";
          combine += resultString || "";
        // }
        // combine += " ";
        // combine += ocrText || "";

      await supabase
        .from("content_new") // Replace "your_table_name" with your actual table name
        .update({
          tagg: ct?.[0].tagg + "," + resultString,
          combined_text:combine,
            // ct?.[0].caption +
            // " " +
            // ct?.[0].content_category +
            // " " +
            // ct?.[0].ocr +
            // " " +
            // ct?.[0].tagg +
            // "," +
            // resultString,
            last_edited:new Date().toISOString()

        }) // Replace "your_updated_tagg_value" with the updated tagg value
        .eq("post_id", post_id)
        .eq("user_id", selectedIds[0]);

      console.log("applyyyy", i);
    }
    setConfirmLoading(false);
    setIsModalOpen(false);
    const elem: any = document.getElementById("search-feed");
    elem.value = "";
    setTag("");
    setActiveFilter("");
    refetch();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsReplaceModalOpen(false);
  };
  function applyToAll() {
    setIsModalOpen(true);
  }
  const [oldTag, setOldTag] = useState("");
  const [newTag, setNewTag] = useState("");

  function replace() {
    setIsReplaceModalOpen(true);
  }
  async function search() {
    setActiveFilter(oldTag);
    const { data, error } = await supabase
      .from("content_new")
      .select("*")
      .ilike("tagg", `%${oldTag}%`)
      .eq("user_id", selectedIds[0]);
    console.log("replacing data", data?.length);
  }
  async function handleReplaceOk() {
    setConfirmLoading(true);
    const { data, error }: any = await supabase
      .from("content_new")
      .select("*")
      .ilike("tagg", `%${oldTag}%`)
      .eq("user_id", selectedIds[0]);
    for (let post of data) {
      const post_id = post.post_id;

      // Perform the update for each post
      const { data: ct } = await supabase
        .from("content_new")
        .select("*")
        .eq("post_id", post_id)
        .eq("user_id", selectedIds[0]);

      // Replace oldTag with newTag in tagg
      // const updatedTagg = ct?.[0].tagg.replace(`|${oldTag}|`, `|${newTag}|`);
      const updatedTagg = ct?.[0].tagg.replace(
        new RegExp(`\\|${oldTag}\\|`, "g"),
        newTag.trim() ? `|${newTag.trim()}|` : ""
      );
      // Update tagg and combined_text
      const { data: updateData, error: updateError } = await supabase
        .from(`${usernames[0]}`)
        .update({
          tagg: updatedTagg,
        })
        .eq("post_id", post_id);

      const { data } = await supabase
        .from(`${usernames[0]}`)
        .select("*")
        .eq("post_id", `${post_id}`)
        .limit(1);
      const { error } = await supabase
        .from(`${usernames[0]}`)
        .update({ text: data?.[0]?.text.replace(oldTag, updatedTagg) })
        .eq("post_id", `${post_id}`);

      // Update content_new table
      await supabase
        .from("content_new")
        .update({
          tagg: updatedTagg,
          combined_text: ct?.[0].combined_text.replace(oldTag, newTag),
          last_edited:new Date().toISOString()

        })
        .eq("post_id", post_id)
        .eq("user_id", selectedIds[0]);
    }

    setConfirmLoading(false);
    setIsReplaceModalOpen(false);
    const elem: any = document.getElementById("search-feed");
    elem.value = "";
    setTag("");
    setOldTag("");
    setNewTag("");
    setActiveFilter("");
    refetch();
  }
  return (
    <div>
    <div className="d-side">
       <div className="details">
        <h1 className="dashboard-mobile-hide">Content</h1>
        <p>Selected Creator :- {usernames[0]}</p>
        <Tabs
          defaultActiveKey="1"
          items={navTabs}
          onChange={onChangeTab}
          type="card"
        />
       </div>

      {activeKey === "active" ? (
        <p>
          Note:{" "}
          <i>
            Active content, if relevant, will be shown in search results while
            Archived content will never be shown in search results.
          </i>
        </p>
      ) : (
        ""
      )}

      <div className="search-input">
        <SearchInput
          placeholder={`Search on ${activeKey} tab`}
          id="search-feed"
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setActiveFilter(null);
                return;
              }
              elem.blur();
              setActiveFilter(searchVal);
            }
          }}
          isActiveFilter={Boolean(activeFilter)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setActiveFilter(searchVal);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setActiveFilter(null);
          }}
        />
        <Space.Compact
          className="space-style"
        >
          <Input
            placeholder="Enter tag"
            onChange={(e: any) => setTag(e.target.value)}
            value={tag}
          />
          <Button
            onClick={applyToAll}
          >
            Apply to All
          </Button>
        </Space.Compact>

        <div style={{ display: "flex" }}>
          <Space.Compact
            className="space-style mr-4"
          >
            <Input
              placeholder="Enter tag to be replaced"
              onChange={(e: any) => setOldTag(e.target.value)}
              value={oldTag}
            />
            <Button
              onClick={search}
            >
              Search
            </Button>
          </Space.Compact>
          <Space.Compact
            className="space-style mr-4"
          >
            <Input
              placeholder="new tag"
              onChange={(e: any) => setNewTag(e.target.value)}
              value={newTag}
            />
          </Space.Compact>
          <Space.Compact
            className="space-style"
          >
            <Button
              onClick={replace}
            >
              Replace
            </Button>
          </Space.Compact>
        </div>
        {/* <CreatorFilter getSelectedCreator={getSelectedCreator} /> */}
      </div>
      {isLoading || !Boolean(data?.pages) ? (
        <PuffLoader
          color="#5274f9"
          loading={isLoading}
          className="loader"
          size={150}
        />
      ) : (
        // <InfiniteScroll
        //   dataLength={
        //     mergedData.length
        //     // data?.pages.reduce((acc: any, page: any) => acc + page.length, 0) ||
        //     // POSTS_PER_PAGE
        //   }
        //   next={fetchNextPage}
        //   scrollThreshold={0.7}
        //   hasMore={Boolean(hasNextPage)}
        //   loader={
        //     <>
        //       {isFetchingNextPage ? (
        //         <ClipLoader
        //           color="#5274f9"
        //           loading={hasNextPage}
        //           className="feed-loader"
        //           size={30}
        //         />
        //       ) : null}
        //     </>
        //   }
        //   endMessage={
        //     <p style={{ textAlign: "center" }}>
        //       <b>Yay! You have seen it all</b>
        //     </p>
        //   }
        // >
        <>
          <DatePickerRange />

          <ContentTable
            usernames={usernames}
            activeKey={activeKey}
            data={data}
            setLimitError={setLimitError}
            filter={activeFilter}
            refetchData={refetch}
            userIds={selectedIds}
            currentPage={currentPage}
          />
          {/* // </InfiniteScroll> */}
          <div className="pagination">
            <Pagination
              current={currentPage}
              total={pages?.total} // Set the total number of items
              pageSize={POSTS_PER_PAGE}
              onChange={onPageChange}
              showSizeChanger={false}
              showQuickJumper // Optionally, you can hide the page size changer
            />
          </div>
        </>
      )}
      <Modal
        title=" More than 2 posts cannot be added as ad."
        open={isLimitError}
        onOk={handleOk}
        className="content-modal"
      ></Modal>
      <Modal
        open={isModalOpen}
        onOk={handleApplyOk}
        onCancel={handleCancel}
        okText="Apply"
        confirmLoading={confirmLoading}
      >
        Are you sure you want to apply tag "{tag}" to all the posts?
      </Modal>
      <Modal
        open={isReplaceModalOpen}
        onOk={handleReplaceOk}
        onCancel={handleCancel}
        okText="Replace"
        confirmLoading={confirmLoading}
      >
        Are you sure you want to replace tag "{oldTag}" with tag "{newTag}"?
      </Modal>
      </div>
    </div>
  );
};

export default FeedTab;
