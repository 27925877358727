import { Button, Input, Modal, Popconfirm, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { BsFillTrashFill, BsInfoCircle } from "react-icons/bs";
import useUpdateCategoryQueries from "./useUpdateCategoryQueries";

import { HiPencil } from "react-icons/hi";
import { AiOutlineDelete } from "react-icons/ai";
import { useLoadCustomCategories } from "../../../Utils/CustomHooks/useLoadCustomCategories";
import TagsBox from "../../../Components/TagsBox/TagsBox";
import {
  changeCustomCategoriesOrder,
  changeSubcategoriesOrder,
  deleteCategory,
  editCategory,
  toggleCategoryValue,
} from "../../../SupabaseServices/CustomCategoriesDatabase";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TagsBoxDrag from "../../../Components/TagsBox/TagsBoxDrag";

const CustomCategoriesSection = (props: any) => {
  const { data, user_id, page,selectedCreator } = props;
console.log(selectedCreator)
  const [currentCategory, setCurrentCategory] = useState<any>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [newCategoryName, setNewCategoryName] = useState("");

  const handleDeleteOk = () => {
    deleteCategory(user_id, currentCategory);
    setIsDeleteModalOpen(false);
    alert("category deleted")
    props.refetchCustomCategories();
  };
  const handleEditOk = () => {
    editCategory(user_id, currentCategory, newCategoryName,selectedCreator);
    setIsEditModalOpen(false);
    props.refetchCustomCategories();
  };
  const handleCancel = () => {
    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
    setNewCategoryName(currentCategory.name ? currentCategory.name : "");
  };

  // const {
  //   isLoading,
  //   data,

  //   refetch: refetchCustomCategories,
  // } = useLoadCustomCategories(user_id, page);

  const { mutate: updateCategory } = useUpdateCategoryQueries();
  async function removeTag(tagArr: any, tag: any, categoryName: any) {
    const idx = tagArr.indexOf(tag);
    tagArr.splice(idx, 1);

    updateCategory({
      categoryName,
      tag,
      tagString: tagArr.toString(),
      user_id,
      action: "remove",
      pageIndex: 1,
      index: 1,
    });
  }
  function submitTag(e: any, categoryName: any, tagArray: any, tag: any) {
    e.preventDefault();
    tag = tag.trim();
    if (e.keyCode === 13) {
      let arrString = "";
      if (!tagArray) {
        arrString = tag;
      } else {
        arrString = [tag, ...tagArray.split(",")].toString();
      }
      if (categoryName) {
        updateCategory({
          categoryName,
          tag,
          tagString: arrString,
          user_id,
          action: "submit",
          pageIndex: 1,
          index: 1,
        });
      }

      e.target.value = "";
    }
  }
  const toggleValues = async (
    checked: boolean,
    categoryName: any,
    pageName: any
  ) => {
    await toggleCategoryValue(checked, categoryName, pageName, user_id).then(
      () => {
        props.refetchCustomCategories();
      }
    );
  };

  function deleteCustomCategory(category: any) {
    setIsDeleteModalOpen(true);
    setCurrentCategory(category);
  }

  function editCustomCategory(category: any) {
    setIsEditModalOpen(true);
    setCurrentCategory(category);
  }

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const targetIndex = result.destination.index;
    const draggedCategory = result.draggableId;

    const newData = [...data];
    const [removed] = newData.splice(result.source.index, 1);
    newData.splice(result.destination.index, 0, removed);
    // setIsCategoryLoading(true)
    await changeCustomCategoriesOrder(newData, user_id).then(() => {
      props.refetchCustomCategories();
      setTimeout(() => {
        // setIsCategoryLoading(false)
      });
    });
  };

  const handleDragEnd = async (result: any, tagsArray: any, category: any) => {
    if (!result.destination) {
      return;
    }
    // Reorder the tagsArray based on the drag result
    const updatedTagsArray = Array.from(tagsArray);
    const [removed] = updatedTagsArray.splice(result.source.index, 1);
    updatedTagsArray.splice(result.destination.index, 0, removed);
    await changeSubcategoriesOrder(updatedTagsArray, user_id, category).then(
      () => {
        props.refetchCustomCategories();
      }
    );
    // Update the state with the new order
    // You may need to use a state management library for this if not using React Hooks
    // Example: setTagsArray(updatedTagsArray);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="categories">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="categories"
          >
            <>
              {data &&
                data.length &&
                data.map((element: any, index: any) => (
                  // <div
                  //   className="dashboard-grid-item"
                  //   style={{ marginTop: "10px", marginBottom: "40px" }}
                  // >
                  <Draggable
                    key={element.name}
                    draggableId={element.name}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="dashboard-grid-item"
                      >
                        <span>
                          <p>
                            {element.name}{" "}
                            <BsInfoCircle data-tooltip-id="showFilters" />{" "}
                            <span className="custom-category-functions">
                              <HiPencil
                                onClick={() => editCustomCategory(element)}
                              />
                              <AiOutlineDelete
                                className="errorMsg"
                                onClick={() => deleteCustomCategory(element)}
                              />
                            </span>
                          </p>
                          <Switch
                            style={{
                              backgroundColor: element.value
                                ? "green"
                                : "rgb(241, 98, 98)",
                            }}
                            checked={element.value}
                            onChange={(e: any) =>
                              toggleValues(e, element.name, page)
                            }
                          />
                        </span>
                        <div className="controls-tab-row-queries">
                          <div className="custom-category-queryInput">
                            <input
                              type="text"
                              id="tagInput"
                              enterKeyHint="enter"
                              placeholder="Enter queries here"
                              className="tagInput tag-input"
                              onKeyUp={(e: any) => {
                                submitTag(
                                  e,
                                  element.name,
                                  element.queries,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <div
                            className="queries"
                          >
                            {element.queries ? (
                              // <TagsBox
                              //   tagsArray={
                              //     element.queries
                              //       ? element.queries.split(",")
                              //       : [""]
                              //   }
                              //   removeTag={(tag: string) => {
                              //     removeTag(
                              //       element.queries
                              //         ? element.queries.split(",")
                              //         : [""],
                              //       tag,
                              //       element.name
                              //     );
                              //   }}
                              // />
                              <DragDropContext
                                onDragEnd={(result) =>
                                  handleDragEnd(
                                    result,
                                    element?.queries.split(","),
                                    element.name
                                  )
                                }
                              >
                                <TagsBoxDrag
                                  tagsArray={
                                    element.queries
                                      ? element.queries.split(",")
                                      : [""]
                                  }
                                  removeTag={(tag: string) => {
                                    removeTag(
                                      element.queries
                                        ? element.queries.split(",")
                                        : [""],
                                      tag,
                                      element.name
                                    );
                                  }}
                                  // data={data}
                                  // category={element.name}
                                />
                              </DragDropContext>
                            ) : (
                              <p
                                className="noTags font-1"
                              >
                                No Queries Added yet!
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                  // </div>
                ))}
              <Modal
                open={isDeleteModalOpen}
                onOk={handleDeleteOk}
                onCancel={handleCancel}
                okText="Delete"
              >
                <div className="delete-question-wrapper">
                  <p>
                    Are you sure you want to delete "{currentCategory?.name}"
                    custom category?
                  </p>
                </div>
              </Modal>
              <Modal
                title={`Edit ${currentCategory.name}'s name`}
                open={isEditModalOpen}
                onOk={handleEditOk}
                onCancel={handleCancel}
                okText="Save"
              >
                <div className="edit-input-wrapper">
                  <Input
                    type="text"
                    placeholder={currentCategory.name}
                    // value={currentCategory.name?currentCategory.name:newCategoryName}
                    onChange={(e): any => {
                      setNewCategoryName(e.target.value);
                    }}
                    value={
                      newCategoryName ? newCategoryName : currentCategory.name
                    }
                  />
                </div>
              </Modal>
            </>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CustomCategoriesSection;
