import { Alert, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react'
import { useAuth } from '../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import validator from "validator";
import { storeNewsletterChannel } from '../ContentDatabase';


const NewsletterIntegrationModal = (props:any) => {
    const { user } = useAuth();
    const {username}=useParams()
    const { showNewsletterModal, user_id, data } = props;
    const [newsletter, setNewsletter] = useState(data?.newsletter_email);

    const [errorNewsletter, setErrorNewsletter] = useState("");
    const [loadingNewsletterConnect, setLoadingNewsletterConnect] =useState(false);
    const validateEmail = async (email: string) => {
        if (validator.isEmail(email)) {
          setLoadingNewsletterConnect(true);
          const { error, message } = await storeNewsletterChannel(
            user?.id as string,
            newsletter,
            username
          );
          if (error) {
            setErrorNewsletter(message);
          } else {
            props.refetchData();
            props.setShowNewsletterModal(false);
          }
          setLoadingNewsletterConnect(false);
        } else {
          setErrorNewsletter("Enter valid email.");
        }
      };
     
  return (
    <>
      <Modal
        title="Connect to Newsletter"
        open={showNewsletterModal}
        onOk={() => {
          validateEmail(newsletter);
        }}
        onCancel={() => {
          setErrorNewsletter("");
          props.setShowNewsletterModal(false);
        }}
        okText="Connect"
        okButtonProps={{ loading: loadingNewsletterConnect }}
        cancelButtonProps={{ disabled: loadingNewsletterConnect }}
      >
        <p>
          By connecting your newsletter email, all your mails will be displayed
          as posts in the feed of your main page. In the content tab you will be
          able to hide or delete the mails you don't want to show.{" "}
        </p>
        {loadingNewsletterConnect ? (
          <div
            className='spin-style'
          >
            <Spin />
          </div>
        ) : (
          <>
            {errorNewsletter && (
              <Alert
                type="error"
                message={`Error: ${errorNewsletter}`}
                showIcon
                className="error-alert"
              />
            )}
            {data?.newsletter_email ? (
              <>
                <p>Already connected newsletter : {data?.newsletter_email}</p>
                <p>New email will connection will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect just enter your newsletter email:</p>

            <Input
              placeholder="email"
              value={newsletter}
              onChange={(e) => {
                e.target.value.length
                  ? setNewsletter(e.target.value)
                  : setErrorNewsletter("Enter valid email");
              }}
            />
          </>
        )}
      </Modal></>
  )
}

export default NewsletterIntegrationModal