import {
  Alert,
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
} from "antd";
import React, { useState } from "react";

import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { storeInstagramUser } from "../ContentDatabase";

const InstagramIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showInstagramModal, user_id, data } = props;
  const [instagramUser, setInstagramUser] = useState(data?.instagram_username);
  const [errorInstagram, setErrorInstagram] = useState("");
  const [loadingInstagramConnect, setLoadingInstagramConnect] = useState(false);
  const [integrateInstagramMsg, setIntegrateInstagramMsg] = useState("");

  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  // const onChange = async (e: RadioChangeEvent) => {
  //   setValue(e.target.value);
  // };

  const handleOk = async () => {
    setShowIntegrationModal(false);
  };

  const handleCancel = () => {
    setShowIntegrationModal(false);
  };
  return (
    <>
      <Modal
        title="Connect to Instagram"
        open={showInstagramModal}
        onOk={async () => {
          setLoadingInstagramConnect(true);

          const res = await storeInstagramUser(user?.id as string, instagramUser,username);
          if (!res) {
            setIntegrateInstagramMsg("");
            setErrorInstagram("error while connecting to this Instagram user");
          } else {
            setIntegrateInstagramMsg(
              "Your Instagram data will be integrated in your feed within 24 hours!"
            );
              props.setShowInstagramModal(false);
              setShowIntegrationModal(true)
          }

          setLoadingInstagramConnect(false);
        }}
        onCancel={() => {
          setErrorInstagram("");
          props.setShowInstagramModal(false);

        }}
        okText="Connect"
        okButtonProps={{ loading: loadingInstagramConnect }}
        cancelButtonProps={{ disabled: loadingInstagramConnect }}
      >
        <p>
          By connecting your Instagram username, all your Instagram data will be
          integrated and shown as posts in the feed of your main page. In the
          content tab you will be able to hide or delete the posts you don't
          want to show.{" "}
        </p>
        {loadingInstagramConnect ? (
          <div
            className="spin-style"
          >
            <Spin />
          </div>
        ) : (
          <>
            {errorInstagram && (
              <Alert
                type="error"
                message={`Error: ${errorInstagram}`}
                showIcon
                className="error-alert"
              />
            )}
            {data?.instagram_username ? (
              <>
                <p>
                  <b>Already connected Instagram username: {data?.instagram_username}</b>
                </p>
                <p>New name will connection will replace this one.</p>
              </>
            ) : (
              ""
            )}
            <p>To connect just enter your Instagram username :</p>

            <Input
              placeholder="username"
              // value={InstagramId}
              onChange={(e) => {
                e.target.value.length
                  ? setInstagramUser(e.target.value)
                  : setErrorInstagram("Enter valid name");
              }}
            />
          </>
        )}
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}
      </Modal>
      <Modal
        title={instagramUser ? `${instagramUser}'s Instagrams` : ""}
        open={showIntegrationModal}
        onOk={handleOk}
        okText="Ok"
        onCancel={handleCancel}
      >
        <>
          <p>{integrateInstagramMsg} </p>
        </>
      </Modal>
    </>
  );
};

export default InstagramIntegrationModal;
