import dayjs from "dayjs";
import { useState } from "react";
// import DateSelector from "../../../components/DateSelector/DateSelector";
import Overview from "./Overview/overview";
import Trending from "./Trending/trending";
import { Tabs } from "antd";
import AnalyticsTabOptions from "./AnalyticsTabOptions";
import Charts from "./GraphicalView/Charts";
import { useLoadCustomCategories } from "../../../Utils/CustomHooks/useLoadCustomCategories";
import { useLoadLinks } from "../../../Utils/CustomHooks/useLoadLinks";
import DateSelector from "../../../Components/DateSelector/DateSelector";
import { useLoadCompletePage } from "../../../Utils/CustomHooks/useLoadCompletePage";
const AnalyticsTabs = (props: any) => {
  const { username, user_id, subscription_type } = props;
  const {
    isLoading: customCategoriesLoading,
    data: customCategoriesData,

    refetch: refetchCustomCategories,
  } = useLoadCustomCategories(user_id, "creator");
  const { data, error }: any = useLoadCompletePage(username, "live");
  const {
    isLoading,
    // isError,
    // error,
    data: instagramLinks,

    refetch,
  } = useLoadLinks(user_id);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(100000, "day").format("YYYY-MM-DD")
  );
  const endDate = dayjs(); //today
  const [isYesterday, setIsYesteday] = useState(false);
  const [daysNumber, setDays] = useState<any>();
  function updateDates(days: number) {
    setDays(days);
    if (days === 1) {
      setIsYesteday(true);
    } else {
      setIsYesteday(false);
    }
    if (days > 0) {
      setStartDate(dayjs().subtract(days, "day").format("YYYY-MM-DD"));
    } else {
      setStartDate(dayjs().subtract(100000, "day").format("YYYY-MM-DD"));
    }
  }
  const [activeKey, setActiveKey] = useState("Overview");
  const items = [
    {
      key: "Overview",
      label: "Overview",
      isLoacked: false,
      children: (
        <Overview
          username={username}
          user_id={user_id}
          startDate={startDate}
          endDate={endDate}
          customCategories={customCategoriesData}
          instagramLinks={instagramLinks}
          showSocials={data?.combinedRes?.userData?.showSocials}
        />
      ),
    },
    {
      key: "Trending",
      label: "Trending",
      isLocked: subscription_type == "basic" ? true : false,
      children: (
        <Trending
          username={username}
          user_id={user_id}
          startDate={startDate}
          endDate={endDate}
          customCategories={customCategoriesData?.filter(
            (category: any) =>
              category.queries &&
              category.queries !== null &&
              category.queries !== ""
          )}
          instagramLinks={instagramLinks}
          instagramHighlights={
            data?.combinedRes?.highlights ? data?.combinedRes?.highlights : []
          }
        />
      ),
    },
    {
      key: "Graphical",
      label: "Graphical View",
      isLocked: subscription_type == "basic" ? true : false,
      children: (
        <Charts
          username={username}
          user_id={user_id}
          startDate={startDate}
          endDate={endDate}
          isYesterday={isYesterday}
          daysNumber={daysNumber}
          // loadingPageAnalytics={loadingPageAnalytics}
          // pageAnalytics={pageAnalytics}
        />
        // <Trending
        //   username={username}
        //   user_id={user_id}
        //   startDate={startDate}
        //   endDate={endDate}
        //   customCategories={customCategoriesData?.filter((category:any) => category.queries && category.queries !==null && category.queries !=="" )}
        //   instagramLinks={instagramLinks}
        //   instagramHighlights={data?.combinedRes?.highlights ? data?.combinedRes?.highlights : []}
        // />
      ),
    },
  ];

  return (
    <div className="d-side">
      <div className="details">
        <h1 className="dashboard-mobile-hide mb-20">
          {process.env.REACT_APP_ANALYTICS_TITLE}
        </h1>
        <DateSelector handleChange={updateDates} />
        <div className="row" id="analytics-row">
          {/* <Tabs defaultActiveKey="Overview" items={items} type="card" /> */}
          <AnalyticsTabOptions
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            navTabs={items}
            subscription_type={subscription_type}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsTabs;
