
import { Button, Col, Pagination, Row, Spin, Tabs, Tooltip } from "antd";
import { useMemo, useState } from "react";
import React, { useEffect } from "react";
import "./Charts.css"; // Importing the CSS file from the same folder
import { supabase } from "../../../Config/SupabaseClient";
import { BsFillCheckCircleFill } from "react-icons/bs";

const { TabPane } = Tabs;

function ChartClicksTable(props: any) {
  const { chartClicks, loadingPostClicksAnalytics, username } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20; // Number of items per page
  const [activeTab, setActiveTab] = useState("shop.with.caroline");
  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const totalSum = useMemo(() => {
    const creatorData = chartClicks[activeTab] || [];
    return creatorData.reduce((sum: any, oneKey: any) => sum + oneKey.count, 0);
  }, [chartClicks, activeTab]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setCurrentPage(1); // Reset to first page when switching tabs
  };
  const [copied, setCopied] = useState(false);
  const [copiedPostId, setCopiedPostId] = useState<string | null>(null);

  async function copyLink(obj: any) {
    const { data: user } = await supabase
      .from("user")
      .select("user_id")
      .eq("username", activeTab);

    const { data: doc } = await supabase
      .from(`${activeTab}`)
      .select("*")
      .eq("user_id", user?.[0].user_id)
      .eq("post_id", obj.post_id);
    const { data: product } = await supabase
      .from("product_bank")
      .select("*")
      .eq("user_id", user?.[0].user_id)
      .eq("id", obj.post_id);

    let textToCopy = "";

    // If doc exists, extract the shop_link
    if (doc?.[0]?.shop_link) {
      textToCopy = doc[0].shop_link;
    }
    // If product exists, extract the url
    else if (product?.[0]?.url) {
      textToCopy = product[0].url;
    }

    // If there's text to copy, proceed with copying it to the clipboard
    if (textToCopy) {
      setCopiedPostId(obj.post_id); // Set copiedPostId to the clicked button's post_id

      navigator.clipboard.writeText(textToCopy).then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      });
    } else {
      console.error("No shop_link or url found to copy");
    }
  }
  return (
    <>
      {loadingPostClicksAnalytics ? (
        <div className="spin">
          <Spin />
        </div>
      ) : (
        <>
          <div className="total-count">
            <strong>Clicks : {totalSum}</strong>
          </div>
          <Tabs activeKey={activeTab} style={{color:"black"}} onChange={handleTabChange}>
            {Object.keys(chartClicks).map((creatorName) => (
              <TabPane tab={creatorName} key={creatorName}>
                <Row className="analytics-table">
                  {chartClicks[creatorName]
                    ?.slice(startIndex, endIndex)
                    ?.map((oneKey: any, index: any) => (
                      <div
                        key={index}
                        className="analyticsTable card-style"
                        // style={{ border: "1px solid red" }}
                      >
                        <div>
                          <div className="sup-analytics-grid-item">
                            <div className="analytics-chartClicks-thumbnail-div">
                              <img
                                className="analytics-chartClicks-thumbnail"
                                src={oneKey.media_url?.split(",")[0]}
                                alt="feed"
                              />
                            </div>
                            <div>{oneKey.count}</div>
                          </div>
                        </div>
                        {/* {oneKey.category && <div>{oneKey.category}</div>} : {oneKey.name && <div>{oneKey.name}</div>} */}
                        <div>
                          {oneKey.category && (
                            <span
                              style={{ color: "#1677ff", marginRight: "10px" }}
                            >
                              {oneKey.category}
                            </span>
                          )}
                          {oneKey.category && oneKey.name && " : "}
                          {oneKey.name && (
                            <span style={{ color: "black" }}>
                              {oneKey.name}
                            </span>
                          )}
                        </div>
                        <Tooltip
                          title="Copied!"
                          open={copied && copiedPostId === oneKey.post_id} // Show tooltip only if this button's post_id matches
                          color="green"
                        >
                          <div style={{
                            textAlign:"center"
                          }}
                          >
                          <Button
                            style={{
                              background: "black",
                              color: "white",
                              width: "40%",
                              marginTop: "5%",
                            }}
                            onClick={() => copyLink(oneKey)}
                          >
                            Copy link
                          </Button>
                          </div>
                        </Tooltip>
                      </div>
                    ))}
                </Row>
                {chartClicks[creatorName] &&
                  chartClicks[creatorName].length > pageSize && (
                    <div className="mt-4">
                      <Pagination
                        current={currentPage}
                        total={chartClicks[creatorName]?.length}
                        pageSize={pageSize}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        showQuickJumper
                      />
                    </div>
                  )}
              </TabPane>
            ))}
          </Tabs>
        </>
      )}
    </>
  );
}

export default ChartClicksTable;
