import dayjs from "dayjs";
import { supabase } from "../../Config/SupabaseClient";
import { fetchCreatorControls, fetchFollowerControls, fetchGlobalControls } from "../../SupabaseServices/ControlsDatabase";
import { getPopularSearches } from "../../SupabaseServices/SearchesDatabase";



export async function getUser(username: any) {
  const { data }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", username);

  if (data.length === 0) {
    return false;
  }

  const {
    user_id,
    name,
    storage_url,
    picture,
    email,
    phoneNo,
    instagram_link,
    youtube_link,
    amazon_link,
    podcast_link,
    pinterest_user,
    twitter_link,
    newsletter_email,
    instagram_links,
    is_onboarding_finished,
    is_nec_search,
    avg_caption_words,
    is_instagram_on,
    is_youtube_on,
    is_newsletter_on,
    is_tiktok_on,
    is_podcast_on,
    is_pinterest_on,
    is_nec_present,
    first_connected_platform,
    subscription_type,
  } = data[0];

  const pictureUrl = storage_url || picture;
  const instaLink = instagram_link;
  const youtubeLink = youtube_link;
  const amazonLink = amazon_link;
  const podcastLink = podcast_link;
  const pinterestLink = pinterest_user;
  const twitterLink = twitter_link;
  const newsletterLink = newsletter_email;

  const activeSocials = [
    { term: "instagram", link: instaLink },
    { term: "youtube", link: youtubeLink },
    { term: "amazon", link: amazonLink },
    { term: "podcast", link: podcastLink },
    { term: "pinterest", link: pinterestLink },
    { term: "twitter", link: twitterLink },
    { term: "pinterest" },

    { term: "newsletter" },
  ].filter((social) => social.link);

  const showSocials = [
    { platform: "instagram", isOn: is_instagram_on },
    { platform: "youtube", isOn: is_youtube_on },
    { platform: "podcast", isOn: is_podcast_on },
    { platform: "newsletter", isOn: is_newsletter_on },
    { platform: "tiktok", isOn: is_tiktok_on },
    { platform: "pinterest", isOn: is_pinterest_on },

    // Add other platforms here similarly
  ]
    .filter((social) => social.isOn)
    .map((social) => social.platform);

  return {
    name,
    picture: pictureUrl,
    email,
    phoneNo,
    instaLink,
    youtubeLink,
    amazonLink,
    podcastLink,
    pinterestLink,
    twitterLink,
    activeSocials,
    showSocials,
    user_id,
    instagram_links,
    isOnboardingFinished: is_onboarding_finished,
    isNecSearch: is_nec_search,
    avg_words: avg_caption_words,
    isInstagram: is_instagram_on,
    isYoutube: is_youtube_on,
    isNewsletter: is_newsletter_on,
    isTiktok: is_tiktok_on,
    isPodcast: is_podcast_on,
    is_nec_present,
    first_connected_platform,
    subscription_type,
  };
}

export async function getFeedControls(page: any, user_id: any) {
  let controls;
  if (page === "follower") {
    controls = await fetchFollowerControls(user_id);
  } else if (page === "creator") {
    controls = await fetchCreatorControls(user_id);
  } else if (page === "global") {
    controls = await fetchGlobalControls();
  }

  if (!controls || controls === undefined) {
    return [];
  } else if (controls) {
    return controls;
  }
  //  else {
  //   throw new Error("Failed to fetch controls");
  // }
}

export async function getFeedActiveFilters(user_id: any, username: any) {
  // const filterArray = ["All"];

  // const { data, error }: any = await supabase
  //   .from(`${username}`)
  //   .select("type")
  //   .eq("user_id", user_id);
  // const ActiveFilters = new Set<number>();
  // if (data && data.length) {
  //   data.filter((obj: any) => {
  //     if (!ActiveFilters.has(obj.type)) {
  //       ActiveFilters.add(obj.type);
  //     }
  //   });
  // }
  // // return uniqueSet;
  // if (!ActiveFilters) {
  //   return filterArray;
  // } else {
  //   ActiveFilters.forEach((filter: any) => filterArray.push(filter));
  //   return filterArray;
  // }
  // return []
  const filterArray = ["All"];

  try {
    const { data, error } = await supabase
      .from(username)
      .select("type")
      .eq("user_id", user_id);

    if (data && data.length) {
      const activeFilters = new Set(data.map((obj) => obj.type));
      filterArray.push(...activeFilters);
    }
  } catch (error) {}

  return filterArray;
}

export async function getPartialTableCount(user_id: any, username: any) {
  // const { data, error } = await supabase
  //   .from(`${username}`)
  //   .select("*")
  //   .eq("user_id", user_id);
  // if (data) {
  //   if (data.length) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   return false;
  // }
  const { data, error } = await supabase
    .from("user")
    .select("subscription_type")
    .eq("user_id", user_id);

  return data?.[0].subscription_type !== null ||
    data?.[0].subscription_type !== ""
    ? true
    : false;
}
export async function getFeedTopHashtags(user_id: any, max: any) {
  // let { data: topHashtags } = await supabase
  //   .from("searches")
  //   .select("*")
  //   .eq("type", `hashtag`)
  //   .eq("user_id", user_id)
  //   .order("occurrences", { ascending: false })
  //   .range(0, max - 1);

  // if (!topHashtags) {
  //   return false;
  // } else {
  //   let topHashtagsArr: any = [];
  //   topHashtags.map((elem: any) => {
  //     topHashtagsArr.push(elem.query);
  //   });
  //   return topHashtagsArr;
  // }
  const { data: topHashtags } = await supabase
    .from("searches")
    .select("query")
    .eq("type", "hashtag")
    .eq("user_id", user_id)
    .order("occurrences", { ascending: false })
    .range(0, max - 1);

  return topHashtags ? topHashtags.map((elem) => elem.query) : false;
}

export async function getFeedPopularSearches(
  user_id: any,
  username: any,
  max: any
) {
  const popularSearches = await getPopularSearches(user_id, username, max);

  if (!popularSearches) {
    return false;
  } else {
    let popularSearchesArr: any = [];
    popularSearches.map((elem: any) => {
      popularSearchesArr.push(elem.query);
    });
    return popularSearchesArr;
  }
}

export async function getFeedHighlights(user_id: any, username: any) {
  // const filterArray: any = [];

  // // const Highlights = await getHighlights(user_id);

  // const { data, error }: any = await supabase
  //   .from(`${username}`)
  //   .select("*")
  //   .eq("type", "HIGHLIGHTS")
  //   .eq("user_id", user_id);

  // const Highlights = new Set<number>();
  // if (data && data.length) {
  //   data.filter((obj: any) => {
  //     if (obj.content_category && !Highlights.has(obj.content_category)) {
  //       Highlights.add(obj.content_category);
  //     }
  //   });
  // }

  // // return uniqueSet;
  // if (!Highlights) {
  //   return filterArray;
  // } else {
  //   Highlights.forEach((filter: any) => filterArray.push(filter));
  //   return filterArray;
  // }
  const filterArray: any = [];

  try {
    const { data, error }: any = await supabase
      .from(username)
      .select("content_category")
      .eq("type", "HIGHLIGHTS")
      .eq("user_id", user_id);

    if (data && data.length) {
      const highlights = new Set(
        data.map((obj: any) => obj.content_category).filter(Boolean)
      );
      filterArray.push(...highlights);
    }
  } catch (error) {}

  return filterArray;
}

export async function getCustomCategories(user_id: any, page: any) {
  // let liveCategories: any = [];
  // let stagingCategories: any = [];
  // const { data } = await supabase
  //   .from("custom_categories")
  //   .select("*")
  //   .eq("user_id", user_id)
  //   .order("index");

  // if (data && data.length) {
  //   data.map((category: any) => {
  //     if (category.page === "live") {
  //       liveCategories.push({
  //         name: category.category_name,
  //         value: category.value,
  //         queries: category.custom_queries,
  //       });
  //     } else if (category.page === "staging") {
  //       stagingCategories.push({
  //         name: category.category_name,
  //         value: category.value,
  //         queries: category.custom_queries,
  //       });
  //     }
  //   });
  //   if (page === "live" || page === "follower") {
  //     return liveCategories;
  //   } else if (page === "staging" || page === "creator") {
  //     return stagingCategories;
  //   }
  // } else return false;
  let liveCategories: any = [];
  let stagingCategories: any = [];

  const { data } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("user_id", user_id)
    .order("index");

  if (data && data.length) {
    data.forEach((category) => {
      const categoryObject = {
        name: category.category_name,
        value: category.value,
        queries: category.custom_queries,
      };

      if (category.page === "live") {
        liveCategories.push(categoryObject);
      } else if (category.page === "staging") {
        stagingCategories.push(categoryObject);
      }
    });

    return page === "live" || page === "follower"
      ? liveCategories
      : stagingCategories;
  }

  return false;
}

export async function getAllCategories(user_id: any, max: any, page: any) {
  const { data: topHashtags } = await supabase
    .from("searches")
    .select("query")
    .eq("type", "hashtag")
    .eq("user_id", user_id)
    .order("occurrences", { ascending: false })
    .range(0, max - 1);
  let liveCategories: any = [];
  let stagingCategories: any = [];

  const { data: customCategories } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("user_id", user_id)
    .order("index");

  if (customCategories && customCategories.length) {
    customCategories.forEach((category) => {
      const categoryObject = {
        name: category.category_name,
        value: category.value,
        queries: category.custom_queries,
        default: category.default_query,
      };

      if (category.page === "live") {
        liveCategories.push(categoryObject);
      } else if (category.page === "staging") {
        stagingCategories.push(categoryObject);
      }
    });

    if (page === "live" || page === "follower") {
      return {
        topHashtags: topHashtags
          ? topHashtags.map((elem) => elem.query)
          : false,
        customCategories: liveCategories,
      };
    } else if (page === "staging" || page === "creator") {
      return {
        topHashtags: topHashtags
          ? topHashtags.map((elem) => elem.query)
          : false,
        customCategories: stagingCategories,
      };
    }
  }

  return false;
}

export async function getUserAndCheckExistence(username: any) {
  const { data: userData }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", username);

  if (userData?.length === 0) {
    return { userExists: false };
  }

  const {
    user_id,
    name,
    storage_url,
    picture,
    email,
    phoneNo,
    instagram_link,
    youtube_link,
    amazon_link,
    podcast_link,
    pinterest_user,
    twitter_link,
    newsletter_email,
    instagram_links,
    is_onboarding_finished,
    is_nec_search,
    avg_caption_words,
    is_instagram_on,
    is_youtube_on,
    is_newsletter_on,
    is_tiktok_on,
    is_podcast_on,
    is_pinterest_on,
    is_nec_present,
    first_connected_platform,
    subscription_type,
    social_instagram,
    social_youtube,

    social_tiktok,

    social_podcast,
    social_pinterest,

    social_newsletter,
    description,
    search_bar_text,
  } = userData[0];

  const pictureUrl = storage_url || picture;
  const instaLink = instagram_link;
  const youtubeLink = youtube_link;
  const amazonLink = amazon_link;
  const podcastLink = podcast_link;
  const pinterestLink = pinterest_user;
  const twitterLink = twitter_link;
  const newsletterLink = newsletter_email;

  const activeSocials = [
    { term: "instagram", link: instaLink },
    { term: "youtube", link: youtubeLink },
    { term: "amazon", link: amazonLink },
    { term: "podcast", link: podcastLink },
    { term: "pinterest", link: pinterestLink },
    { term: "twitter", link: twitterLink },
    { term: "pinterest" },
    { term: "newsletter" },
  ].filter((social) => social.link);

  const showSocials = [
    { platform: "instagram", isOn: is_instagram_on },
    { platform: "youtube", isOn: is_youtube_on },
    { platform: "podcast", isOn: is_podcast_on },
    { platform: "newsletter", isOn: is_newsletter_on },
    { platform: "tiktok", isOn: is_tiktok_on },
    { platform: "pinterest", isOn: is_pinterest_on },
    // Add other platforms here similarly
  ]
    .filter((social) => social.isOn)
    .map((social) => social.platform);

  const userExists = subscription_type ? true : false;
  return {
    name,
    picture: pictureUrl,
    email,
    phoneNo,
    instaLink,
    youtubeLink,
    amazonLink,
    podcastLink,
    pinterestLink,
    twitterLink,
    activeSocials,
    showSocials,
    user_id,
    instagram_links,
    isOnboardingFinished: is_onboarding_finished,
    isNecSearch: is_nec_search,
    avg_words: avg_caption_words,
    isInstagram: is_instagram_on,
    isYoutube: is_youtube_on,
    isNewsletter: is_newsletter_on,
    isTiktok: is_tiktok_on,
    isPodcast: is_podcast_on,
    is_nec_present,
    first_connected_platform,
    subscription_type,
    userExists,
    social_instagram,
    social_youtube,

    social_tiktok,

    social_podcast,
    social_pinterest,

    social_newsletter,
    description,
    search_bar_text,
  };
}

export async function getFiltersAndHighligts(user_id: any, username: any) {
  const activeFiltersArray = ["All"];
  const highlightsArray = [];

  try {
    // Fetching active filters
    const activeFiltersQuery = await supabase
      .from(username)
      .select("type")
      .eq("user_id", user_id);

    if (activeFiltersQuery.data && activeFiltersQuery.data.length) {
      const activeFilters = new Set(
        activeFiltersQuery.data.map((obj) => obj.type)
      );
      activeFiltersArray.push(...activeFilters);
    }
  } catch (error) {}

  try {
    // Fetching highlights
    const highlightsQuery = await supabase
      .from(username)
      .select("content_category")
      .eq("type", "HIGHLIGHTS")
      .eq("user_id", user_id);

    if (highlightsQuery.data && highlightsQuery.data.length) {
      const highlights = new Set(
        highlightsQuery.data.map((obj) => obj.content_category).filter(Boolean)
      );
      highlightsArray.push(...highlights);
    }
  } catch (error) {}

  return { activeFiltersArray, highlightsArray };
}

export async function getStats(user_id: any, username: any) {
  let res: any = {
    yesterday: false,
    yesterdayShop: false,
    week: false,
    weekShop: false,
    shopLinks: false,
  };

  const today = dayjs().format("YYYY-MM-DD");

  // Get yesterday's date
  const yesterdayDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
  const sevenDayDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");


  // const { data: yesterday, error: yesterdayError } = await supabase
  //   .from(`${username}`)
  //   .select("*")
  //   // .eq("type", "STORY")
  //   .gte("post_date", dayjs().startOf("day").subtract(1, "days"))
  //   .limit(1);

  const { data: yesterday, error: yesterdayError } = await supabase.rpc(
    "get_storiesfeedcountonee",
    {
      userid: user_id,
      // start_number: start,
      // end_num: end,
      // filter: filteredArray,
      query_str: "",
      user_name: username,
      start_date: yesterdayDate,
      end_date: today,

      // extra_ids: extraId,
    }
  );

  const { data: yesterdayShop, error: yesterdayShopError } = await supabase.rpc(
    "get_storiesfeedcountshopneee",
    {
      userid: user_id,
      // start_number: start,
      // end_num: end,
      // filter: filteredArray,
      query_str: "",
      user_name: username,
      start_date: yesterdayDate,
      end_date: today,

      // extra_ids: extraId,
    }
  );

  const { data: week, error: weekError } = await supabase.rpc(
    "get_storiesfeedcountonee",
    {
      userid: user_id,
      // start_number: start,
      // end_num: end,
      // filter: filteredArray,
      query_str: "",
      user_name: username,
      start_date: sevenDayDate,
      end_date: today,

      // extra_ids: extraId,
    }
  );

  const { data: weekShop, error: weekShopError } = await supabase.rpc(
    "get_storiesfeedcountshopneee",
    {
      userid: user_id,
      // start_number: start,
      // end_num: end,
      // filter: filteredArray,
      query_str: "",
      user_name: username,
      start_date: sevenDayDate,
      end_date: today,

      // extra_ids: extraId,
    }
  );
  const { data: shopLinks, error: shopLinksError } = await supabase
    .from(`${username}`)
    .select("*")
    .neq("shop_link", "")
    // .gte("post_date", dayjs().startOf("day").subtract(7, "days"))
    .limit(1);
  if (yesterday && yesterday[0].count) {
    res.yesterday = true;
  }
  if (week && week[0].count) {
    res.week = true;
  }
  if (yesterdayShop && yesterdayShop[0].count) {
    res.yesterdayShop = true;
  }
  if (weekShop && weekShop[0].count) {
    res.weekShop = true;
  }
  if (shopLinks && shopLinks.length) {
    res.shopLinks = true;
  }
  return res;
}
