import React, { useState } from "react";
import { supabase } from "../../../../Config/SupabaseClient";
import { Button } from "antd";
import axios from "axios";

const PodcastContent = (props: any) => {
  const { selectedUser } = props;
  const [podcastShowId, setPodcastShowId] = useState("");
  const [podcastShowName, setPodcastShowName] = useState("");
  async function fetchAllPodcastEpisodes() {
    const { data: user }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);
    // const url = `http://localhost:3001/fetch/podcast`;
    const url = `https://dev.thesearchbubble.com/fetch/podcast`;

    let res: any = await axios.post(
      url,
      {
        user: user?.[0]?.username,
        user_id: user?.[0]?.user_id,
        id: podcastShowId,
        showName: podcastShowName,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (res.data === "done") {
        alert("Integrating Podcast")
          const { data: update, error } = await supabase
          .from("user")
          .update({ show_podcast: true,is_podcast_on:true })
          .eq("user_id", user?.[0]?.user_id);
      return true;
    } else {
        alert ("Error in integrating podcast")
      return false;
    }
  }
  return (
    <>
      <div className="content-wrapper">
        <span className="content-block">
          <input
            required
            className="caption-input input-value"
            type="text"
            placeholder="enter podcast show name"
            onChange={(e: any) => setPodcastShowName(e.target.value)}
            value={podcastShowName}
          />
        </span>
        <span className="content-block">
          <input
            required
            className="caption-input input-value"
            type="text"
            placeholder="enter podcast show id"
            onChange={(e: any) => setPodcastShowId(e.target.value)}
            value={podcastShowId}
          />
        </span>
        <Button
          type="primary"
          className="upload-btn primary-color"
            onClick={fetchAllPodcastEpisodes}
        >
          {" "}
          Upload Files{" "}
        </Button>
      </div>{" "}
    </>
  );
};

export default PodcastContent;
