// import { Link, useNavigate } from "react-router-dom";
// import "../../../Styles/auth.css";
// import validator from "validator";
// import { Helmet } from "react-helmet";
// import { useState } from "react";
// import { loginEmail } from "../../../SupabaseServices/UserDatabase";

// const Login = () => {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState<any>("");
//   const [emailError, setEmailError] = useState<any>("");

//   async function signInWithEmail(e: any) {
//     e.preventDefault();
//     const loginData = await loginEmail(email, password);
//     if (!loginData) {
//       setError("You are not authorized to access this site!");
//     } else if (loginData === "error") {
//       setError("Invalid login credentials!");
//     } else if (loginData) {
//       navigate("/dashboard");
//     }
//   }

//   const validateEmail = (email: any) => {
//     if (validator.isEmail(email)) {
//       setEmailError("");
//       return true;
//     } else {
//       setEmailError("Enter valid Email!");
//     }
//   };

//   function validateCredentials(e: any) {
//     e.preventDefault();
//     if (validateEmail(email)) {
//       signInWithEmail(e);
//     }
//   }
//   function SubmitButton() {
//     if (email && password) {
//       return <button className="submit-btn">Submit</button>;
//     } else {
//       return (
//         <button className="submit-btn" disabled>
//           Submit
//         </button>
//       );
//     }
//   }
//   return (
//     <div className="con">
//       <Helmet>
//         <title>Bubble • Admin</title>
//       </Helmet>
//       <div className="nav-container">
//         <h1 className="nav-title">
//           <Link to="/">Bubble.</Link>
//         </h1>
//       </div>
//       <div className="providers">
//         <div>
//           <h2>Welcome Back!</h2>
//           <p>Login to your Bubble Account!</p>
//         </div>
//         <div>
//           <form className="email-form" onSubmit={validateCredentials}>
//             <div className="form-input">
//               {" "}
//               <input
//                 type="email"
//                 placeholder="Enter email"
//                 onChange={(e: any) => setEmail(e.target.value)}
//                 required
//               />
//             </div>
//             {emailError ? (
//               <div className="error-msg">
//                 <p>{emailError}</p>
//               </div>
//             ) : (
//               ""
//             )}
//             <div className="form-input">
//               {" "}
//               <input
//                 type="password"
//                 placeholder="Password"
//                 onChange={(e: any) => setPassword(e.target.value)}
//                 required
//               />
//             </div>{" "}
//             {error ? (
//               <div className="error-msg">
//                 <p>{error}</p>
//               </div>
//             ) : (
//               ""
//             )}
//             <SubmitButton />
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;






import { useEffect } from "react";
// import { updateLoginCount } from "../../../Utils/SupabaseServices/AnalyticsDatabse";
// import CreatorQuote from "../../../components/CreatorQuote/creatorQuote";
// import { TabTitle } from "../../../Utils/GeneralFunctions";
// import AuthPagesHeader from "../../../components/Header/AuthPagesHeader";
// import AuthPageSwitch from "../../../components/AuthPageSwitch/authPageSwitch";
import "../auth.css";
import PhoneLogin from "./PhoneLogin";


const Login = () => {
	// TabTitle("Login • Bubble");
	// useEffect(() => {
	// 	updateLoginCount();
	// }, []);

	return (
		<div className='auth-container'>
			<div className="auth-div-center"
				// style={{
				// 	width: "100%",
				// 	display: "flex",
				// 	// minHeight: "100%",
				// }}
			>
				<div className='auth-phone-signup-container-flex'>
					{/* <AuthPagesHeader /> */}
					<div className='auth-form-wrapper'>
						<div className='auth-title-div'>
							<p className='auth-title-div-p'>Welcome back</p>
							<p className='auth-title-div-p-2'>Sign in to your admin account</p>
						</div>
						<PhoneLogin />
						{/* <AuthPageSwitch loginPage /> */}
					</div>

					{/* <div className='auth-terms'>
						<p>
							By continuing, you agree to Bubbles's Terms of Service and Privacy
							Policy, and to receive periodic emails with updates.
						</p>
						<a>Read the terms</a>
					</div> */}
				</div>
				{/* <CreatorQuote /> */}
			</div>
		</div>
	);
};

export default Login;
