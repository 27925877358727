import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import "./TagsBox.css";
import { DragAndDrop, Drag, Drop } from "./drag-and-drop";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

type Props = {
  tagsArray: string[];
  removeTag: (tag: string) => void;
};

export default function TagsBoxDrag({ tagsArray, removeTag }: Props) {
  const [showAllTags, setShowAllTags] = useState(false);

  return (
    <>
      {/* // Inside TagsBox component return ( */}
      <Droppable droppableId="tagsDroppable" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            className="tags"
          >
            {tagsArray &&
              tagsArray
                .slice(0, showAllTags ? tagsArray.length : 5)
                .map((tag: any, index: any) => (
                  <Draggable
                    key={index}
                    draggableId={`tag${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: "#3b3b3b",
                          color: "white",
                          display: "flex",
                          borderRadius: "5px",
                          padding: "2px 5px",
                          alignItems: "center",
                        }}
                      >
                        {tag.trim() && (
                          <div
                            id={`tag${index}`}
                            className="all-tags"
                            key={`tag${index}`}
                          >
                            <p>
                              {tag.replace(/\|/g, "").trim()}
                            </p>
                            <CloseIcon
                              className="close-tag-icon"
                              onClick={() => removeTag(tag)}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {/* ); */}
      {!showAllTags && tagsArray.length > 5 && (
        <p
          className="tags-box-see-all"
          onClick={() => {
            setShowAllTags(true);
          }}
        >
          Show all tags
        </p>
      )}
      {showAllTags && tagsArray.length > 5 && (
        <p
          className="tags-box-see-less"
          onClick={() => {
            setShowAllTags(false);
          }}
        >
          Show less
        </p>
      )}
    </>
  );
}
