import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";

/**
 * Load content
 */
export function useLoadContentTypeCount(
  username: any,
  user_id: string,
  typeList: any[]
) {
  async function getPaginatedFeed() {
    let disabledTypes = [];
    let activeType=""
    for (let type of typeList) {
      if (type === "Feed") {
        const { data: content, error: contentError } = await supabase.rpc(
          "get_generalfffeedcount",
          {
            userid: user_id,
            // start_number: start,
            // end_num: end,
            filter: ["REELS", "FEED"],
            query_str: "",
            user_name: username,
            socials: ["instagram", "podcast", "newsletter", "pinterest"],

            // extra_ids: extraId,
          }
        );

        let feedCount = content[0].count;
        if (feedCount === 0) {
          disabledTypes.push(type);
          activeType="All";
          await supabase.from("controls_live").update({initial_content:'All'}).eq("user_id",user_id)
        }
      } else if (type === "Featured") {
        const { data: content, error: contentError } = await supabase.rpc(
          "get_featuredfeedcount",
          {
            userid: user_id,
            // start_number: start,
            // end_num: end,
            filter: ["FEED", "REELS", "HIGHLIGHTS", "STORY","TIKTOK","PIN","MAIL","VIDEO","PODCAST"],
            query_str: "",
            user_name: username,
            socials: ["instagram", "podcast", "newsletter", "pinterest","tiktok","youtube"],

            // extra_ids: extraId,
          }
        );

        let feedCount = content[0].count;
        if (feedCount === 0) {
          disabledTypes.push(type);
          activeType="Feed"
          await supabase.from("controls_live").update({initial_content:'Feed'}).eq("user_id",user_id)

        }
      }
    }
    return {disabledTypes:disabledTypes,activeType:activeType};
  }

  return useQuery(["customCategories"], getPaginatedFeed, {
    // enabled: Boolean(totalPages),
  });
}
