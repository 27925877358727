import { Tabs } from "antd";
import { useParams } from "react-router-dom";

import CreatorViewControls from "./StagingViewControls/creatorViewControls";
import LiveViewControls from "./LiveViewControls/liveViewControls";

import "./Controls.css";
import GeneralControls from "./LiveViewControls/Components/GeneralControls";
import CategoriesControls from "./LiveViewControls/Components/CategoriesControls";
import SearchbarControls from "./LiveViewControls/Components/SearchbarControls";
import { useLoadUser } from "../../../Utils/CustomHooks/useLoadUser";
import { StaticVariables } from "../../../StaticVariables";

const ControlsTab = (props: any) => {
  const { subscription_type ,username,user_id} = props;
    const { isLoading: userLoading, data: userData }: any = useLoadUser(username);
  const items = [
    {
      key: "General",
      label: "General",
      children: (
        <GeneralControls
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
          username={username}
          user_id={user_id}

        />
      ),
    },
    {
      key: "Categories",
      label: "Categories",
      children: (
        <CategoriesControls
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
          username={username}
          user_id={user_id}

        />
      ),
    },
    {
      key: "Searchbar",
      label: "Searchbar",
      children: (
        <SearchbarControls
        username={username}
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
          user_id={user_id}


        />
      ),
    },
    // {
    //   key: "Live",
    //   label: "Live View Controls",
    //   children: (
    //     <LiveViewControls
    //       showSocials={userData?.showSocials}
    //       myLinks={userData?.myLinks}
    //       subscription_type={subscription_type}
    //     />
    //   ),
    // },
    // {
    // 	key: "Staging",
    // 	label: "Staging View Controls",
    // 	children: <CreatorViewControls username={username} showSocials={userData?.showSocials} myLinks={userData?.myLinks} subscription_type={subscription_type}/>,
    // },
  ];

  return (
    <div className="dashboard-container">
      <div className="details">
        {
          <h1 className="dashboard-mobile-hide">
            {StaticVariables().CONTROLS_TITLE}
          </h1>
        }
         <div className='row' id='analytics-row'>
					<Tabs defaultActiveKey='General' items={items} type='card' />
				</div>
        {/* <LiveViewControls
          showSocials={userData?.showSocials}
          myLinks={userData?.myLinks}
          subscription_type={subscription_type}
        /> */}
       
      </div>
    </div>
  );
};

export default ControlsTab;
