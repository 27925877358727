import { useState } from "react";
import { useQuery } from "react-query";

import dayjs from "dayjs";
import { supabase } from "../../../Config/SupabaseClient";
import { getEmbedding } from "../../../SupabaseServices/ContentDatabase";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useLoadEmbeddingCount(
  userIds: any[],
  index: string,
  postsPerPage: number,
  filter: any,
  usernames: any,
  threshold:any
) {
  //   filter = filter.toLowerCase().replace(/\s+/g, " ").trim();

  async function getCount() {
    let feedCount;
    let postIds: any = [];
    if (userIds && userIds.length === 1) {
      if (filter) {
        const elementsCount = 5000;
        // const embedding = await getEmbedding(queryTerm);
        const connectingWords = [
          "and",
          "or",
          "but",
          "if",
          "because",
          "although",
          "since",
          "while",
          "when",
          "after",
          "before",
          "as",
          "that",
          "which",
          "who",
          "whom",
          "whose",
          "where",
          "what",
          "how",
          "the",
          "an",
          "a",
          "in",
          "on",
          "at",
          "with",
          "by",
          "for",
          "of",
          "to",
          "from",
          "through",
          "into",
          "during",
          "within",
          "against",
          "between",
          "among",
          "under",
          "above",
          "below",
          "over",
          "throughout",
          "around",
          "beside",
          "beneath",
          "up",
          "down",
          "off",
          "on",
          "out",
          "over",
          "through",
          "together",
          "apart",
          "forward",
          "backward",
          "away",
          "back",
          "with",
          "without",
          "about",
          "for",
          "against",
          "like",
          "unlike",
          "as",
          "with",
          "without",
          "are",
          "is",
          "do",
          "you",
          "am",
          "I",
        ];

        // Split the sentence into words
        const words = filter.split(" ");

        // Filter out connecting words
        const filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        // Join the filtered words back into a sentence
        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
        const embedding = await getEmbedding(pgQuery);
        const { data: countResult, error: countError } = await supabase.rpc(
          // "semantic_searchfiltercount",
          // "semantic_ssearchcounthybrid",
          // "semantic_ssearchcountembed",
          // "semantic_ssearchcountembedpostid",
          "semantic_ssearchcountembedpostidsim",
          {
            query_string: pgQuery,
            query_embedding: embedding,
            // similarity_threshold: 0.3146,
            similarity_threshold: threshold,

            // match_count: elementsCount,
            userid: userIds[0],
            username: usernames[0],
            socials: [
                "instagram",
                "youtube",
                "tiktok",
                "podcast",
                "pinterest",
                "newsletter",
              ],
            // filter:filter
          }
        );
        let resCount = countResult.length;
        postIds = countResult.map((item: any) => item.post_id);
        // if (countResult?.[0]?.count === 0) {
        //   resCount = 0;
        // } else {
        //   resCount = countResult?.[0]?.count ;
        // }
        feedCount = resCount;

        } else {
          feedCount = 0;
        }
      }
    

   
    
    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
   
  }

  return useQuery(["getLoadEmbedPages", filter, userIds, index], getCount, {
    enabled:Boolean(threshold)
  });
}
