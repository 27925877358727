import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../Config/SupabaseClient";

export function useLoadCustomCategories(user_id: any, page: any) {
  async function getCustomCategories() {
    console.log("id", user_id);
    let liveCategories: any = [];
    let stagingCategories: any = [];
    const { data } = await supabase
      .from("custom_categories")
      .select("*")
      .eq("user_id", user_id)
      .order("index");
    console.log("kya h ", data);
    if (data) {
      if (data.length) {
        data.map((category: any) => {
          if (category.page === "live") {
            liveCategories.push({
              name: category.category_name,
              value: category.value,
              queries: category.custom_queries,
            });
          } else if (category.page === "staging") {
            stagingCategories.push({
              name: category.category_name,
              value: category.value,
              queries: category.custom_queries,
            });
          }
        });
        if (page === "live" || page === "follower" || page === "admin") {
          return liveCategories;
        } else if (page === "staging" || page === "creator") {
          return stagingCategories;
        }
      } else return [];
    } else return false;
  }

  return useQuery(["customCategories", user_id], getCustomCategories, {
    refetchOnWindowFocus: true,
  });
}
