import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../Config/SupabaseClient";

export default function useUpdateDescription() {
  const queryClient = useQueryClient();

  const submitDescription = async ({
    tagString,
    user_id,
  }: {
    tagString: string;
    user_id: string;
    pageIndex: number;
    index: number;
  }) => {
    const { error } = await supabase
      .from("user")
      .update({ description: tagString })
      .eq("user_id", `${user_id}`);

    if (error) {
      throw new Error(error.message);
    } else {
      return true;
    }
  };

  return useMutation(submitDescription, {
    onMutate: async ({ user_id, tagString }) => {
      await queryClient.cancelQueries(["getDescription"]);
      const previousData = queryClient.getQueryData([
        "getDescription",
        user_id,
        tagString,
      ]);

      return { previousData };
    },
    onError: (_error, {}, context) => {
      queryClient.setQueryData(
        ["getDescription"],
        context?.previousData
      );
    },
    onSettled: (_data, _error, { pageIndex }) => {
      queryClient.invalidateQueries(["getDescription"], {
        refetchPage: (page, index) => {
          return index === pageIndex;
        },
      });
    },
  });
}
