import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Styles/navbar.css";
import { useAuth } from "../../hooks/useAuth";
const Navbar = () => {
  const { signOut: signOutSupabase } = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState<any>("");

  useEffect(() => {
    if (localStorage.getItem("name")) {
      const name = localStorage.getItem("name");
      setName(name);
    }
  }, []);
  const nameInitial = name.slice("")[0];
  const [showDashboardOptions, setShowDashboardOptions] = useState(false);

  const showOptions = () => {
    setShowDashboardOptions(!showDashboardOptions);
  };

  async function signOut() {
    localStorage.clear();
    sessionStorage.clear();
    signOutSupabase()
    navigate("/");
  }

  return (
    <div className="header">
      <div className="dash-head">
        <h1 className="nav-title">Bubble.</h1>
      </div>
      <div className="sidebar-img">
        <div className="dashboard-img" onClick={showOptions}>
          {nameInitial}
        </div>
        <nav className={showDashboardOptions ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showOptions}>
            <li className="nav-text">
              <h3>{name}</h3>
            </li>
            <li className="nav-text">
              <button className="sidebar" onClick={signOut}>
                <span>Logout</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
