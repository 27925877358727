import { useState, useEffect } from "react";
import { Switch } from "antd";
import PuffLoader from "react-spinners/PuffLoader";
import HashtagSlider from "./ControlsSlider/hashtagSlider";
import {
  fetchControls,
  updateAdsControl,
  updateCaptionControl,
  updateFiltersControl,
  updateHashtagsControl,
} from "../../../SupabaseServices/ControlsDatabase";

const GlobalViewControls = () => {
  const [email, setEmail] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const [showCaption, setShowCaption] = useState<any>();
  const [showTopHashtags, setShowTopHashtags] = useState<any>();
  const [showFilters, setShowFilters] = useState<any>();
  const [showAds, setShowAds] = useState<any>();

  useEffect(() => {
    if (localStorage.getItem("email")) {
      const email = localStorage.getItem("email");
      setEmail(email);
    }
  }, []);
  useEffect(() => {
    fetchInitialControls();
  }, [showCaption]);

  async function fetchInitialControls() {
    const controls = await fetchControls();
    if (controls) {
      setShowCaption(controls.showGlobalCaption);
      setShowTopHashtags(controls.showGlobalHashtags);
      setShowFilters(controls.showGlobalFilters);
      setShowAds(controls.showGlobalAds)
      setLoading(false);
    }
  }

  const toggleCaption = async (checked: boolean) => {
    const toggle = checked;
    updateCaptionControl(toggle).then(() => {
      fetchInitialControls();
    });
  };

  const toggleTopHashtags = async (checked: boolean) => {
    const toggle = checked;
    updateHashtagsControl(toggle).then(() => {
      fetchInitialControls();
    });
  };
  const toggleFilters = async (checked: boolean) => {
    const toggle = checked;
    updateFiltersControl(toggle).then(() => {
      fetchInitialControls();
    });
  };
  const toggleAds = async (checked: boolean) => {
    const toggle = checked;
    updateAdsControl(toggle).then(() => {
      fetchInitialControls();
    });
  };

  return (
    <div className="controls-wrapper">
      {loading ? (
        <PuffLoader
          color="#5274f9"
          loading={loading}
          className="loader"
          size={150}
        />
      ) : (
        <>
          <div className="d-side">
            <div className="details">
              <h1 className="dashboard-mobile-hide">Global View Controls</h1>
              <div className="controls-container">
                <div className="row">
                  <div className="controls">
                    <h3 className="dashboard-grid-item">
                      Show Caption{" "}
                      <Switch
                        style={{
                          backgroundColor: showCaption
                            ? "green"
                            : "rgb(241, 98, 98)",
                        }}
                        checked={showCaption}
                        onChange={toggleCaption}
                      />
                    </h3>
                    <h3 className="dashboard-grid-item">
                      Show Top Hashtags{" "}
                      <Switch
                        style={{
                          backgroundColor: showTopHashtags
                            ? "green"
                            : "rgb(241, 98, 98)",
                        }}
                        checked={showTopHashtags}
                        onChange={toggleTopHashtags}
                      />
                    </h3>

                    <h3 className="dashboard-grid-item">
                      Top Hashtag Range <HashtagSlider />
                    </h3>
                    <h3 className="dashboard-grid-item">
                      Show Filters{" "}
                      <Switch
                        style={{
                          backgroundColor: showFilters
                            ? "green"
                            : "rgb(241, 98, 98)",
                        }}
                        checked={showFilters}
                        onChange={toggleFilters}
                      />
                    </h3>
                    <h3 className="dashboard-grid-item">
                      Show Ads{" "}
                      <Switch
                        style={{
                          backgroundColor: showAds
                            ? "green"
                            : "rgb(241, 98, 98)",
                        }}
                        checked={showAds}
                        onChange={toggleAds}
                      />
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default GlobalViewControls;
