import "../Analytics.css";
function PopularSearchesTable({ popularSearches }: { popularSearches: any }) {

	return (
		<table className='analytics-table'>
			<thead>
				<tr>
					<th>Search Query</th>
					<th>Searches Made</th>
				</tr>
			</thead>

			{popularSearches
				? popularSearches.map((oneKey: any, index: any) => (
						<tbody key={index}>
							<tr>
								<td>{oneKey?.query}</td>
								<td>{oneKey?.count}</td>
							</tr>
						</tbody>
				  ))
				: ""}
		</table>
	);
}

export default PopularSearchesTable;
