import React, { useState } from "react";
import { Image, Modal, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd";
import { PlusOutlined, EyeOutlined } from "@ant-design/icons";
import { supabase } from "../../../../Config/SupabaseClient";

// type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    // reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ProfilePicture = (props: any) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: `${props.username}`,
      status: "done",
      url: `${props.userData?.picture}`,
    },
  ]);

  const handlePreview = async (file: UploadFile) => {
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj);
    // }

    // setPreviewImage(file.url || (file.preview as string));
    // setPreviewOpen(true);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList.length >= 2) {
      const [, ...rest] = newFileList; // Destructure to get rest of the array excluding the first element
      const updatedFiles: any = rest.map((file) => ({
        ...file,
        status: "done",
      }));
      let lastDigit = updatedFiles[0].uid.match(/\d$/)[0];

      // if (updatedFiles) {
      //   updatedFiles.name = props.username;
      //   updatedFiles.uid = -1;
      // }
      // const mediaType = updatedFiles.type.split("/")[0];
      // const mediaExt = updatedFiles.type.split("/")[1];
      const { data, error }: any = await supabase.storage
        .from("profile-picture")
        .upload(`${props.username}.jpeg`, updatedFiles[0].originFileObj, {
          contentType: `${updatedFiles[0].type}`,
        });
      if (error && error.error === "Duplicate") {
        lastDigit = lastDigit + 1;
        // const { data, error } = await supabase.storage
        //   .from("profile-picture")
        //   .update(`${props.username}.jpeg`, updatedFiles[0].originFileObj, {
        //     cacheControl: "3600",
        //     upsert: true,
        //   });
        //  await supabase.storage
        //     .from("profile-picture")
        //     .remove([`${props.username}.jpeg`]);
        const { data, error }: any = await supabase.storage
          .from("profile-picture")
          .upload(
            `${props.username}/${props.username}_${lastDigit}.jpeg`,
            updatedFiles[0].originFileObj,
            {
              contentType: `${updatedFiles[0].type}`,
            }
          );
      }
      var { data: url }: any = await supabase.storage
        .from("profile-picture")
        .getPublicUrl(`${props.username}/${props.username}_${lastDigit}.jpeg`);
      const { data: user } = await supabase
        .from("user")
        .update({ picture: url.publicUrl, storage_url: url.publicUrl })
        .eq("username", props.username);
      props.refetch();
      setFileList(updatedFiles);
    }
  };

  const uploadButton = (
    <button className="upload-btn" type="button">
      <PlusOutlined />
      <div className="mt-8">Upload</div>
    </button>
  );
  const handleClosePreview = () => {
    setPreviewOpen(false);
    setPreviewImage("");
  };
  return (
    <>
       <div
        className="account-description-wrapper mt-4"
      >
        <div className="account-social-logins-heading">
          <h5>Upload Profile Picture </h5>
        </div>
      <Upload
        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        showUploadList={{
          showPreviewIcon: true,
          showRemoveIcon: false, // Hide remove icon
          showDownloadIcon: false, // Hide download icon
          previewIcon: <EyeOutlined />, // Custom preview icon
        }}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Modal open={previewOpen} onCancel={handleClosePreview} footer={null}>
          <Image className="mt-5" src={previewImage} />
        </Modal>
      )}
      </div>
      
    </>
  );
};

export default ProfilePicture;
