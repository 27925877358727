import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  fetchIncludedUsername,
  fetchIncludedUsers,
} from "../../../SupabaseServices/UserDatabase";
import BrokenLinkContent from "./BrokenLinkContent";
import PuffLoader from "react-spinners/PuffLoader";
import { Pagination } from "antd";
import { useLoadBrokenLinksContent } from "./useLoadBrokenLinksContent";
import { useLoadBrokenPages } from "./useLoadBrokenPages";
const POSTS_PER_PAGE = 20;

const BrokenLink = () => {
  const [usernames, setUsernames] = useState<any>([]);
  const [selectedCreator, setSelectedCreator] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<any>();
  const [currentBrokenPage, setCurrentBrokenPage] = useState(1);

  useQuery({
    queryFn: () => {
      fetchUsers();
    },
    refetchOnMount: true,
  });
  async function fetchUsers() {
    const users = await fetchIncludedUsername();
    const userIds = await fetchIncludedUsers();
    setSelectedIds(userIds);
    setSelectedCreator(users);
    setUsernames(users);
  }
  const { data: brokenPages } = useLoadBrokenPages(
    selectedIds?.[0] || "",

    POSTS_PER_PAGE,
    "",
    usernames?.[0] || ""
  );
  const {
    data: brokenLinks,
    isLoading: brokenLinksLoading,
    isError,
    error,
    refetch,
  } = useLoadBrokenLinksContent(
    selectedIds?.[0] || "",
    usernames?.[0] || "",
    currentBrokenPage,
    POSTS_PER_PAGE,
    brokenPages?.totalPages,
    ""
  );
  console.log("brokn",brokenPages,brokenLinks)
  const handlePageChange = (page:any) => {
    setCurrentBrokenPage(page);
  };
  return (
    <div>
      {selectedCreator && selectedCreator.length ? (
        <>
          <BrokenLinkContent
            username={usernames[0]}
            user_id={selectedIds[0]}
            refetch={refetch}
            brokenLinks={brokenLinks}
          />

          <Pagination
            showQuickJumper
            current={currentBrokenPage}
            pageSize={POSTS_PER_PAGE}
             total={brokenPages?.feedCount}
             onChange={handlePageChange}
            showSizeChanger={false}
            className="pagination"
          />
        </>
      ) : (
        //

        <PuffLoader
          color="#5274f9"
          //   loading={userLoading}
          className="loader"
          size={150}
        />
      )}
    </div>
  );
};

export default BrokenLink;
