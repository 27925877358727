import {
  Alert,
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
} from "antd";
import React, { useState } from "react";

import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { fetchPinterestData } from "../ContentDatabase";

const PinterestIntegrationModal = (props: any) => {
  const { username } = useParams();
  const { showPinterestModal, user_id, data } = props;
  // const [pinterestUser, setPinterestId] = useState(data?.pinterest_link);
  const [errorPinterest, setErrorPinterest] = useState("");
  const [integratePinterestMsg, setIntegratePinterestMsg] = useState("");

  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  // const onChange = async (e: RadioChangeEvent) => {
  //   setValue(e.target.value);
  // };

  const handleOk = async () => {
    setShowIntegrationModal(false);
  };

  const handleCancel = () => {
    setShowIntegrationModal(false);
  };
  const handlePinterestAuth = () => {
    const CLIENT_ID = "1488674";
    const REDIRECT_URI = `https://www.searchbubble.com/`;
    const PINTEREST_AUTH_URL = "https://www.pinterest.com/oauth";

    const initialUrl = window.location.href;

    const popup: any = window.open("", "_blank");

    const authUrl = `${PINTEREST_AUTH_URL}/?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=boards:read,pins:read`;
    popup.location.href = authUrl;

    const checkPopupUrl = setInterval(async () => {
      try {
        if (popup.location.href.includes(REDIRECT_URI)) {
          clearInterval(checkPopupUrl);

          const code = new URLSearchParams(
            new URL(popup.location.href).search
          ).get("code");

          // Close the popup window
          popup.close();
          if (window.opener) {
            window.opener.location.href = initialUrl;
          }
          await fetchPinterestData(username, user_id, code).then((res: any) => {
            if (res) {
              setIntegratePinterestMsg(
                "Your Pinterest account has been authenticated. The data will be integrated in your feed shortly."
              );
              props.setShowPinterestModal(false);
              setShowIntegrationModal(true);
              setErrorPinterest("");
              const waitTime = 1000;
            } else {
              setErrorPinterest(
                "Error in authenticating your pinterest account. Contact Support. "
              );
            }
          });
        }
      } catch (error) {}
    }, 500);
  };

  return (
    <>
      <Modal
        title="Connect to Pinterest Show"
        open={showPinterestModal}
        onOk={handlePinterestAuth}
        onCancel={() => {
          setErrorPinterest("");
          props.setShowPinterestModal(false);
        }}
        okText="Authenticate with Pinterest"
        // okButtonProps={{ loading: loadingPodcastConnect }}
        // cancelButtonProps={{ disabled: loadingPodcastConnect }}
      >
        <p>
          By connecting your pinterest account, all your boards and pins will be
          displayed in the feed of your main page. In the content tab you will
          be able to hide or delete the posts you don't want to show.{" "}
        </p>
        {errorPinterest && (
          <Alert
            type="error"
            message={`Error: ${errorPinterest}`}
            showIcon
            className="error-alert"
          />
        )}
      </Modal>
      <Modal
        title="Pinterest Authentication"
        open={showIntegrationModal}
        onOk={handleOk}
        okText="Ok"
        onCancel={handleCancel}
      >
        <>
          <p>{integratePinterestMsg} </p>
        </>
      </Modal>
    </>
  );
};

export default PinterestIntegrationModal;
