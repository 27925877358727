import { supabase } from "../Config/SupabaseClient";

export async function storeUserControls(email: any) {
  const { data }: any = await supabase
    .from("controls")
    .select("*")
    .eq("email", email);

  if (data.length === 0) {
    await supabase
      .from("controls")
      .insert([
        {
          email,
          totalHashtag: 100,
          hashtagRange: 100,
        },
      ])
      .single();
  }
}

export async function fetchControls() {
  let { data }: any = await supabase
    .from("global_controls")
    .select("*")
    .order("id");

  if (data.length > 0) {
    let showGlobalCaption;
    let showGlobalHashtags;
    let showGlobalFilters;
    let showGlobalAds;
    const totalHashtags = data[0].control_value;
    const maxHashtags = data[2].control_value;
    const showCaption = data[1].control_value;
    const showTopHashtags = data[4].control_value;
    const showFilters = data[3].control_value;
    const showAds = data[5].control_value;

    if (showCaption === "true") {
      showGlobalCaption = true;
    } else {
      showGlobalCaption = false;
    }
    if (showTopHashtags === "true") {
      showGlobalHashtags = true;
    } else {
      showGlobalHashtags = false;
    }
    if (showFilters === "true") {
      showGlobalFilters = true;
    } else {
      showGlobalFilters = false;
    }
    if (showAds === "true") {
      showGlobalAds = true;
    } else {
      showGlobalAds = false;
    }
    return {
      showGlobalCaption,
      showGlobalHashtags,
      maxHashtags,
      totalHashtags,
      showGlobalFilters,
      showGlobalAds,
    };
  }
}
export async function updateCaptionControl(toggle: any) {
  await supabase
    .from("global_controls")
    .update({ control_value: toggle })
    .eq("global_controls.control", `showCaption`);
}

export async function updateHashtagsControl(toggle: any) {
  await supabase
    .from("global_controls")
    .update({ control_value: toggle })
    .eq("global_controls.control", `showHashtags`);
}

export async function updateFiltersControl(toggle: any) {
  await supabase
    .from("global_controls")
    .update({ control_value: toggle })
    .eq("global_controls.control", `show_filters`);
}
export async function updateAdsControl(toggle: any) {
  await supabase
    .from("global_controls")
    .update({ control_value: toggle })
    .eq("global_controls.control", `global_show_ads`);
}
export async function updateHashtagRange(newValue: any) {
  await supabase
    .from("global_controls")
    .update({ control_value: newValue })
    .eq("global_controls.control", "hashtagRange");
}

export async function createCustomCategoryColumn(
  user_id: any,
  categoryName: any
) {
  const { data } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("page", "live")
    .eq("user_id", user_id);
  const { error } = await supabase.from("custom_categories").insert([
    {
      user_id,
      category_name: `${categoryName}`,
      value: true,
      page: "live",
      index: data ? data?.length : 0,
    },
    {
      user_id,
      category_name: `${categoryName}`,
      value: true,
      page: "staging",
      index: data ? data?.length : 0,
    },
  ]);
  if (error === null) {
  }
}



export async function fetchFollowerControls(user_id: any) {
  let { data }: any = await supabase
    .from("controls_live")
    .select("*")
    .eq("user_id", user_id).limit(1).order("created_at",{ascending:false})
    .single();

  if (data) {
    const showCaption = data.show_caption;
    const showTopHashtags = data.topHashtags;
    const showPopularSearches = data.popular_searches;
    const showRecentSearches = data.recent_searches;
    const maxHashtags = data.maxHashtag;
    const maxPopularSearches = data.popularSearchesNumFollower;
    const maxRecentSearches = data.recentSearchesNumFollower;
    const totalRecentSearches = data.totalRecentSearches;
    const totalPopularSearches = data.totalPopularSearches;
    const recentSearchesNum = data.recentSearchesNumFollower;
    const popularSearchesNum = data.popularSearchesNumFollower;
    const totalHashtags = data.totalHashtagNum;
    const showFilters = data.showFollowerFilters;
    const showAds = data.showAdsFollower;
    const showComments = data.show_follower_comments;
    const openAiSearch = data.open_ai_search;
    const showIcons = data.showIconsFollower;
    const showBrowserCategories = data.show_browser_live;
    const showCustomCategories = data.show_custom_live;
    const showTrendingCategories = data.show_trending_live;
    const showHighlights = data.show_highlights_live;
    const showLinks = data.show_links_live;
    const checkedType=data.initial_content;
    const showSocialLinks=data.show_social_links;
    const showYesterdayStories=data.yesterday_stories;
    const showThisWeekStories=data.this_week_stories;
    const showShoppableLinks=data.show_shoppable_links;
    const contentOrder=data.content_order;

    return {
      showCaption,
      showTopHashtags,
      showPopularSearches,
      showRecentSearches,
      maxHashtags,
      maxPopularSearches,
      maxRecentSearches,
      totalRecentSearches,
      totalPopularSearches,
      recentSearchesNum,
      popularSearchesNum,
      totalHashtags,
      showFilters,
      showAds,
      showComments,
      openAiSearch,
      showIcons,
      showBrowserCategories,
      showCustomCategories,
      showTrendingCategories,
      showHighlights,
      showLinks,
      checkedType,
      showSocialLinks,
      showYesterdayStories,
      showThisWeekStories,
      showShoppableLinks,
      contentOrder
    };
  }
}

export async function fetchCreatorControls(user_id: any) {
  let { data }: any = await supabase
    .from("controls_staging")
    .select("*")
    .eq("user_id", user_id).limit(1).order("created_at",{ascending:false})
    .single();

  if (data) {
    const showCaption = data.showCreatorCaption;
    const showTopHashtags = data.topHashtagsCreator;
    const showPopularSearches = data.popularSearchesCreator;
    const showRecentSearches = data.recentSearchesCreator;
    const maxHashtags = data.maxCreatorHashtag;
    const maxPopularSearches = data.popularSearchesNumCreator;
    const maxRecentSearches = data.recentSearchesNumCreator;
    const totalRecentSearches = data.totalRecentSearches;
    const totalPopularSearches = data.totalPopularSearches;
    const recentSearchesNum = data.recentSearchesNumCreator;
    const popularSearchesNum = data.popularSearchesNumCreator;
    const totalHashtags = data.totalHashtagNum;
    const showFilters = data.showCreatorFilters;
    const showAds = data.showAdsCreator;
    const showComments = data.show_creator_comments;
    const openAiSearch = data.open_ai_search;
    const showIcons = data.showIconsCreator;
    const showBrowserCategories = data.show_browser_staging;
    const showCustomCategories = data.show_custom_staging;
    const showTrendingCategories = data.show_trending_staging;
    const showHighlights = data.show_highlights_staging;
    const showLinks = data.show_links_staging;
    const checkedType=data.initial_content
    const showSocialLinks=data.show_social_links;

    return {
      showCaption,
      showTopHashtags,
      showPopularSearches,
      showRecentSearches,
      maxHashtags,
      maxPopularSearches,
      maxRecentSearches,
      totalRecentSearches,
      totalPopularSearches,
      recentSearchesNum,
      popularSearchesNum,
      totalHashtags,
      showFilters,
      showAds,
      showComments,
      openAiSearch,
      showIcons,
      showBrowserCategories,
      showCustomCategories,
      showTrendingCategories,
      showHighlights,
      showLinks,
      checkedType,
      showSocialLinks
    };
  }
}



export async function fetchGlobalControls() {
  let { data }: any = await supabase
    .from("global_controls")
    .select("*")
    .order("id");

  if (data.length > 0) {
    let showGlobalFilters;
    let showGlobalAds;
    const totalHashtags = data[0].control_value;
    const maxHashtags = data[1].control_value;
    const showCaption = data[2].control_value;
    const showTopHashtags = data[3].control_value;
    const showFilters = data[4].control_value;
    if (showFilters === "true") {
      showGlobalFilters = true;
    } else {
      showGlobalFilters = false;
    }
    const showAds = data[5].control_value;
    if (showAds === "true") {
      showGlobalAds = true;
    } else {
      showGlobalAds = false;
    }

    return {
      showCaption,
      showTopHashtags,
      maxHashtags,
      totalHashtags,
      showGlobalFilters,
      showGlobalAds
    };
  }
}
