import { BsCheck } from "react-icons/bs";
import { useLoadUser } from "../../../Utils/CustomHooks/useLoadUser";
import { getUserData } from "../../../SupabaseServices/UserDatabase";
import { useEffect, useState } from "react";
import { supabase } from "../../../Config/SupabaseClient";

const NecUpdate = (props: any) => {
  const { username, table } = props;
  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);
  // const [isNewData, setIsNewData] = useState(false);
  // useEffect(() => {
  //   // Add any specific logic needed on userData change
  //   if(!table){
  //     checkNewData();

  //   }
  // }, [userData]);

  // async function checkNewData() {
  //   const { data } = await supabase
  //     .from("content_new")
  //     .select("*")
  //     .gt("created_at", userData?.nec_date)
  //     .eq("user_id", userData?.user_id)
  //     .order("created_at", { ascending: false });
  //   if (data && data.length > 0) {
  //     setIsNewData(true);
  //   }
  // }
  return (
    <>
      {/* <div style={{ margin: "2%", marginBottom: "5%" }}> */}
        {userData ? (
          <>
            {userData.frames_created &&
              userData.sent_to_nec &&
              !userData.new_data && (
                <>
                  <BsCheck
                    style={{
                      background: "green",
                    }}
                    className="check"
                  />
                  {userData.nec_date && (
                    <span>
                      Last Sent to NEC:{" "}
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        day: "numeric",
                        month: "long",
                      }).format(new Date(userData.nec_date))}
                    </span>
                  )}
                </>
              )}
  
            {userData.frames_created && !userData.sent_to_nec && (
              <>
                <BsCheck
                  style={{
                    background: "lightblue",
                  }}
                  className="check"
                />{" "}
                {!table ? <>Data pending to be sent to NEC.</> : ""}
              </>
            )}
  
            {!userData.frames_created && !userData.sent_to_nec && (
              <>
                <BsCheck
                  style={{
                    background: "#FF6347",
                  }}
                  className="check"
                />{" "}
                {!table ? <>Hit Process Frames Button.</> : ""}
              </>
            )}
  
            {userData.frames_created &&
              userData.sent_to_nec &&
              userData.new_data && (
                <>
                  <BsCheck
                    style={{
                      background: "yellow",
                    }}
                    className="check"
                  />{" "}
                   {userData.nec_date && (
                    <span>
                      Last Sent to NEC:{" "}
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        day: "numeric",
                        month: "long",
                      }).format(new Date(userData.nec_date))}
                    </span>
                  )}
                  {!table ? <>New data not processed</> : ""}
                </>
              )}
               {!userData.frames_created &&
              userData.sent_to_nec &&
              !userData.new_data && (
                <>
                  <BsCheck
                    style={{
                      background: "green",
                    }}
                    className="check"
                  />
                  {userData.nec_date && (
                    <span>
                      only images Sent to NEC:{" "}
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        day: "numeric",
                        month: "long",
                      }).format(new Date(userData.nec_date))}
                    </span>
                  )}
                </>
              )}
              {!userData.frames_created &&
              userData.sent_to_nec &&
              userData.new_data && (
                <>
                  <BsCheck
                    style={{
                      background: "green",
                    }}
                    className="check"
                  />
                  {userData.nec_date && (
                    <span>
                      only images Sent to NEC:{" "}
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        day: "numeric",
                        month: "long",
                      }).format(new Date(userData.nec_date))}
                    </span>
                  )}
                </>
              )}
          </>
        ) : (
          ""
        )}















      {/* </div> */}
    </>
  );
  
};

export default NecUpdate;
