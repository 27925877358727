import { useState } from "react";
// import "./PostsCards.css";

export default function Caption({ caption }: { caption: string }) {
	const [showAll, setShowAll] = useState(false);
	const maxCharLength = 80;
	// Function to convert URLs to clickable links
	const convertToLinks = (text: string) => {
		// Regular expression pattern to match URLs
		const urlPattern = /(https?:\/\/\S+)/g;

		// Split the text by URLs
		const parts = text.split(urlPattern);

		// Map over the parts and create clickable links for URLs
		const result = parts.map((part, index) => {
			if (part.match(urlPattern)) {
				return (
					<a key={index} href={part} target='_blank' rel='noopener noreferrer'>
						{part}
					</a>
				);
			} else {
				return part;
			}
		});

		return result;
	};

	return (
		<div className='posts-caption'>
			{showAll || caption.length <= maxCharLength ? (
				convertToLinks(caption)
			) : (
				<>
					{convertToLinks(caption.substring(0, maxCharLength))}
					<span
						className='posts-caption-more'
						onClick={() => {
							setShowAll(true);
						}}
					>
						... see more
					</span>
				</>
			)}
		</div>
	);
}
