import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../../Config/SupabaseClient";

export default function useUpdateCategoriesIndex() {
  const queryClient = useQueryClient();

  const updateIndexValue = async ({
    user_id,
    newData,
  }: {
    user_id: any;
    newData: any;
  }) => {

    let errorArray: any[] = [];
    for (const [idx, i] of newData.entries()) {
      const { data, error } = await supabase
        .from("custom_categories")
        .update({ index: idx })
        .eq("category_name", i.name)
        .eq("user_id", user_id);

      if (error !== null) {
        errorArray.push(error.message);
      }
    }

    return { newData, errorArray };
  };

  // Tag optimistic update
  return useMutation(updateIndexValue, {
    // If the mutation fails, use the context returned from onError to roll back
    onError: (error: any, { newData, user_id }) => {
      if (newData) {
        queryClient.setQueryData(["customCategories"], user_id);
      }
    },
    onSettled: async (_, error, { newData }) => {
        if (!error && newData) {
          queryClient.invalidateQueries(["customCategories"], {
            refetchPage: () => true,
          });
        }
      }
      
    // Always refetch after error or success:

  });
}
