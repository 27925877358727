import Skeleton from "react-loading-skeleton";
import "./StatBox.css";

type Props = {
	name: string;
	icon: any;
	isLoading: boolean;
	number?: any;
	component?: any;
};
function StatBox({ name, number, icon, isLoading, component }: Props) {
	return (
		<div className='stat-box-wrapper'>
			<div className="stat-box-display">
				<p className='stat-box-title'>{name}</p>
				{icon}
			</div>
			{isLoading ? (
				<Skeleton />
			) : typeof number === "number" ? (
				<p className='stat-box-number'>{number}</p>
			) : (
				component
			)}
		</div>
	);
}

export default StatBox;
