import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import "./TagsBox.css";

type Props = {
	tagsArray: string[];
	removeTag: (tag: string) => void;

};

export default function TagsBox({ tagsArray, removeTag }: Props) {
	const [showAllTags, setShowAllTags] = useState(false);

	return (
		<>
			<div
			className="tags"
			>
				{tagsArray && tagsArray.slice(0, showAllTags ? tagsArray.length : 5)
					.map((tag: any, keyIndex: any) => {
						return (
							<>{tag.replace(/\|/g, '').trim()?<div
								id={`tag${keyIndex}`}
								className="all-tags"
								key={`tag${keyIndex}`}
							>
								<p>

									{tag.replace(/\|/g, '').trim()}
									
								</p>
								<CloseIcon
									className="close-tag-icon"
									onClick={() => removeTag(tag)}
								/>
							</div>:""}</>
						);
					})}
			</div>
			{!showAllTags && tagsArray.length > 5 && (
				<p
					className='tags-box-see-all'
					onClick={() => {
						setShowAllTags(true);
					}}
				>
					Show all tags
				</p>
			)}
			{showAllTags && tagsArray.length > 5 && (
				<p
					className='tags-box-see-less'
					onClick={() => {
						setShowAllTags(false);
					}}
				>
					Show less
				</p>
			)}
		</>
	);
}
