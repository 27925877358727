import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import { useLoadContentTypeCount } from "./useLoadContentTypeCount";
import { updateCheckedType } from "./ControlsDatabase";

const ContentTypeRadio = (props: any) => {
  const { username, checkedType, user_id, page } = props;
  const typeList = ["All", "Feed", "Featured"];


  const { isLoading, data } = useLoadContentTypeCount(
    username,
    user_id,
    typeList
  );
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
  if (data && data.disabledTypes.length && data.disabledTypes.includes(checkedType)) {
      setSelectedType(data?.activeType);
    }else{
      setSelectedType(checkedType)
    }
  }, [checkedType, data]);

  // const [selectedType, setSelectedType] = useState((data && data.disabledTypes.length && data.disabledTypes.includes(checkedType))?data?.activeType:checkedType);
  const handleChange = (e: any) => {
    const type = e.target.value;
    setSelectedType(type);
    updateCheckedType(user_id, type, page);
  };
  return (
    <div className="content-type-radios">
      <Radio.Group
        onChange={handleChange}
        value={selectedType}
        className="radio-btn"
      >
        {typeList.map((type, index) => (
          <Radio.Button
            key={type}
            value={type}
            style={{
              marginRight: index < typeList.length - 1 ? "10px" : "0",
              borderRadius: "4px",
              borderLeft: "4px",
              border: "none",
              outline: "none",
              // borderColor:'#414141',
              // Add more styles as needed
              backgroundColor: selectedType === type ? "black" : "white",
              color: selectedType === type ? "white" : "black",
              ...(data && data.disabledTypes.length && data.disabledTypes.includes(type)
                ? {
                    backgroundColor: "#d9d9d9", // Adjust the background color for disabled state
                    color: "#333", // Adjust the text color for disabled state
                  }
                : {}),
              // Add more styles as needed
            }}
            disabled={Boolean(data && data.disabledTypes.length && data.disabledTypes.includes(type))}
          >
            {type}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default ContentTypeRadio;
