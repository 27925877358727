import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useAuth } from "../../../../hooks/useAuth";
import { getCustomSearchesDateRange, getInstagramHighlightsSearchesDateRange, getInstagramLinksSearchesDateRange, getPopularSearchesDateRange, getPostClicks, getShoppableClicks } from "../../../../SupabaseServices/SearchesDatabase";

function useTrendingAnalytics(
  user_id:any,username:any,
  startDate: any,
  endDate: any,
  customCategories: any[],
  instagramHighlights:any[],
  instagramLinks:any
) {
  // const { username } = useParams();
  // const { user } = useAuth();
  // const user_id = user?.id || "";
  // const { data: recentSearches, isLoading: loadingRecentSearches } = useQuery(
  // 	["trending-recent-searches", username, startDate],
  // 	() => {
  // 		return getRecentSearches(username, 25, startDate, endDate);
  // 	},
  // 	{ refetchOnWindowFocus: true }
  // );
  const { data: popularSearches, isLoading: loadingPopularSearches } = useQuery(
    ["trending-popular-searches", username, startDate],
    () => {
      return getPopularSearchesDateRange(
        username,
        25,
        startDate,
        endDate,
        user_id
      );
    },
    { refetchOnWindowFocus: true }
  );

  const { data: postClicks, isLoading: loadingPostClicks } = useQuery(
    ["trending-post-clicks", username, startDate],
    () => {
      return getPostClicks(username, 25, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );
  const { data: shoppableClicks, isLoading: loadingShoppableClicks } = useQuery(
    ["trending-Shoppable-clicks", username, startDate],
    () => {
      return getShoppableClicks(username, 25, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );
  const {
    data: customCategoriesSearches,
    isLoading: loadingCustomCategoriesSearches,
  } = useQuery(
    ["trending-custom-categories-searches", username, startDate],
    () => {
      return getCustomSearchesDateRange(
        username,
        25,
        customCategories,
        startDate,
        endDate,
        user_id
      );
    },
    {
      enabled: Boolean(customCategories) && Boolean(customCategories.length),
      refetchOnWindowFocus: true,
    }
  );
  const {
    data: instagramHighlightsSearches,
    isLoading: loadinginstagramHighlightsSearches,
  } = useQuery(
    ["trending-instagram-highlights-searches", username, startDate],
    () => {
      return getInstagramHighlightsSearchesDateRange(
        username,
        25,
        instagramHighlights,
        startDate,
        endDate,
        user_id
      );
    },
    {
      enabled: Boolean(instagramHighlights) && Boolean(instagramHighlights.length),
      refetchOnWindowFocus: true,
    }
  );
  const {
    data: instagramLinksSearches,
    isLoading: loadinginstagramLinksSearches,
  } = useQuery(
    ["trending-instagram-links-searches", username, startDate],
    () => {
      return getInstagramLinksSearchesDateRange(
        username,
        25,
        instagramLinks,
        startDate,
        endDate,
        user_id
      );
    },
    {
      enabled: Boolean(instagramLinks) && Boolean(instagramLinks.length),
      refetchOnWindowFocus: true,
    }
  );
  return {
    // loadingRecentSearches,
    // recentSearches,
    loadingPopularSearches,
    popularSearches,
    loadingPostClicks,
    postClicks,
    customCategoriesSearches,
    loadingCustomCategoriesSearches,
    instagramHighlightsSearches,
    loadinginstagramHighlightsSearches,
    instagramLinksSearches,
   loadinginstagramLinksSearches,
   loadingShoppableClicks,
   shoppableClicks,
  };
}

export default useTrendingAnalytics;
