import React from 'react';
import Routing from './Routes/routing';
import './Styles/app.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/reset.css';

function App() {
  return (
    <div className="App">
      <Routing/>
    </div>
  );
}

export default App;
