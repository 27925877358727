import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";

// Hook for adding or removing tags
export default function useUpdateCategoryQueries() {
  const queryClient = useQueryClient();

  const submitQuery = async ({
    categoryName,
    tag,
    tagString,
    user_id,
    action,
  }: // post_id,
  {
    // activeKey: string;
    categoryName: any;
    tag: string;
    tagString: string;
    user_id: any;
    action: string;
    // post_id: string;
    pageIndex: number;
    index: number;
    // filter: string | null;
  }) => {
    const { error: customError } = await supabase
      .from("custom_categories")
      .update({ custom_queries: tagString })
      .eq("category_name", categoryName)
      .eq("user_id", user_id);
    if (action === "submit" && tag) {
      const { error }: any = await supabase.from("searches").insert([
        {
          user_id,
          query: tag,
          type: categoryName.split(" ").join(""),
        },
      ]);
    } else if (action === "remove") {
      const { error }: any = await supabase
        .from("searches")
        .delete()
        .eq("query", tag)
        .eq("type", categoryName.split(" ").join(""))
        .eq("user_id", user_id);
    }

    if (customError) {
      throw new Error(customError?.message);
    } else {
      return true;
    }
  };

  return useMutation(submitQuery, {
    // When mutate is called:
    onMutate: async ({ user_id, tagString }) => {
      await queryClient.cancelQueries(["customCategories", tagString]);
      const previousData = queryClient.getQueryData([
        "customCategories",
        user_id,
        tagString,
        // activeKey,
        // filter,
      ]);

      // if (previousData) {
      // 	// Deep clone the object, otherwise the objects inside will have the same reference in memory
      // 	const newData = JSON.parse(JSON.stringify(previousData)) as {
      // 		pageParams: any;
      // 		pages: any[];
      // 	};
      // 	newData.pages[pageIndex][index].custom_queries = tagString;
      // 	queryClient.setQueryData(
      // 		["customCategories", ],
      // 		newData
      // 	);
      // }
      return { previousData };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error, {}, context) => {
      queryClient.setQueryData(["customCategories"], context?.previousData);
    },
    // Always refetch after error or success:
    onSettled: (_data, _error, { pageIndex }) => {
      queryClient.invalidateQueries(["customCategories"], {
        refetchPage: (page, index) => {
          return index === pageIndex;
        },
      });
    },
  });
}
