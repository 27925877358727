import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";

// Hook for updating post status: archived or active
export default function useUpdatePostStatus() {
  const queryClient = useQueryClient();

  const updateIndexValue = async ({
    newPostStatus,
    postId,
    usernames,
    userIds,
  }: {
    newPostStatus: "archived" | "active" | "active,ad";
    postId: string;
    activeKey: string;
    pageIndex: number;
    postIndex: number;
    filter: string | null;
    usernames: any;
    userIds: any;
  }) => {
    const { error } = await supabase
      .from(`${usernames[0]}`)
      .update({ index: newPostStatus })
      .eq("post_id", postId);
    const {} = await supabase
      .from("content_new")
      .update({ index: newPostStatus })
      .eq("post_id", postId)
      .eq("user_id", userIds[0]);
    const {} = await supabase
      .from("content_instagram")
      .update({ index: newPostStatus })
      .eq("post_id", postId)
      .eq("user_id", userIds[0]);

    if (error) {
      throw new Error(error.message);
    } else {
      return true;
    }
  };

  // Tag optimistic update
  return useMutation(updateIndexValue, {
    // When mutate is called:
    onMutate: async ({
      newPostStatus,
      pageIndex,
      postIndex,
      activeKey,
      filter,
    }) => {
      await queryClient.cancelQueries(["paginatedPosts", activeKey, filter]);
      const previousData = queryClient.getQueryData([
        "paginatedPosts",
        activeKey,
        filter,
      ]);
      if (previousData) {
        // Deep clone the object, otherwise the objects inside will have the same reference in memory
        const newData = JSON.parse(JSON.stringify(previousData)) as {
          pageParams: any;
          pages: any[];
        };

        if (newPostStatus === "active,ad") {
          newData.pages[pageIndex][postIndex].index = newPostStatus;
        } else {
          newData.pages[pageIndex].splice(postIndex, 1);
        }

        queryClient.setQueryData(
          ["paginatedPosts", activeKey, filter],
          newData
        );
      }
      return { previousData };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error, { activeKey, filter }, context) => {
      queryClient.setQueryData(
        ["paginatedPosts", activeKey, filter],
        context?.previousData
      );
    },
    // Always refetch after error or success:
    onSettled: async (_data, _error, { activeKey, newPostStatus, filter }) => {
      queryClient.invalidateQueries(["paginatedPosts", activeKey, filter], {
        refetchPage: () => {
          return true;
        },
      });
      queryClient.invalidateQueries(["paginatedPosts", newPostStatus, filter], {
        refetchPage: () => {
          return true;
        },
      });
    },
  });
}
