import { useState, useEffect } from "react";
import { Col, Row, Slider } from "antd";
import {
  fetchControls,
  updateHashtagRange,
} from "../../../../SupabaseServices/ControlsDatabase";

const HashtagSlider = () => {
  const [inputValue, setInputValue] = useState(5);
  const [totalHashtag, setTotalHashtag] = useState<any>();
  const minimum = 5;
  useEffect(() => {
    fetchMaxHashtag();
  }, []);
  async function fetchMaxHashtag() {
    const maxTopHashtags = await fetchControls();

    if (maxTopHashtags) {
      let max = maxTopHashtags.totalHashtags - 0;
      setTotalHashtag(max);
      setInputValue(maxTopHashtags.maxHashtags);
    }
  }

  const onChange = async (newValue: number) => {
    setInputValue(newValue);
    updateHashtagRange(newValue).then(() => {
      fetchMaxHashtag();
    });
  };

  return (
    <div>
      <Row>
        <span className="min-range">{minimum}</span>
        <Col span={18}>
          <Slider
            min={minimum}
            max={totalHashtag}
            onChange={onChange}
            value={inputValue}
            tooltip={{ placement: "bottom" }}
          />
        </Col>
        {"   "}
        <span className="max-range">{totalHashtag}</span>
      </Row>
    </div>
  );
};

export default HashtagSlider;
