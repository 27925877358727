import React, { useState } from "react";
import { Modal, Tabs } from "antd";
import { FaLock } from "react-icons/fa";

const AnalyticsTabOptions = (props: any) => {
  const { navTabs, subscription_type, activeKey } = props;
  const { TabPane } = Tabs;
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleOk = () => {
    return (
      // <div>
      <a
        href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
        target="_blank"
      >
        <button className="upgrade-subscription">Upgrade</button>
      </a>
      // {/* </div> */}
    );
  };
  const onChangeTab = (key: string) => {
    if (key === "Trending" && subscription_type === "basic") {
      setIsModalOpen(true);
      props.setActiveKey(activeKey);
    } else {
      props.setActiveKey(key);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Tabs
        defaultActiveKey="Overview"
        activeKey={activeKey}
        type="card"
        onChange={onChangeTab}
      >
        {navTabs.map((tab: any) => (
          <TabPane
            key={tab.key}
            tab={
              <div className="tab-key">
                {tab.isLocked && (
                  <FaLock className="tab-lock" />
                )}
                <span style={{ color: tab.isLocked ? "grey" : "" }}>
                  {tab.label}
                </span>
              </div>
            }
            // disabled={tab.isLocked}
          >
            {tab.children}
          </TabPane>
        ))}
      </Tabs>
      <Modal
        title="Upgrade to Unlock Trending"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="upgrade-text">
          Unlocking the Trending feature will allow you see analytics of you
          page in more detail with the top 25 searches under each category made
          on your page.
        </p>
        <div className="text-right">
          <a
            href="https://payments.thesearchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
            target="_blank"
          >
            <button className="upgrade-subscription">Upgrade</button>
          </a>
        </div>
      </Modal>
    </>
  );
};

export default AnalyticsTabOptions;
