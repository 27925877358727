import { supabase } from "../Config/SupabaseClient";

import axios from "axios";

export async function changeAdSequence(post_id: any, index: any) {
  await supabase
    .from("content_new")
    .update({ global_ads_order: index })
    .eq("post_id", post_id);
}
export async function removePostasAd(post_id: any) {
  await supabase
    .from("content_new")
    .update({ index: "active", global_ads_order: null })
    .eq("post_id", post_id);
}
export async function getAdsFeed(
  username: any,

  index: any
) {
  let { data }: any = await supabase
    .from("content_new")
    .select("*")
    .eq("username", `${username}`)
    .eq("index", "yes")
    .eq("index", index);

  if (data) {
    if (data.length !== 0) {
      return data;
    } else {
      return false;
    }
  }
}
export async function getAdsFeedLength(usernames: any) {
  let { count }: any = await supabase
    .from("content_new")
    .select("*", { count: "exact", head: true })
    .in("username", usernames)
    .eq("index", "active,ad");

  return count;
}
export async function reduceAdSequence(username: any) {
  let { data } = await supabase
    .from("content_new")
    .select("*")
    .eq("username", username)
    .eq("index", "active,ad");
  if (data && data.length) {
    data.map(async (ad: any) => {
      if (ad.global_ads_order === 0) {
        await supabase
          .from("content_new")
          .update({ global_ads_order: null })
          .eq("post_id", ad.post_id);
      } else {
        await supabase
          .from("content_new")
          .update({ global_ads_order: `${ad.global_ads_order - 1}` })
          .eq("post_id", ad.post_id);
      }
    });
  }
}
export async function orderAdSequence(
  sourceIndex: any,
  targetIndex: any,
  post_id: any
) {
  let { data: sourceAd }: any = await supabase
    .from("content_new")
    .update({ global_ads_order: targetIndex })

    .eq("global_ads_order", `${sourceIndex}`)
    .select();

  let { data: targetAd }: any = await supabase
    .from("content_new")
    .update({ global_ads_order: sourceIndex })

    .eq("post_id", `${post_id}`)
    .select();
}
export async function updateIndexToArchive(post_id: any) {
  await supabase
    .from("content_new")
    .update({ index: "archive" })
    .eq("post_id", post_id);
}
export async function updateIndexToActive(post_id: any) {
  await supabase
    .from("content_new")
    .update({ index: "active" })
    .eq("post_id", post_id);
}


var dataInsertion: any = function (
  length: any,
  result: any,
  username: any,
  errGroup: any[]
) {
  if (length > 0)
    return new Promise((resolve, reject) => {
      let post = result.shift();
    })
      .then(dataInsertion.bind(null, --length, result, username, errGroup))
      .catch(function (err) {
        console.log("Error " + err);
      });
  if (length == 0) {
    console.log(errGroup);
    errGroup.length
      ? console.log("Error", errGroup)
      : console.log("No issues found in Getting tags", new Date());
  }
};


export async function getEmbedding(query: any) {
  // try{
  // const embedUrl = `https://dev.thesearchbubble.com/insertEmbed`;
  // const url = `https://dev.thesearchbubble.com/generate/carousel/thumbnail`;
  // const url = `https://dev.thesearchbubble.com/fetch/openai/embedding`;

  // const res = await axios.post(
  //   url,
  //   {
  //     query:query
  //   },
  //   {
  //     // headers: {
  //     //   "content-type": "application/json",
  //     // },
  //   }
  // );
  // return res.data.embedding
  // }catch(error){
  // }
  const { data, error } = await supabase
    .from("query_embedding")
    .select("*")
    .eq("query", query);
  if (data) {
    if (data.length != 0) {
      return data?.[0]?.embedding;
    } else {
      try {
        const data = {
          input: query,
          model: "text-embedding-ada-002",
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer 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",
            "Access-Control-Allow-Origin ": "*",
          },
        };

        const response = await axios.post(
          "https://api.openai.com/v1/embeddings",
          data,
          config
        );

        const [{ embedding }] = response.data.data;
        await supabase
          .from("query_embedding")
          .insert([{ query: query, embedding: embedding }]);
        return embedding;
      } catch (error) {
        throw error;
      }
    }
  }
  // }
}



export async function getExtraNecIds(
  queryTerm: any,
  username: any
): Promise<any[]> {
  const url = "https://nec.thesearchbubble.com:3136/api/";

  const myHeaders = new Headers({
    "Content-Type": "application/json",
    "Accept-Charset": "UTF-8",
  });

  const raw = JSON.stringify({
    text: queryTerm,
    creator: username,
  });

  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    // const dataArray = result.slice(1, 10).split(", ");
    const secondArray =result.split("],")[1].slice(1, -1).split(", ");
    const filteredArray = secondArray?.filter((element:any) => element > 21.3);

// Log the filtered array
    const dataArray = result.slice(1, -1).split(", ");
    const dataArrayCut = dataArray.slice(0, filteredArray.length);

    const promises = dataArrayCut.map(async (obj: any) => {
      const match = obj.match(/(\d+)/g);
      return match[0];
    });

    const finalList = await Promise.all(promises);

    // Use Set to remove duplicates
    const uniqueFinalList = [...new Set(finalList)];

    return uniqueFinalList;
  } catch (error) {
    // Handle errors if needed
    return [];
  }
}