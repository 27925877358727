import { Button, Input, Spin } from "antd";
import React, { useState } from "react";
import { changeSearchBarText } from "../../SupabaseServices/UserDatabase";

const SearchBarText = (props: any) => {
  const { isLoading, userData } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [newSearchName, setNewSearchName] = useState("");
  const handleUpdate = async () => {
    await changeSearchBarText(userData?.user_id, newSearchName).then(() => {
      props.refetch();
      setIsEdit(false);
    });
  };
  const handleCancel = () => {
    setIsEdit(false);
  };
  return (
    <>
      <div className="account-description-wrapper">
        <div className="account-social-logins-heading">
          <h5>
            Search Bar Placeholder Text{" "}
            <>
              {!isEdit ? (
                <Button
                  className="edit-button"
                  onClick={() => {
                    setNewSearchName(
                      userData?.search_bar_text
                        ? userData?.search_bar_text
                        : `Search ${userData?.name}'s Page`
                    );
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    className="social-button-style"
                    onClick={() => {
                      // setIsEdit(false);
                      handleUpdate();
                    }}
                    disabled={!newSearchName}
                  >
                    Save
                  </Button>
                  <Button
                    className="social-button-style"
                    onClick={() => {
                      // setIsEdit(false);
                      handleCancel();
                    }}
                    //  disabled={!newSearchName}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </>
          </h5>
        </div>
        {isLoading ? (
          <div
            className="spin-style"
          >
            <Spin />
          </div>
        ) : (
          <>
            {userData?.name ? (
              <>
                <div className="account-social-content-connect">
                  <React.Fragment>
                    <div className="account-social-link-connect-row">
                      <div
                        className="account-social-link-connect-row-cont connect-row-width"
                      >
                        {isEdit ? (
                          <Input
                            value={newSearchName}
                            onChange={(e: any) =>
                              setNewSearchName(e.target.value)
                            }
                            // style={{width:'80%',textAlign:"center"}}
                          />
                        ) : (
                          <p>
                            {userData?.search_bar_text
                              ? userData?.search_bar_text
                              : `Search ${userData?.name}'s Page`}
                          </p>
                        )}
                      </div>
                    </div>

                    <hr
                      className="search-hr"
                    />
                  </React.Fragment>
                </div>
              </>
            ) : (
              <div
                className="account-social-content-connect content-connect-color"
              ></div>
            )}
          </>
        )}{" "}
      </div>
    </>
  );
};

export default SearchBarText;
