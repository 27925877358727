import { createClient } from "@supabase/supabase-js";

const supabaseUrl: any = "https://nneqehyphaavozzymtwp.supabase.co";
const supabaseAnonKey: any =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5uZXFlaHlwaGFhdm96enltdHdwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjY4NDMwMDUsImV4cCI6MTk4MjQxOTAwNX0.Cted088Q4phMIuer7L5kSey4CU1CxRHxz5JnsxMGGuM";

// const supabaseUrl: any = "https://dqmncccmjrpgsdpvjbvp.supabase.co";
// const supabaseAnonKey="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRxbW5jY2NtanJwZ3NkcHZqYnZwIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4NTU0OTczOCwiZXhwIjoyMDAxMTI1NzM4fQ.AhJXXNIdnO-T6BHCGPfkPaf3NjqXgeVp5FYeLOq-PiQ"
// const supabaseAnonKey =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRxbW5jY2NtanJwZ3NkcHZqYnZwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU1NDk3MzgsImV4cCI6MjAwMTEyNTczOH0.tCc9kb8-FpgeB1s249aCy3GEtBwF_KdOeYheLBxcOtI";

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
