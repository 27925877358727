import dayjs from "dayjs";
import { supabase } from "../Config/SupabaseClient";

export async function getGeneralPageCount(
  route: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc("get_page_views_date_range", {
    route_name: route,
    start_date: startDate,
    end_date: endDate,
  });

  if (data) {
    if (typeof data[0].count === "number") {
      return data[0].count;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}
export async function getUserPageCount(
  username: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc("get_user_page_count_date_range", {
    username: username,
    start_date: startDate,
    end_date: endDate,
  });
}
export async function fetchPageAnalytics(
  username: any,
  startDate: any,
  endDate: any
) {
  const { data: analytics, error: anError }: any = await supabase
    .from("analytics")
    .select("*")
    .eq("type", "page")
    .ilike("pathname", `%${username}`)
    .lt("created_at", endDate)
    .gt("created_at", startDate);

  let liveCount = 0;
  let creatorCount = 0;
  let dashboardCount = 0;
  let followerCount = 0;
  analytics.map((data: any) => {
    if (data.pathname.includes("/creator")) {
      creatorCount = creatorCount + 1;
    } else if (data.pathname.includes("/follower")) {
      followerCount = followerCount + 1;
    } else if (data.pathname.includes("/dashboard")) {
      dashboardCount = dashboardCount + 1;
    } else {
      liveCount = liveCount + 1;
    }
  });

  return analytics;
}
export async function fetchTrendingAnalytics() {
  var { data: users, error }: any = await supabase.from("user").select("*");

  if (users.length) {
    let usernames: any = [];
    users.map((user: any) => {
      usernames.push(user.username);
    });

    const obj: any = {};
    usernames.map(async (username: any) => {
      var { data: creatorCount, error }: any = await supabase
        .from("analytics")
        .select("view_count")
        .eq("page", `/creator/${username}`);
      if (creatorCount.length) {
        const count = creatorCount[0].view_count;
        obj[username] = count;
      }

      return true;
    });
  }
}

export async function getCreatorViewCount(username: any) {
  const { data, error }: any = await supabase
    .from("user_pages")
    .select("creator")
    .eq("username", username);
  if (data.length) {
    const count = data[0].view_count;
    return count;
  }
}
export async function getDashboardViewCount(username: any) {
  const { data, error }: any = await supabase
    .from("analytics")
    .select("view_count")
    .eq("page", `/dashboard/${username}`);
  if (data.length) {
    const count = data[0].view_count;
    return count;
  }
}
export async function getFollowerViewCount(username: any) {
  const { data, error }: any = await supabase
    .from("analytics")
    .select("view_count")
    .eq("page", `/follower/${username}`);
  if (data.length) {
    const count = data[0].view_count;
    return count;
  }
}
export async function getLiveViewCount(username: any) {
  const { data, error }: any = await supabase
    .from("analytics")
    .select("view_count")
    .eq("page", `/${username}`);
  if (data.length) {
    const count = data[0].view_count;
    return count;
  }
}

export async function getPageAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  if (daysNumber === 0 || daysNumber === 30 || daysNumber === undefined) {
    const { data } = await supabase.rpc("get_page_views_date_analyticsss", {
      route_name: `/${username}`,
      start_date: startDate,
      end_date: endDate,
      userid: user_id,
      // is_yesterday:isYesterday,
    });
    if (data && data.length) {
      return data.reverse();
      // }else{
      //   return data;

      // }
    } else {
      return 0;
    }
  } else if (daysNumber === 1) {
    let { data } = await supabase.rpc("get_page_views_date_analyticsssy", {
      route_name: `/${username}`,
      start_date: dayjs().subtract(0, "day").format("YYYY-MM-DD"),

      end_date: endDate,
      userid: user_id,
      is_yesterday: isYesterday,
    });
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);
        // Reverse the array
        return data.reverse();
      } else if (daysNumber === 1) {
        data = data.slice(3);
        // Reverse the array
        return data.reverse();
      } else {
        return data;
      }
    } else {
      return 0;
    }
  } else {
    let { data } = await supabase.rpc("get_page_views_date_analyticsssy", {
      route_name: `/${username}`,
      start_date: startDate,
      end_date: endDate,
      userid: user_id,
      is_yesterday: isYesterday,
    });
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);
        // Reverse the array
        return data.reverse();
      } else if (daysNumber === 1) {
        data = data.slice(3);
        // Reverse the array
        return data.reverse();
      } else {
        return data;
      }
    } else {
      return 0;
    }
  }
}

export async function getUniqueUsers(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  const { data } = await supabase.rpc("fetch_unique_users_date_rangee", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });
  if (data && data.length) {
    return data[0].count;
  }
  // if (typeof data === "number") {
  // 	return data;
  // }
  else {
    return 0;
  }
}
export async function getPageViews(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  const { data } = await supabase.rpc("get_page_views_date_rangee", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });

  if (data && data.length) {
    return data[0].count;
  } else {
    return 0;
  }
}
export async function getTotalPageViews(
  user: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc("get_page_views_date_rangee_all", {
    route_name: user?.creators.map((elem: any) => `/${elem}`),
    start_date: startDate,
    end_date: endDate,
    userid: user?.ids,
  });

  if (data && data.length) {
    return data[0].count;
  } else {
    return 0;
  }
}
export async function getpreviousTotalPageViews(
  user: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc("get_page_views_date_rangee_all", {
    route_name: user?.creators.map((elem: any) => `/${elem}`),
    start_date: startDate,
    end_date: endDate,
    userid: user?.ids,
  });

  if (data && data.length) {
    return data[0].count;
  } else {
    return 0;
  }
}

export async function getTotalUniqueUsers(
  user: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc("fetch_unique_users_date_rangee_all", {
    route_name: user?.creators.map((elem: any) => `/${elem}`),
    start_date: startDate,
    end_date: endDate,
    userid: user?.ids,
  });
  if (data && data.length) {
    return data[0].count;
  }
  // if (typeof data === "number") {
  // 	return data;
  // }
  else {
    return 0;
  }
}
export async function getpreviousTotalUniqueUsers(
  user: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc("fetch_unique_users_date_rangee_all", {
    route_name: user?.creators.map((elem: any) => `/${elem}`),
    start_date: startDate,
    end_date: endDate,
    userid: user?.ids,
  });
  if (data && data.length) {
    return data[0].count;
  }
  // if (typeof data === "number") {
  // 	return data;
  // }
  else {
    return 0;
  }
}

export async function getTotalSearchesCount(
  user: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc("fetch_searches_count_date_rangee_all", {
    route_name: user?.creators.map((elem: any) => `/${elem}`),
    start_date: startDate,
    end_date: endDate,
    userid: user?.ids,
  });
  if (data && data.length) {
    return data[0].count;
  }
  // if (typeof data === "number") {
  // 	return data;
  // }
  else {
    return 0;
  }
}

export async function getpreviousTotalSearchesCount(
  user: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc("fetch_searches_count_date_rangee_all", {
    route_name: user?.creators.map((elem: any) => `/${elem}`),
    start_date: startDate,
    end_date: endDate,
    userid: user?.ids,
  });
  if (data && data.length) {
    return data[0].count;
  }
  // if (typeof data === "number") {
  // 	return data;
  // }
  else {
    return 0;
  }
}

export async function getTotalShoppableClickCount(
  user: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc(
    "fetch_shoppable_click_count_date_rangee_all",
    {
      route_name: user?.creators.map((elem: any) => `/${elem}`),
      start_date: startDate,
      end_date: endDate,
      userid: user?.ids,
    }
  );
  if (data && data.length) {
    return data[0].count;
  }
  // if (typeof data === "number") {
  // 	return data;
  // }
  else {
    return 0;
  }
}

export async function getpreviousTotalShoppableClickCount(
  user: any,
  startDate: any,
  endDate: any
) {
  const { data } = await supabase.rpc(
    "fetch_shoppable_click_count_date_rangee_all",
    {
      route_name: user?.creators.map((elem: any) => `/${elem}`),
      start_date: startDate,
      end_date: endDate,
      userid: user?.ids,
    }
  );
  if (data && data.length) {
    return data[0].count;
  }
  // if (typeof data === "number") {
  // 	return data;
  // }
  else {
    return 0;
  }
}

export async function getMultipleUsersPostClicksViewsAnalytics(
  usernames: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  const results: any = {};
  const sortedResult:any={}
  console.log("fhs", usernames);
  // Iterate over each username
  for (const [i, username] of usernames.creators.entries()) {
    results[username] = await getPostClicksAnalytics(
      username,
      startDate,
      endDate,
      usernames.ids[i],
      isYesterday,
      daysNumber
    );

   sortedResult[username] = getTop10ClickedPosts(results[username]);
  }
  return sortedResult;
}

export async function getPostClicksViewsAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  if (
    daysNumber === 0 ||
    daysNumber === 30 ||
    daysNumber === 7 ||
    daysNumber === undefined
  ) {
    let { data, error } = await supabase.rpc(
      "get_post_clicks_views_date_analyticsss",
      {
        route_name: `/${username}`,
        start_date: startDate,
        end_date: endDate,
        userid: user_id,
      }
    );
    if (data && data.length) {
      data.reverse();

      const acc: any = {};

      data.forEach((item: any) => {
        const post = JSON.parse(item.post);
        const title = post.title;

        if (!acc[title]) {
          acc[title] = [];
        }

        const existingDateEntry = acc[title].find(
          (entry: any) => entry.formatted_date === item.formatted_date
        );

        if (existingDateEntry) {
          existingDateEntry.clicks += item.count;
        } else {
          acc[title].push({
            formatted_date: item.formatted_date,
            clicks: item.count,
          });
        }
      });
      return acc;
    } else {
      return 0;
    }
  } else if (daysNumber === 1) {
    let { data } = await supabase.rpc(
      "get_post_clicks_views_date_analyticsssy",
      {
        route_name: `/${username}`,
        start_date: dayjs().subtract(0, "day").format("YYYY-MM-DD"),

        end_date: endDate,
        userid: user_id,
        is_yesterday: isYesterday,
      }
    );
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else if (daysNumber === 1) {
        data = data.slice(3);

        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else {
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      }
    } else {
      return 0;
    }
  } else {
    let { data } = await supabase.rpc("get_post_clicks_date_analyticsssy", {
      route_name: `/${username}`,
      start_date: startDate,
      end_date: endDate,
      userid: user_id,
      is_yesterday: isYesterday,
    });
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else if (daysNumber === 1) {
        data = data.slice(3);
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else {
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      }
    } else {
      return 0;
    }
  }
}

function getTop10ClickedPosts(data: any) {
  let posts: any = [];

  // Flatten the object, attaching category to each post
  for (let category in data) {
    data[category].forEach((post: any) => {
      posts.push({
        category,
        ...post,
      });
    });
  }

  // Sort by the number of clicks

  posts.sort((a: any, b: any) => b.count - a.count);
  // Get top 10 most clicked posts
  return posts.slice(0, 10);
}

export async function getPostClicksAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  let postClicksObj: any = [];
  const { data } = await supabase.rpc("get_post_clicks_analyticss", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });
  if (data) {
    const transformedData = data.reduce((acc: any, item: any) => {
      const post = JSON.parse(item.post);

      if (!acc[post.title]) {
        acc[post.title] = [];
      }

      acc[post.title].push({
        post_id: post.post_id,
        media_url: post.media_url,
        count: item.count,
        name: post.name,
      });

      return acc;
    }, {});
    return transformedData;
  }
}
