import { Button } from "antd";
import React, { useState } from "react";
import { supabase } from "../../../../Config/SupabaseClient";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";
import axios from "axios";

const HighlightsUpload = (props: any) => {
    const {username}=props;
    const [highlightCategory, setHighlightCategory] = useState("");
    const [highlightSelectedFiles, setHighlightSelectedFiles] = useState<any>([]);

    const handleHighlightFolderChange = async (event: any) => {
        const files = Array.from(event.target.files);
        const filteredFiles = files.filter((file: any) => file.size > 0);
    
        setHighlightSelectedFiles(filteredFiles);
      };

      const uploadHighlightFolder = async () => {
        const uploadData: any = [];
        const csvFiles: any = [];
        const userData: any = await getUserData(username);
        const post_date = `${Math.floor(Math.random() * 100) + 1900}-${String(
          Math.floor(Math.random() * 12) + 1
        ).padStart(2, "0")}-${String(Math.floor(Math.random() * 28) + 1).padStart(
          2,
          "0"
        )} ${String(Math.floor(Math.random() * 24)).padStart(2, "0")}:${String(
          Math.floor(Math.random() * 60)
        ).padStart(2, "0")}:${String(Math.floor(Math.random() * 60)).padStart(
          2,
          "0"
        )}+00`;
    
        let latestDate = null;
    
        // highlightSelectedFiles.map(async (file: any) => {
        let index = 1;
        for (let file of highlightSelectedFiles) {
          const underscoreIndex = file.name.indexOf("_");
          const dotIndex = file.name.indexOf(".");
    
          if (underscoreIndex !== -1 && dotIndex !== -1) {
            if (latestDate) {
              const previousPostDate: any = new Date(latestDate);
              previousPostDate.setUTCMinutes(previousPostDate.getUTCMinutes() - 2);
              latestDate = previousPostDate
                .toISOString()
                .replace(/\.\d{3}Z/, "+00")
                .replace("T", " ");
            } else {
              latestDate = post_date;
            }
    
            const post_id = file.name.substring(0, underscoreIndex);
            const mediaType = file.type.split("/")[0];
            const mediaExt = file.type.split("/")[1];
            console.log(index, latestDate);
            const { data, error } = await supabase.storage
              .from("highlights")
              .upload(
                `${username}/${highlightCategory}/${mediaType}${post_id}.${mediaExt}`,
                file,
                {
                  contentType: `${file.type}`,
                }
              );
            console.log("error", error);
            const { data: url }: any = await supabase.storage
              .from("highlights")
              .getPublicUrl(
                `${username}/${highlightCategory}/${mediaType}${post_id}.${mediaExt}`
              );
    
            const { data: storedPost, error: storedPostError } = await supabase
              .from("content_instagram")
              .select("*")
              .eq("post_id", post_id)
              .eq("user_id", userData.user_id);
    
            if (storedPost && storedPost.length === 0) {
              const { data: content, error: contentError }: any = await supabase
                .from("content_instagram")
                .insert([
                  {
                    post_id,
                    user_id: userData.user_id,
                    post_date: latestDate,
                    caption: " ",
                    media_url: url.publicUrl,
                    type: "HIGHLIGHTS",
                    storage_url: url.publicUrl,
                    highlights_category: highlightCategory,
                  },
                ])
                .select("*");
    
              await supabase.from("content_new").insert([
                {
                  post_id,
                  user_id: userData.user_id,
                  caption: " ",
                  media_url: url.publicUrl,
                  type: "HIGHLIGHTS",
                  platform: "instagram",
                  post_date: latestDate,
                  raw_content_id: content[0].id,
                  content_category: highlightCategory,
                  tagg: `|${highlightCategory}|`,
                  combined_text: highlightCategory,
                  last_edited:new Date().toISOString()

                },
              ]);
              console.log("highlight uploaded", index, content, contentError);
            }
          }
          index += 1;
        }
        // );
        console.log("wait...");
        const time = highlightSelectedFiles.length * 1500;
        console.log(time);
        setTimeout(async () => {
          // const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
          // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;
          const thumbnailUrl = `https://dev.thesearchbubble.com/api/thumbnail`;

          const resThumbnail = await axios.post(
            thumbnailUrl,
            { id: userData.user_id, user: userData.username, from: "content" },
            {
              headers: {
                "content-type": "application/json",
              },
            }
          );
          console.log("res", resThumbnail.data);
          if (resThumbnail.data === "done") {
            console.log("Processing your data : Thumbnails, tags, embeddings");
            console.log("Please wait !!!");
            setTimeout(async () => {
              const { data: update, error } = await supabase
                .from("user")
                .update({ show_instagram: true, is_instagram_on: true })
                .eq("user_id", userData.user_id);
            }, highlightSelectedFiles.length * 1000);
            console.log("updates user");
          }
        }, time);
      };
  return (
    <div>
      {" "}
      <h5>Upload Bulk Highlights</h5>
      <input
        required
        className="category-input input-value"
        type="text"
        placeholder="enter highlight category"
        onChange={(e: any) => setHighlightCategory(e.target.value)}
        value={highlightCategory}
      />
      <h6>Upload folder</h6>
      <input type="file" multiple onChange={handleHighlightFolderChange} />
      <Button
        type="primary"
        className="primary-color"
        onClick={uploadHighlightFolder}
      >
        Upload
      </Button>
    </div>
  );
};

export default HighlightsUpload;







// import { Button } from "antd";
// import React, { useState } from "react";
// import { supabase } from "../../../../Config/SupabaseClient";
// import { getUserData } from "../../../../SupabaseServices/UserDatabase";
// import axios from "axios";

// const HighlightsUpload = (props: any) => {
//   const { username } = props;
//   const [highlightCategory, setHighlightCategory] = useState("");
//   const [highlightSelectedFiles, setHighlightSelectedFiles] = useState<any>([]);
//   // function handleName
//   const handleHighlightFolderChange = async (event: any) => {
//     const files = Array.from(event.target.files);
//     const filteredFiles = files.filter((file: any) => file.size > 0);

//     setHighlightSelectedFiles(filteredFiles);
//   };

//   const uploadHighlightFolder = async () => {
//     const uploadData: any = [];
//     const csvFiles: any = [];
//     const userData: any = await getUserData(username);
//     const post_date = `${Math.floor(Math.random() * 100) + 1900}-${String(
//       Math.floor(Math.random() * 12) + 1
//     ).padStart(2, "0")}-${String(Math.floor(Math.random() * 28) + 1).padStart(
//       2,
//       "0"
//     )} ${String(Math.floor(Math.random() * 24)).padStart(2, "0")}:${String(
//       Math.floor(Math.random() * 60)
//     ).padStart(2, "0")}:${String(Math.floor(Math.random() * 60)).padStart(
//       2,
//       "0"
//     )}+00`;

//     let latestDate = null;

//     // highlightSelectedFiles.map(async (file: any) => {
//     let index = 1;
//     for (let file of highlightSelectedFiles) {
//       const underscoreIndex = file.name.indexOf("_");
//       const dotIndex = file.name.indexOf(".");

//       if (underscoreIndex !== -1 && dotIndex !== -1) {
//         if (latestDate) {
//           const previousPostDate: any = new Date(latestDate);
//           previousPostDate.setUTCMinutes(previousPostDate.getUTCMinutes() - 2);
//           latestDate = previousPostDate
//             .toISOString()
//             .replace(/\.\d{3}Z/, "+00")
//             .replace("T", " ");
//         } else {
//           latestDate = post_date;
//         }

//         const post_id = file.name.substring(0, underscoreIndex);
//         const mediaType = file.type.split("/")[0];
//         const mediaExt = file.type.split("/")[1];
//         console.log(index, latestDate);
//         const { data, error } = await supabase.storage
//           .from("highlights")
//           .upload(
//             `${username}/${highlightCategory}/${mediaType}${post_id}.${mediaExt}`,
//             file,
//             {
//               contentType: `${file.type}`,
//             }
//           );
//         console.log("error", error);
//         if (error === null) {
//           const { data: url }: any = await supabase.storage
//             .from("highlights")
//             .getPublicUrl(
//               `${username}/${highlightCategory}/${mediaType}${post_id}.${mediaExt}`
//             );

//           const { data: storedPost, error: storedPostError } = await supabase
//             .from("content_instagram")
//             .select("*")
//             .eq("post_id", post_id)
//             .eq("user_id", userData.user_id);

//           if (storedPost && storedPost.length === 0) {
//             const { data: content, error: contentError }: any = await supabase
//               .from("content_instagram")
//               .insert([
//                 {
//                   post_id,
//                   user_id: userData.user_id,
//                   post_date: latestDate,
//                   caption: " ",
//                   media_url: url.publicUrl,
//                   type: "HIGHLIGHTS",
//                   storage_url: url.publicUrl,
//                   highlights_category: highlightCategory,
//                 },
// <<<<<<< HEAD
//               ]);
//               console.log("highlight uploaded", index, content, contentError);
//             }
//           }
//           index += 1;
//         }
//         // );
//         console.log("wait...");
//         const time = highlightSelectedFiles.length * 1500;
//         console.log(time);
//         setTimeout(async () => {
//           // const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
//           // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;
//           const thumbnailUrl = `https://dev.thesearchbubble.com/api/thumbnail`;

//           const resThumbnail = await axios.post(
//             thumbnailUrl,
//             { id: userData.user_id, user: userData.username, from: "content" },
//             {
//               headers: {
//                 "content-type": "application/json",
// =======
//               ])
//               .select("*");

//             await supabase.from("content_new").insert([
//               {
//                 post_id,
//                 user_id: userData.user_id,
//                 caption: " ",
//                 media_url: url.publicUrl,
//                 type: "HIGHLIGHTS",
//                 platform: "instagram",
//                 post_date: latestDate,
//                 raw_content_id: content[0].id,
//                 content_category: highlightCategory,
//                 tagg: `|${highlightCategory}|`,
//                 combined_text: highlightCategory,
//                 last_edited: new Date().toISOString(),
// >>>>>>> main
//               },
//             ]);
//             console.log("highlight uploaded", index, content, contentError);
//           }
//         }
//       }
//       index += 1;
//     }
//     // );
//     console.log("wait...");
//     const time = highlightSelectedFiles.length * 1500;
//     console.log(time);
//     setTimeout(async () => {
//       const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
//       // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;

//       // const thumbnailUrl = `https://dev.thesearchbubble.com/api/thumbnail`;
//       // const thumbnailUrl = `http://localhost:3001/api/thumbnail`;

//       const resThumbnail = await axios.post(
//         thumbnailUrl,
//         { id: userData.user_id, user: userData.username, from: "content" },
//         {
//           headers: {
//             "content-type": "application/json",
//           },
//         }
//       );
//       console.log("res", resThumbnail.data);
//       if (resThumbnail.data === "done") {
//         console.log("Processing your data : Thumbnails, tags, embeddings");
//         console.log("Please wait !!!");
//         setTimeout(async () => {
//           const { data: update, error } = await supabase
//             .from("user")
//             .update({ show_instagram: true, is_instagram_on: true })
//             .eq("user_id", userData.user_id);
//         }, highlightSelectedFiles.length * 1000);
//         console.log("updates user");
//       }
//     }, time);
//   };
//   console.log("highligh", highlightCategory);
//   const sanitizeInput = (input: string): string => {
//     const emojiAndSymbolRegex =
//       /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|[\uFE00-\uFE0F]|[\uD83D-\uDE00-\uDE4F]|[\u2600-\u26FF]|[!@#$%^&*(),.?":{}|<>']|:\w+:\s*)/g;

//     // Remove emojis, symbols, and shortcodes from the input value
//     const sanitizedValue = input.replace(emojiAndSymbolRegex, "").trim();

//     // Return the cleaned value, ensuring no extra spaces
//     return sanitizedValue.replace(/\s+/g, " "); // Op
//   };
//   return (
//     <div>
//       {" "}
//       <h5>Upload Bulk Highlights</h5>
//       <input
//         required
//         className="category-input input-value"
//         type="text"
//         placeholder="enter highlight category"
//         // onChange={(e: any) => {
//         //   // Regular expression to match emojis
//         //   const emojiRegex =
//         //     /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|[\uFE00-\uFE0F]|[\uD83D-\uDE00-\uDE4F]|[\u2600-\u26FF])/g;

//         //   // Remove emojis from the input value
//         //   const sanitizedValue = e.target.value.replace(emojiRegex, "");

//         //   // Set the cleaned value to the state
//         //   setHighlightCategory(sanitizedValue);
//         // }}

//         onChange={(e: any) => {
//           // Regular expression to match emojis and symbols, including single quotes
//           const inputValue = e.target.value;
//           const sanitizedValue = sanitizeInput(inputValue);

//           // Set the cleaned value to the state
//           setHighlightCategory(sanitizedValue);
//         }}
//         value={highlightCategory}
//       />
//       <h6>Upload folder</h6>
//       <input type="file" multiple onChange={handleHighlightFolderChange} />
//       <Button
//         type="primary"
//         className="primary-color"
//         onClick={uploadHighlightFolder}
//       >
//         Upload
//       </Button>
//     </div>
//   );
// };

// export default HighlightsUpload;
