import "../Analytics.css";
function CustomCategoriesTable({ searches ,from}: { searches: any ,from:any}) {
	return (
		<table className='analytics-table'>
			<thead>
				<tr>
					{from==="links"?<th>Link Name</th>:<th>Subcategories</th>}
					<th>Clicks</th>
				</tr>
			</thead>

			{searches
				? searches.map((oneKey: any, index: any) => (
						<tbody key={index}>
							<tr>
								<td>{oneKey?.query}</td>
								<td>{oneKey?.count}</td>
							</tr>
						</tbody>
				  ))
				: ""}
		</table>
	);
}

export default CustomCategoriesTable;
