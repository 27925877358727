import { Button } from 'antd'
import axios from 'axios';
import React, { useState } from 'react'
import { supabase } from '../../../../Config/SupabaseClient';
import { getUserData } from '../../../../SupabaseServices/UserDatabase';

const TiktokUpload = (props:any) => {
    const {username}=props;
    const [tiktokSelectedFiles, setTiktokSelectedFiles] = useState<any>([]);

    const handleTiktokFolderChange = async (event: any) => {
      const files = Array.from(event.target.files);
      setTiktokSelectedFiles(files);
    };
    async function uploadTiktokFolder() {
      const userData: any = await getUserData(username);
      console.log("user", userData, tiktokSelectedFiles);
      // tiktokSelectedFiles.map(async (file: any) => {
      for (let file of tiktokSelectedFiles) {
        console.log("file", file, file.name);
        const match = file.name.match(/\d+/);
        const post_id = match[0];
        const mediaType = file.type.split("/")[0];
        const mediaExt = file.type.split("/")[1];
        const post_date = `${Math.floor(Math.random() * 100) + 1900}-${String(
          Math.floor(Math.random() * 12) + 1
        ).padStart(2, "0")}-${String(Math.floor(Math.random() * 28) + 1).padStart(
          2,
          "0"
        )} ${String(Math.floor(Math.random() * 24)).padStart(2, "0")}:${String(
          Math.floor(Math.random() * 60)
        ).padStart(2, "0")}:${String(Math.floor(Math.random() * 60)).padStart(
          2,
          "0"
        )}+00`;
        const permalink = `https://www.tiktok.com/@${userData.tiktok_user}/video/${post_id}`;
  
        const { data, error } = await supabase.storage
          .from("tiktok")
          .upload(`${username}/${mediaType}${post_id}.${mediaExt}`, file, {
            contentType: `${file.type}`,
          });
        console.log("error", error);
        const { data: url }: any = await supabase.storage
          .from("tiktok")
          .getPublicUrl(`${username}/${mediaType}${post_id}.${mediaExt}`);
  
        const { data: storedPost, error: storedPostError } = await supabase
          .from("content_tiktok")
          .select("*")
          .eq("post_id", post_id)
          .eq("user_id", userData.user_id);
  
        if (storedPost && storedPost.length === 0) {
          const { data: content, error: contentError }: any = await supabase
            .from("content_tiktok")
            .insert([
              {
                post_id,
                user_id: userData.user_id,
                post_date: post_date,
                caption: " ",
                media_url: url.publicUrl,
                type: "TIKTOK",
                storage_url: url.publicUrl,
                permalink: permalink,
                // highlights_category: highlightCategory,
              },
            ])
            .select("*");
  
          await supabase.from("content_new").insert([
            {
              post_id,
              user_id: userData.user_id,
              caption: " ",
              media_url: url.publicUrl,
              type: "TIKTOK",
              platform: "tiktok",
              post_date: post_date,
              raw_content_id: content[0].id,
           
              permalink: permalink,
              combined_text: " ",
            },
          ]);
        }
      }
  
      console.log("wait...");
      const time = tiktokSelectedFiles.length * 2000;
      console.log(time);
      setTimeout(async () => {
        // const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
        // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;
        const thumbnailUrl = `https://dev.thesearchbubble.com/api/thumbnail`;

        const resThumbnail = await axios.post(
          thumbnailUrl,
          { id: userData.user_id, user: userData.username, from: "content" },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        console.log("res", resThumbnail.data);
        if (resThumbnail.data === "done") {
          console.log("Processing your data : Thumbnails, tags, embeddings");
          console.log("Please wait !!!");
        }
      }, time);
      const waitTime = tiktokSelectedFiles.length * 3000;
      // setTimeout(async () => {
        const { data: update, error } = await supabase
          .from("user")
          .update({ show_tiktok: true, is_tiktok_on: true })
          .eq("user_id", userData.user_id);
        console.log("updates user");
      // }, waitTime);
    }
  return (
    <div><h5>Upload Tiktok Data</h5>
    <h6>Upload folder</h6>
    <input type="file" multiple onChange={handleTiktokFolderChange} />
    <Button
      type="primary"
      className='primary-color'
      onClick={uploadTiktokFolder}
    >
      Upload
    </Button></div>
  )
}

export default TiktokUpload