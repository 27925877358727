import React, { useState } from "react";
import { Alert, Button, Input, Modal, Spin } from "antd";
import { useAuth } from "../../hooks/useAuth";
import "./AccountsIntegration.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdOutlineDragIndicator } from "react-icons/md";
import { useLoadLinks } from "../../Utils/CustomHooks/useLoadLinks";
import useUpdateLinkTag from "./useUpdateLinkTag";
import { storeLinks } from "../../SupabaseServices/UserDatabase";
const AccountsIntegration = (props: any) => {
  const { isLinksModalOpen, username } = props;
  const { user } = useAuth();
  const user_id = user?.id || "";
  const [linkURL, setLinkURL] = useState("");
  const [linkName, setLinkName] = useState("");
  const [isEditLink, setIsEditLink] = useState(false);
  const [isEditLinkName, setIsEditLinkName] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [showDeleteConfirmationIndex, setShowDeleteConfirmationIndex] =
    useState(-1);
  const [errorLink, setErrorLink] = useState("");

  const { mutate: updateLinkTags } = useUpdateLinkTag();
  let {
    isLoading,

    data,

    refetch,
  } = useLoadLinks(user_id);
  const [localLinks, setLocalLinks] = useState("")
  const [isDragging, setIsDragging] = useState(false)
  const handleOk = async () => {
    const urlRegex = /^(?:(https?|ftp):\/\/)?(www\.)?[^\s/$.?#].[^\s]*$/;

    if (linkURL && linkName) {
      if (urlRegex.test(linkURL)) {
        const res = await storeLinks(user_id, username, linkName, linkURL);
        if (res) {
          refetch();
          props.setIsLinksModalOpen(false);
          setErrorLink("");
          setLinkURL("");
          setLinkName("");
        }
      } else {
        setErrorLink("Enter valid url");
      }
    } else {
      if (linkName) {
        setErrorLink("Enter valid url");
      } else if (linkURL) {
        setErrorLink("Enter valid name");
      }
    }
  };
  const removeTag = (index: any) => {
    setShowDeleteConfirmationIndex(index);
  };

  const handleDeleteConfirmation = (index: any) => {
    removeTagLogic(index);
    setShowDeleteConfirmationIndex(-1);
    setIsEditLink(false);
    setIsEditLinkName(false);
    setEditIndex(-1);
    setLinkURL("");
    setLinkName("");
  };

  const removeTagLogic = (index: any) => {
    const updatedData = data.split(",").filter((_: any, i: any) => i !== index);
    updateLinkTags({
      tagString: updatedData.toString(),
      user_id,
      pageIndex: 1,
      index: 1,
    });
  };

  const handleCancel = () => {
    props.setIsLinksModalOpen(false);
    setErrorLink("");
    setLinkURL("");
    setLinkName("");
  };

  function editLink(link: any) {
    setLinkURL(link.split(":-")[1]);
  }

  const editLinkName = (link: any) => {
    setLinkName(link.split(":-")[0]);

    setEditIndex(-1);
  };
  const handleEdit = (data: any, changedLink: any) => {
    const idx = data.indexOf(changedLink);

    data.splice(idx, 1);

    const newLink = `${linkName}:-${linkURL}`;
    data.splice(idx, 0, newLink);
    updateLinkTags({
      tagString: data.toString(),
      user_id,
      pageIndex: 1,
      index: 1,
    });

    setIsEditLinkName(false);
    setIsEditLink(false);
    setEditIndex(-1);
  };

  const handleKeyPress = async (data: any, link: any, element: any) => {
    handleEdit(data, link);
  };

  function onDragEnd(result: any) {
    setIsDragging(true)
    const { destination, source, draggableId } = result;
    // Check if there's no destination (i.e., dropped outside of droppable)
    if (!destination) {
      return;
    }

    // Check if the item was dropped in its original location
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Reorder logic goes here based on the provided result object
    // You might want to update your state to reflect the new order of items
    // Example:
    const newLinks = Array.from(data.split(","));
    const movedLink = newLinks.splice(source.index, 1)[0];
    newLinks.splice(destination.index, 0, movedLink);
    updateLinkTags({
      tagString: newLinks.toString(),
      user_id,
      pageIndex: 1,
      index: 1,
    });
    // refetch()
    data = newLinks.toString();
    setLocalLinks(newLinks.toString())
    refetch()
    setTimeout(() => {
      setIsDragging(false)
    }, 4000)
      ;
  }
  return (
    <>
      <>
        <div className="account-social-content-connect">
          {isLoading ? (
            <div
              className="spin-style"
            >
              <Spin />
            </div>
          ) : (
            <>
              {data ? (
                <>
                  <DragDropContext
                    onDragEnd={(result: any) => {
                      onDragEnd(result);
                    }}
                  >
                    <Droppable droppableId="links">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {(isDragging ? localLinks : data).split(",").map((link: any, index: any) => {
                            const draggableId = `link-${index}`;
                            const positionNames = [
                              "First",
                              "Second",
                              "Third",
                              "Fourth",
                              "Fifth",
                              "Sixth",
                              "Seventh",
                              "Eighth",
                              "Ninth",
                              "Tenth",
                              "Eleventh", "Twefth", "Thirteenth", "Fourteenth", "Fifteenth", "Sixteenth", "Seventeenth", "Eighteenth", "Nineteenth", "Twentieth"
                            ];

                            const positionText =
                              positionNames[index] || `${index + 1}th`;

                            return (
                              <Draggable
                                key={index}
                                draggableId={draggableId}
                                index={index}
                              // isDragDisabled={activeKey==="active,ad"?false:true}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  // className={`feed-tab-post-row ${
                                  //   activeKey === "active,ad"
                                  //     ? "feed-tab-post-row-grab"
                                  //     : ""
                                  // } ${
                                  //   draggedIndex === index
                                  //     ? "feed-tab-post-row-dragging"
                                  //     : ""
                                  // }`}
                                  // style={activeKey === "active,ad"?{cursor:'pointer'}:{cursor:'grab'}}
                                  >
                                    <React.Fragment key={index}>
                                      <div
                                        key={index}
                                        className="account-social-link-connect-row ml-2"
                                      >
                                        <div className="account-social-link-connect-row-cont">
                                          <p>
                                          <MdOutlineDragIndicator className="mr-2 v-center"/>

                                            {positionText} Link{" "}
                                            {isEditLink &&
                                              isEditLinkName &&
                                              editIndex === index ? (
                                              <>
                                              <Button
                                                className="drop-down-button"
                                                onClick={() => {
                                                  setIsEditLinkName(false);
                                                  setIsEditLink(false);

                                                    setEditIndex(index);

                                                    handleKeyPress(
                                                      data.split(","),
                                                      link,
                                                      "all"
                                                    );
                                                  }}
                                                // disabled={errorLink?true:false}
                                              >
                                                Save
                                              </Button>
                                              <Button
                                                className="drop-down-button"
                                                onClick={() => {
                                                  setIsEditLinkName(false);
                                                  setIsEditLink(false);

                                                    setEditIndex(index);

                                                    // handleKeyPress(
                                                    //   data.split(","),
                                                    //   link,
                                                    //   "all"
                                                    // );
                                                  }}
                                                // disabled={errorLink?true:false}
                                                >
                                                  Cancel
                                                </Button>
                                              </>
                                            ) : (
                                              <Button
                                                className="drop-down-button"
                                                onClick={() => {
                                                  setIsEditLinkName(true);
                                                  setEditIndex(index);
                                                  editLinkName(link);
                                                  setIsEditLink(true);

                                                  setEditIndex(index);
                                                  editLink(link);
                                                }}
                                              >
                                                Edit
                                              </Button>
                                            )}
                                          </p>
                                          {isEditLinkName &&
                                            editIndex === index ? (
                                            <>
                                              <Input
                                                value={linkName}
                                                onChange={(e: any) => {
                                                  setLinkName(e.target.value);
                                                }}
                                                className="edit-input"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <p className="link-p">
                                                {link.split(":-")[0]}
                                              </p>
                                            </>
                                          )}
                                          {isEditLink && editIndex === index ? (
                                            <>
                                              <Input
                                                value={linkURL}
                                                onChange={(e: any) => {
                                                  e.target.value.length
                                                    ? setLinkURL(e.target.value)
                                                    : setErrorLink(
                                                      "Enter valid link url"
                                                    );
                                                }}
                                                className="edit-input"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <p className="link-p">{link.split(":-")[1]}</p>
                                            </>
                                          )}{" "}
                                          {/* {errorLink?<p style={{color:'red',textAlign:'center',}}>{errorLink}</p>:""} */}
                                          <p>
                                            {isEditLink &&
                                              isEditLinkName &&
                                              editIndex === index && (
                                                <Button
                                                  className="remove-button"
                                                  onClick={() =>
                                                    removeTag(index)
                                                  }
                                                >
                                                  Remove
                                                </Button>
                                              )}
                                          </p>
                                        </div>
                                      </div>
                                      {showDeleteConfirmationIndex ===
                                        index && (
                                        <div className="confirmation-dialog">
                                          <p className="confirmation-dialog-p">
                                            Are you sure you want to delete this
                                            link?
                                          </p>
                                          <button
                                            className="confirm-btn cancel"
                                            onClick={() =>
                                              setShowDeleteConfirmationIndex(-1)
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="confirm-btn confirm confirm-btn-color"
                                            onClick={() =>
                                              handleDeleteConfirmation(index)
                                            }
                                          >
                                            Confirm
                                          </button>
                                        </div>
                                      )}
                                      <hr
                                        className="height-space"
                                      />
                                    </React.Fragment>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              ) : (
                <p className="no-links-msg">No Links</p>
              )}
            </>
          )}
        </div>
      </>

      {isLinksModalOpen ? (
        <Modal
          title="Add link"
          open={isLinksModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Add"
        >
          {errorLink && (
            <Alert
              type="error"
              message={`Error: ${errorLink}`}
              showIcon
              className="error-alert"
            />
          )}
          <label 
          className="modal-label"
          >
            Link Name         
          </label>
          <Input
            placeholder="Name"
            required
            onChange={(e) => {
              setLinkName(e.target.value);
            }}
          />
          <br/>
          <br/>
          <label 
          className="modal-label"
          >
            URL         
          </label>
          <Input
            placeholder="www.yourlink.com"
            required
            // value={linkURL}
            onChange={(e) => {
              e.target.value.length
                ? setLinkURL(e.target.value)
                : setErrorLink("Enter valid link");
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default AccountsIntegration;
