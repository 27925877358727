import { useState } from "react";
import Navbar from "../../Components/Header/Navbar";
import "../../Styles/dashboard.css";
import Admin from "./Admin/admin";
import AnalyticsTab from "./Analytics/analyticsTab";
import Creators from "./Creators/creators";
import GlobalViewControls from "./DashboardViewControls/globalViewControls";
import { isMobile } from "react-device-detect";
import RouteList from "./routeList";
import FeedTab from "./Feed/feedTab";
import { useLocation } from "react-router-dom";
import CustomCategories from "./CustomCategories/CustomCategories";
import LiveViewSearch from "./LiveViewSearch/LiveViewSearch";
import BrokenLinkContent from "./BrokenLinks/BrokenLinkContent";
import BrokenLink from "./BrokenLinks/BrokenLink";
import Analytics from "./Analytics/analytics";
import ManageLinks from "./ManageLinks/ManageLinks";
import ManageLinksTab from "./ManageLinks/ManageLinksTab";
import Controls from "./Controls/controls";
import AccountTab from "./Account/AccountTab";
import ProductBank from "./Product Bank/ProductBank";
import CompanyAnalytics from "./Company Analytics/CompanyAnalytics";

const Dashboard = () => {
  const [option, setOption] = useState("Creators");
  const location = useLocation()
  function handleClick(tab: any) {
    setOption(tab);
  }

  return (
    <div className="dashboard">
      <div className="row">
        <div className="dash-head">
          <Navbar />
        </div>
      </div>

      {!isMobile ? (
        <div className="content-container">
          <h1 className="dashboard-mobile-show">Dashboard</h1>
          <div id="12" className="left-panel box">
            <RouteList handleClick={handleClick} />
          </div>

          <div className="right-panel box"  style={{marginLeft: "3.5%"}}>
            {option ? (
              option === "Admin" ? (
                <Admin />
              ) : option === "Global View Controls" ? (
                <GlobalViewControls />
              ) : option === "Creators" ? (
                <Creators />
              ) : option === "Analytics" ? (
                <Analytics />
              )
                : option === "Content" ? (
                  <FeedTab />
                ) : option === "Custom Categories" ? (
                  <CustomCategories />
                ) : option === "Live View Search" ? (
                  <LiveViewSearch />
                ) : option === "Broken Links" ? (
                  <BrokenLink />
                ) : option === "Manage Links" ? (
                  <ManageLinksTab />
                ) : option === "Controls" ? (
                  <Controls />
                ) : option === "Account" ? (
                  <AccountTab />
                ) : option === "Product Bank" ? (
                  <ProductBank />
                ) : option === "Company Analytics" ? (
                  <CompanyAnalytics />
                ) : (
                  ""
                )
            ) : (
              ""
            )}
            </div>
          </div>
      ) : (
        <div className="content-container">
          <h1 className="dashboard-mobile-show">Dashboard</h1>
          <div id="12" className="left-panel box">
            <RouteList handleClick={handleClick} />
          </div>

          <div id="12" className="right-panel box">
            {option ? (
              option === "Admin" ? (
                <Admin />
              ) : option === "Global View Controls" ? (
                <GlobalViewControls />
              ) : option === "Creators" ? (
                <Creators />
              ) : option === "Analytics" ? (
                <Analytics />
              ) : option === "Content" ? (
                <FeedTab />
              ) : option === "Custom Categories" ? (
                <CustomCategories />
              )
                : option === "Live View Search" ? (
                  <LiveViewSearch />
                ) : option === "Broken Links" ? (
                  <BrokenLink />
                ) : option === "Manage Links" ? (
                  <ManageLinksTab />
                ) : option === "Controls" ? (
                  <Controls />
                ) : option === "Account" ? (
                  <AccountTab />
                ) : option === "Product Bank" ? (
                  <ProductBank />
                ) : option === "Company Analytics" ? (
                  <CompanyAnalytics />
                ) : (
                  ""
                )
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
