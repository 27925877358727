import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../Config/SupabaseClient";

export default function useUpdateLinkTags() {
  const queryClient = useQueryClient();

  const submitLink = async ({
    tagString,
    user_id,
    pageIndex,
    index,
  }: {
    tagString: string;
    user_id: string;
    pageIndex: number;
    index: number;
  }) => {
    const { error } = await supabase
      .from("user")
      .update({ instagram_links: tagString })
      .eq("user_id", user_id);

    if (error) {
      throw new Error(error.message);
    } else {
      return true;
    }
  };

  return useMutation(submitLink, {
    onMutate: async ({ tagString, user_id }) => {
      await queryClient.cancelQueries(["getInstagramAddedLinks", user_id]);
      const previousData = queryClient.getQueryData([
        "getInstagramAddedLinks",
        user_id,
      ]);

      // Return the previous data for rollback on error
      const modifiedPreviousData =
        tagString; /* Modify the previous data as needed */
      // Return the modified previous data
      return { previousData: modifiedPreviousData };
      // return { previousData };
    },
    onError: (error, variables, context) => {
      // Rollback to the previous data on error
      if (context?.previousData) {
        queryClient.setQueryData(
          ["getInstagramAddedLinks", variables.user_id],
          context.previousData
        );
      }
    },
    onSuccess: (data, variables) => {
      // Invalidate and refetch the query to update the data on the frontend
      queryClient.invalidateQueries([
        "getInstagramAddedLinks",
        variables.user_id,
      ]);
    },
  });
}

// import { useMutation, useQueryClient } from "react-query";
// import { supabase } from "../../config/supabaseClient";

// export default function useUpdateLinkTags() {
//   const queryClient = useQueryClient();

//   const submitLink = async ({
//     tagString,
//     user_id,
//   }: {
//     tagString: string;
//     user_id: string;
//     pageIndex: number;
//     index: number;
//   }) => {
//     const { error } = await supabase
//       .from("user")
//       .update({ instagram_links: tagString })
//       .eq("user_id", `${user_id}`);

//     if (error) {
//       throw new Error(error.message);
//     } else {
//       return true;
//     }
//   };

//   return useMutation(submitLink, {
//     onMutate: async ({ user_id, tagString }) => {
//       await queryClient.cancelQueries(["getInstagramAddedLinks"]);
//       const previousData = queryClient.getQueryData([
//         "getInstagramAddedLinks",
//         user_id,
//         tagString,
//       ]);

//       return { previousData };
//     },
//     onError: (_error, {}, context) => {
//       queryClient.setQueryData(
//         ["getInstagramAddedLinks"],
//         context?.previousData
//       );
//     },
//     onSettled: (_data, _error, { pageIndex }) => {
//       queryClient.invalidateQueries(["getInstagramAddedLinks"], {
//         refetchPage: (page, index) => {
//           return index === pageIndex;
//         },
//       });
//     },
//   });
// }

// // export function useUpdateLinkTag() {
// //   const mutation = useMutation(
// //     async (updatedLinks: string) => {
// //       // Perform the mutation to update the links in your database
// //       const { error } = await supabase
// //         .from("user")
// //         .update({ instagram_links: updatedLinks })
// //         .eq("user_id", user_id);

// //       if (error) {
// //         throw new Error(error.message);
// //       }
// //     },
// //     {
// //       // Optional: onMutate function to rollback optimistic updates on error
// //       onMutate: async (updatedLinks: string) => {
// //         // Fetch the current data before mutation
// //         await queryClient.cancelQueries(["getInstagramAddedLinks", user_id]);
// //         const previousLinks = queryClient.getQueryData(["getInstagramAddedLinks", user_id]);
// //         queryClient.setQueryData(["getInstagramAddedLinks", user_id], updatedLinks);

// //         // Return a function to rollback the update on error
// //         return () => queryClient.setQueryData(["getInstagramAddedLinks", user_id], previousLinks);
// //       },
// //       // Optionally, refetch the data after successful mutation
// //       onSuccess: () => {
// //         queryClient.invalidateQueries(["getInstagramAddedLinks", user_id]);
// //       },
// //     }
// //   );

// //   return mutation;
// // }
