import React, { useState } from "react";
import { Button } from "antd";
import { Tooltip } from "react-tooltip";
import "./ManageLinks.css";
import { useLoadUser } from "../../../Utils/CustomHooks/useLoadUser";
import SocialLinks from "../../../Components/SocialLinks/SocialLinks";
import AccountsIntegration from "../../../Components/AccountsIntegration/AccountsIntegration";
import { StaticVariables } from "../../../StaticVariables";
import AccountSkeleton from "../../../Components/skeleton/DashboardView/accountSkeleton";

const ManageLinks = (props: any) => {
  const { username,user_id } = props;
console.log("ud",username)
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);

  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);

  return (
    <div>
      {userLoading ? (
        <AccountSkeleton />
      ) : (
        <>
        <div className="d-side">
          <div className="details">
            <h1 className="dashboard-mobile-hide">
              {StaticVariables().MANAGE_ALL_LINKS_TITLE}
            </h1>
            <div className="links-wrapper">
              <div className="account-values-profiles">
                <div className="account-social-logins-heading">
                  <h5>
                    My Links{" "}
                    <Button
                      onClick={() => setIsLinksModalOpen(true)}
                      className="add-link"
                    >
                      Add Link
                    </Button>
                    {/* <BsPlusCircle onClick={() => setIsLinksModalOpen(true)} /> */}
                  </h5>
                </div>
                <AccountsIntegration
                  isLinksModalOpen={isLinksModalOpen}
                  setIsLinksModalOpen={setIsLinksModalOpen}
                  username={username}
                />
              </div>

              <div className="account-values-profiles">
                <div className="account-social-logins-heading">
                  <h5>Social Links</h5>
                </div>
                <SocialLinks />
              </div>
            </div>
          </div>
          </div>
        </>
      )}
      <Tooltip
        className="tool-tip"
        id="displayName"
        place={"top"}
        offset={4}
        html="How your name will be shown on your page."
      />
      <Tooltip
        className="tool-tip"
        id="username"
        place={"top"}
        offset={4}
        html="This is your Bubble username."
      />
      <Tooltip
        className="tool-tip"
        id="email"
        place={"top"}
        offset={4}
        html="This is the email connected to your account."
      />
      <Tooltip
        className="tool-tip"
        id="phone"
        place={"top"}
        offset={4}
        html="This is the phone number connected to your account."
      />
    </div>
  );
};

export default ManageLinks;
