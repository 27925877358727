import { useState, useEffect } from "react";
import { Col, Row, Slider } from "antd";

import { StaticVariables } from "../../../../../StaticVariables";
import { fetchFollowerControls, updateMaxHashtagsLive } from "../../ControlsDatabase";

const HashtagSlider = (props: any) => {
  const user_id = props.user_id;
  const page = "follower";
  const [inputValue, setInputValue] = useState(5);
  const [totalHashtag, setTotalHashtag] = useState<any>();
  const minimum = StaticVariables().MIN_RANGE;

  useEffect(() => {
    fetchMaxHashtag();
  }, []);
  async function fetchMaxHashtag() {
    const maxTopHashtags = await fetchFollowerControls(user_id);
    if (maxTopHashtags) {
      let max = maxTopHashtags.totalHashtags - 0;
      setTotalHashtag(max);
      setInputValue(maxTopHashtags.maxHashtags);
    }
  }

  const onChange = async (newValue: number) => {
    setInputValue(newValue);
    updateMaxHashtagsLive(user_id, newValue).then(() => {
      fetchMaxHashtag();
    });
  };

  return (
    <div>
      <Row>
        <span className="min-range">{minimum}</span>
        <Col span={18}>
          <Slider
            min={minimum}
            max={totalHashtag}
            onChange={onChange}
            value={inputValue}
            tooltip={{ placement: "bottom" }}
          />
        </Col>
        {"   "}
        <span className="max-range">{totalHashtag}</span>
      </Row>
    </div>
  );
};

export default HashtagSlider;
