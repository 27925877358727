import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../Config/SupabaseClient";

/**
 * Load content
 */
export function useLoadSocialLinks(user_id: string) {
  async function getSocialLinks({ pageParam = 1 }) {
    const { data, error }: any = await supabase
      .from("user")
      .select("*")
      .eq("user_id", user_id);


    if (data && data.length >= 0 && Array.isArray(data)) {
    	return data[0];
    } else if (error) {
    	throw new Error(error.message);
    } else {
    	throw new Error("Failed to fetch posts");
    }
  }

  return useQuery(["getSocialLinks", user_id], getSocialLinks, {
    enabled: Boolean(user_id),
  });
}
