import { useInfiniteQuery, useQuery } from 'react-query';
import { supabase } from '../../../Config/SupabaseClient';

export function useLoadBrokenLinksContent(user_id: string, username: any,currentPage:any,postsPerPage:any,totalPages:any,filter:any) {
  const fetchBrokenLinksCount = async () => {
    // const { data, error } = await supabase
    //   .from(`${username}`)
    //   .select('*')
    //   .eq("user_id", user_id)
    //   .eq("shoplink_broken", true);
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    const { data, error } = await supabase.rpc(
      "get_brokenlinksss",
      {
        query_string:filter? [`%%${filter}%%`]:[],
        userid: user_id,

        username: username,
      
        index_type: 'archived',
        start_number:start,
        end_number:end
      }
    );
   
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };
  // const { data: pages, isLoading, isError, error, refetch } = useQuery(
  //   ['brokenLinksCount'],
  //   fetchBrokenLinksCount,{
  //     enabled:Boolean(user_id)
  //   }
  // );

  // return { pages, isLoading, isError, error, refetch };
  return useInfiniteQuery(
    ["brokenLinksCount", currentPage],
    fetchBrokenLinksCount,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),
    }
  );
//   const { data: value, isLoading, isError, error,refetch } = useQuery(
//     'brokenLinksCount',
//     fetchBrokenLinksCount
//   );

//   return { pages: value, isLoading, isError, error,refetch };
}
