import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

// Use this component to redirect authenticated users to their dashboard
export default function AuthRoute({ Component }: any) {
  const { session, isOnboardingFinished, username } = useAuth();
  console.log(session, isOnboardingFinished, username);
  const navigate = useNavigate();


  useEffect(() => {
    if (session && isOnboardingFinished && username) {
      navigate(`/dashboard`);
    }
    // if(session===null){
    // 	navigate(`/login`);
    // }
  }, [isOnboardingFinished, username, session]);

  return <Component />;
}
