import axios from "axios";
import React, { useState } from "react";
import { supabase } from "../../../../Config/SupabaseClient";
import { Button } from "antd";

const YoutubeContent = (props: any) => {
  const { selectedUser } = props;
  const [youtubeChannelId,setYoutubeChannelId]=useState("")
  type ResYtStoreContent = {
    error: boolean;
    message: string;
  };
  async function storeYoutubeChannel(
    // user_id: string,
    // channel_id: string,
    // username: any
  ) {
    const res = {
      error: false,
      message: "",
    };
    const { data: user }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", selectedUser);
    // console.log("user",user)
    try {
      const url = `https://dev.thesearchbubble.com/api/youtube/storeVideosByChannelId`;
      // const url = `https://dev.thesearchbubble.com/api/youtube/storeVideosByChannelId`;
      const resServer = await axios.post(
        url,
        { userId: user?.[0]?.user_id, channelId: youtubeChannelId, username: user?.[0]?.username },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { success, message } = resServer.data;

      if (!success) {
        res.error = true;
        res.message = message;
        // return res;
        alert (`Error in youtube integration :- ${message}`)
      }else{
        alert ("Integrating Youtube.")
      }
      const embedUrl = `https://dev.thesearchbubble.com/insertEmbed`;
      // const embedUrl = `https://dev.thesearchbubble.com/insertEmbed`;

      try {
        const resEmbed = await axios.post(
          embedUrl,
          {
            id: user?.[0]?.user_id,
            user: user?.[0]?.username,
          }
          // {
          //   headers: {
          //     "content-type": "application/json",
          //   },
          // }
        );
        if (resEmbed.data === "done") {
          console.log("Please wait !!!");
          // setTimeout(async () => {
            const { data: update, error } = await supabase
              .from("user")
              .update({ show_youtube: true ,is_youtube_on:true})
              .eq("user_id", user?.[0]?.user_id);
          // }, 10000);
        }

        // Continue with the next steps if needed
      } catch (error) {}

      return res;
    } catch (error: any) {
      res.error = true;
      if (
        error &&
        error?.response &&
        error.response?.data &&
        error.response.data?.message
      ) {
        res.message = error.response.data.message;
        alert(`Youtube Integration :- ${res.message}`)
      } else {
        alert("Youtube integration :- Server error: please try again later or contact our support team.")
        // res.message =
        //   "Server error: please try again later or contact our support team.";
      }
      return res;
    }
  }
  return (
    <>
        <div className="content-wrapper">


    <span className="content-block">
      <input
        required
        className="caption-input input-value"
        type="text"
        placeholder="enter youtube channel id"
        onChange={(e: any) => setYoutubeChannelId(e.target.value)}
        value={youtubeChannelId}
      />
    </span>
    <Button
        type="primary"
        className="upload-btn primary-color"
        onClick={storeYoutubeChannel}
      >
        {" "}
        Upload Files{" "}
      </Button>  
      </div>  </>
  );
};

export default YoutubeContent;
