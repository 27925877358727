import { useQueries, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { getCustomCategoriesSearchesCount, getHashtagSearchesCount, getInstagramHighlightsSearchesCount, getInstagramLinksSearchesCount, getPopularSearchesCount, getSearchbarSearchesCount } from "../../../../SupabaseServices/SearchesDatabase";
import { getPageViews, getUniqueUsers } from "../../../../SupabaseServices/AnalyticsDatabase";


function useOverviewAnalytics(
  user_id: any,
  startDate: any,
  endDate: any,
  username?:any,
  customCategories?: any[],
  instagramLinks?:any[],
) {
  //Based on usernames
  const { data: uniqueUsers, isLoading: loadingUniqueUsers } = useQuery(
    ["overview-unique-users", username, startDate],
    () => {
      return getUniqueUsers(username, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );
  const { data: pageViews, isLoading: loadingPageViews } = useQuery(
    [`overview-page-views`, username, startDate],
    () => {
      return getPageViews(username, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );
  // const { data: pageAnalytics, isLoading: loadingPageAnalytics } = useQuery(
  //   [`overview-page-analytics`, username, startDate],
  //   () => {
  //     return getPageAnalytics(username, startDate, endDate, user_id);
  //   },
  //   { refetchOnWindowFocus: true }
  // );
  const { data: searchBarSearches, isLoading: loadingSearchBarSearches } =
    useQuery(
      ["overview-search-bar-searches", username, startDate],
      () => {
        return getSearchbarSearchesCount(username, startDate, endDate, user_id);
      },
      { refetchOnWindowFocus: true }
    );

  const { data: hashtagSearches, isLoading: loadingHashtagSearches } = useQuery(
    ["overview-hashtag-searches", username, startDate],
    () => {
      return getHashtagSearchesCount(username, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );
  

  // const { data: recentSearches, isLoading: loadingRecentSearches } = useQuery(
  // 	["overview-recent-searches", username, startDate],
  // 	() => {
  // 		return getRecentSearchesCount(username, startDate, endDate,user_id);
  // 	},
  // 	{ refetchOnWindowFocus: true }
  // );
  const { data: popularSearches, isLoading: loadingPopularSearches } = useQuery(
    ["overview-popular-searches", username, startDate],
    () => {
      return getPopularSearchesCount(username, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );
  const { data: instagramHighlightsSearches, isLoading: loadinginstagramHighlightsSearches } = useQuery(
    ["overview-instagramHighlights-searches", username, startDate],
    () => {
      return getInstagramHighlightsSearchesCount(username, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );


  const { data: instagramLinksSearches, isLoading: loadinginstagramLinksSearches } = useQuery(
    ["overview-instagramLinks-searches", username, startDate],
    () => {
      return getInstagramLinksSearchesCount(username, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );
  const {
    data: customCategoriesSearches,
    isLoading: loadingCustomCategoriesSearches,
    refetch:refetchCustomCategories
  }:any = useQuery(
    ["overview-custom-categories-searches", username, startDate,customCategories],
    () => {
      return getCustomCategoriesSearchesCount(
        username,
        customCategories,
        startDate,
        endDate,
        user_id
      );
    },
    {
      
      refetchOnWindowFocus: true,
      // enabled:Boolean(customCategories)
    }
  );
  // const queries = useQueries(
  //   customCategories && customCategories.map((category) => ({
  //     queryKey: ["overview-custom-category-searches", username, startDate, category.name],
  //     queryFn: () => getCustomCategoriesSearchesCount(username, [category], startDate, endDate, user_id),
  //     config: {
  //       refetchOnWindowFocus: true,
  //       enabled: Boolean(category),
  //     },
  //   }))
  // );

  // Access the data and loading state for each query
  // const customCategoriesData = queries.map((query) => ({
  //   customCategorySearches: query.data,
  //   loadingCustomCategorySearches: query.isLoading,
  //   refetchCustomCategory: query.refetch,
  // }));
  
  


  // const {
  //   data: instagramLinksSearches,
  //   isLoading: loadingInstagramLinksSearches,
  //   refetch:refetchInstagramLinksSearches
  // } = useQuery(
  //   ["overview-instagram-links-searches", username, startDate,instagramLinks],
  //   () => {
  //     return getInstagramLinksSearchesCount(
  //       username,
  //       instagramLinks,
  //       startDate,
  //       endDate,
  //       user_id
  //     );
  //   },
  //   {
      
  //     refetchOnWindowFocus: true,
  //   }
  // );
  

  return {
    loadingUniqueUsers,
    uniqueUsers,
    loadingPageViews,
    pageViews,
    loadingHashtagSearches,
    hashtagSearches,
    loadingSearchBarSearches,
    searchBarSearches,
    // loadingRecentSearches,
    // recentSearches,
    loadingPopularSearches,
    instagramHighlightsSearches,
    loadinginstagramHighlightsSearches,
    popularSearches,
    customCategoriesSearches,
    loadingCustomCategoriesSearches,
    loadinginstagramLinksSearches,
    instagramLinksSearches,
    // loadingPageAnalytics,
    // pageAnalytics
  };
}

export default useOverviewAnalytics;
