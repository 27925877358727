import { BsSearch, BsX } from "react-icons/bs";

import "./SearchInput.css";

type Props = {
	placeholder: string;
	id: string;
	handleKeyUp: any;
	isActiveFilter: boolean;
	onSearchClick: () => void;
	onClearClick: () => void;
};
export default function SearchInput({
	placeholder,
	id,
	handleKeyUp,
	isActiveFilter,
	onSearchClick,
	onClearClick,
}: Props) {
	return (
		<div className='search-input-wrapper'>
			<input
				type='text'
				id={id}
				enterKeyHint='search'
				placeholder={placeholder}
				onKeyUp={handleKeyUp}
				className='search-input-box'
			/>

			{!isActiveFilter ? (
				<button className='search-input-icon-btn' onClick={onSearchClick}>
					<BsSearch />
				</button>
			) : (
				<button className='search-input-icon-btn' onClick={onClearClick}>
					<BsX />
				</button>
			)}
		</div>
	);
}
