import React, { useState } from "react";
import { supabase } from "../../../../Config/SupabaseClient";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";
import axios from "axios";

const DMsContent = (props: any) => {
  const { username } = props;
  console.log("user", username);
  const [jsonData, setJsonData] = useState(null);

  const handleFileUpload = async (event: any) => {
    const userData: any = await getUserData(username);

    const file = event.target.files[0];
    console.log("Selected file:", file);

    const reader = new FileReader();

    // Callback function for when file reading is complete
    reader.onload = async (e: any) => {
      console.log("File reading completed");
              console.log("fike", e.target.result);

    //   try {
    //     console.log("fike", e.target.result);
    //     const data = JSON.parse(e.target.result);
    //     console.log("Parsed JSON data:", data, data.comments_v2);
    //     for (let [i, d] of data.comments_v2.entries()) {
    //       console.log(i, d, d?.attachments, d?.data, d?.title);
    //       const { data } = await supabase
    //         .from("content_comments")
    //         .select("*")
    //         .eq("post_id", d.timestamp)
    //         .eq("user_id", userData.user_id);
    //       if (data) {
    //         if (data.length === 0) {
    //           const post_date=`${Math.floor(Math.random() * 100) + 1900}-${String(
    //             Math.floor(Math.random() * 12) + 1
    //           ).padStart(2, "0")}-${String(Math.floor(Math.random() * 28) + 1).padStart(
    //             2,
    //             "0"
    //           )} ${String(Math.floor(Math.random() * 24)).padStart(2, "0")}:${String(
    //             Math.floor(Math.random() * 60)
    //           ).padStart(2, "0")}:${String(Math.floor(Math.random() * 60)).padStart(
    //             2,
    //             "0"
    //           )}+00`
    //           const { data: content } = await supabase
    //             .from("content_comments")
    //             .insert([
    //               {
    //                 post_id: d.timestamp,
    //                 user_id: userData.user_id,
    //                 type: "COMMENT",
    //                 permalink:
    //                   d?.attachments?.[0]?.data?.[0]?.external_context?.url,
    //                 author: d?.data?.[0]?.comment?.author,
    //                 comment: d?.data?.[0]?.comment?.comment,
    //                 title: d?.title,
    //                 post_date:post_date
                    
    //               },
    //             ])
    //             .select("*");

    //           if (content && content.length) {
    //             const { data, error } = await supabase
    //               .from("content_new")
    //               .insert([
    //                 {
    //                   post_date:post_date,
    //                   post_id: d.timestamp,
    //                   user_id: userData.user_id,
    //                   type: "COMMENT",
    //                   permalink:
    //                     d?.attachments?.[0]?.data?.[0]?.external_context?.url,
    //                   caption: d?.data?.[0]?.comment?.comment,
    //                   metadata: JSON.stringify({
    //                     author: d?.data?.[0]?.comment?.author,
    //                     title: d?.title,

    //                   }),
    //                   platform: "instaComment",
    //                   raw_content_id: content[0].id,

    //                   combined_text: d?.data?.[0]?.comment?.comment || " ",
    //                 },
    //               ]);

    //             console.log("comment uploaded", i, data, d.timestamp, error);
    //           }
    //         }
    //       }
    //     }
    //     console.log("All async operations inside the loop completed");

    //     console.log("wait...");
    //     const time = 1000;
    //     console.log(time);
    //     setTimeout(async () => {
    //       const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
    //       // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;
    
    //       const resThumbnail = await axios.post(
    //         thumbnailUrl,
    //         { id: userData.user_id, user: userData.username, from: "content" },
    //         {
    //           headers: {
    //             "content-type": "application/json",
    //           },
    //         }
    //       );
    //       console.log("res", resThumbnail.data);
    //       if (resThumbnail.data === "done") {
    //         console.log(
    //           "Processing your data : Thumbnails, tags, carausel, embeddings"
    //         );
    //         console.log("Please wait !!!");  }
    //       }, time);
    //     setJsonData(data);
    //   } catch (error) {
    //     console.error("Error parsing JSON file:", error);
    //     setJsonData(null);
    //   }
    };

    // Read the file as text
    reader.readAsText(file);
  };

  return (
    <div>
      <h2>DM</h2>
      <input type="file" accept=".json" onChange={handleFileUpload} />
      <div>
        {/* <h3>JSON Data</h3>
        {jsonData ? (
          <pre>{JSON.stringify(jsonData, null, 2)}</pre>
        ) : (
          <p>No JSON data to display</p>
        )} */}
      </div>
    </div>
  );
};

export default DMsContent;






