import { useQuery } from "react-query";

import { useRef, useState } from "react";
import { supabase } from "../../../Config/SupabaseClient";
import { getExtraNecIds } from "../../../SupabaseServices/ContentDatabase";
// import { useSearchData } from "./useLoadSearchbarData";

/**
 * Load content
 */

export function useLoadNecData(
  userIds: any[],
  index: string,
  postsPerPage: number,
  filter: any,
  usernames: any
) {
  async function getPaginatedFeed() {
    let data,
      error = null;
    // if (queryTerm !== isNecCalledRef.current.currentQueryTerm) {
    //   // If the query term has changed, reset isNecCalledRef.current to false
    //   isNecCalledRef.current.current = false;
    //   isNecCalledRef.current.currentQueryTerm = queryTerm;
    // }
    if (userIds && userIds.length === 1) {
      if (filter) {
        const { data:nec } = await supabase
          .from("user")
          .select("is_nec_present")
          .eq("user_id", userIds[0]);
        if (nec && nec[0] && nec[0].is_nec_present === true) {
          let extraIds;
          // if (!isNecCalledRef.current.current) {
          const { data: nec, error: necError } = await supabase
            .from("nec_response")
            .select("*")
            .eq("query", filter)
            .eq("username", usernames[0])
            .eq("user_id", userIds[0]);
          if (nec && nec.length !== 0) {
            extraIds = JSON.parse(nec[0].nec_data);
          } else {
            extraIds = await getExtraNecIds(filter, usernames[0]);
            if (extraIds && extraIds.length) {
              await supabase.from("nec_response").insert([
                {
                  nec_data: extraIds,
                  username: usernames[0],
                  user_id: userIds[0],
                  query: filter,
                },
              ]);
            }
          }
          const { data: postData, error: postError } = await supabase.rpc(
            "get_getnecfeedddt",
            {
              userid: userIds[0],
              user_name: usernames[0],
              extraids: extraIds,
            }
          );
          data = postData;
          error = postError;
          // if (postData) {
          //   totalResult.unshift(...postData);

          // isNecCalledRef.current.current = true;
          // }
          // }
        }
        const resultArray = data ? [...data] : [];
        if (
          resultArray &&
          resultArray.length >= 0 &&
          Array.isArray(resultArray)
        ) {
          return resultArray;
        } else if (error) {
          throw new Error(error.message);
        } else {
          throw new Error("Failed to fetch posts");
        }
      }
    }
  }

  return useQuery(["necFeed", filter, userIds], getPaginatedFeed, {
    enabled: Boolean(filter),
  });
}
