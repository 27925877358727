import React, { useState } from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';

const DropdownComponent = (props: any) => {
  const { items ,setSelectedUser} = props;
  const [selectedItem, setSelectedItem] = useState(items[0]?.label || 'Dropdown');

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    message.info('Click on left button.');
    console.log('click left button', e);
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.log('click', e);
    const clickedItem = items.find((item: any) => item.key === e.key);
    if (clickedItem) {
      setSelectedItem(clickedItem.label);
      
      setSelectedUser(clickedItem)
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Space wrap>
      <Dropdown.Button menu={menuProps} onClick={handleButtonClick}>
        {selectedItem}
      </Dropdown.Button>
    </Space>
  );
};

export default DropdownComponent;
