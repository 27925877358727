import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { supabase } from "../../../Config/SupabaseClient";

// Hook for deleting posts
export default function useDeletePosts() {
  const queryClient = useQueryClient();
  const { username } = useParams();
  const deleteContent = async ({
    postId,user_id
  }: {
    postId: string;
    activeKey: string;
    pageIndex: number;
    postIndex: number;
    filter: string | null;
	user_id:any
  }) => {
    // const { error } = await supabase
    // 	.from("content_new")
    // 	.delete()
    // 	.eq("post_id", postId);

    const { error } = await supabase
      .from(`${username}`)
      .delete()
      .eq("post_id", postId);
	  const { error:productError} = await supabase
	  .from('product_bank')
	  .delete()
	  .eq("post_id", postId).eq('user_id',user_id);
    if (error) {
      throw new Error(error.message);
    } else {
      return true;
    }
  };

  // Tag optimistic update
  return useMutation(deleteContent, {
    // When mutate is called:
    onMutate: async ({ pageIndex, postIndex, activeKey, filter }) => {
      await queryClient.cancelQueries(["paginatedPosts", activeKey, filter]);
      const previousData = queryClient.getQueryData([
        "paginatedPosts",
        activeKey,
        filter,
      ]);
      if (previousData) {
        // Deep clone the object, otherwise the objects inside will have the same reference in memory
        const newData = JSON.parse(JSON.stringify(previousData)) as {
          pageParams: any;
          pages: any[];
        };

        newData.pages[pageIndex].splice(postIndex, 1);

        queryClient.setQueryData(
          ["paginatedPosts", activeKey, filter],
          newData
        );
      }
      return { previousData };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error, { activeKey, filter }, context) => {
      queryClient.setQueryData(
        ["paginatedPosts", activeKey, filter],
        context?.previousData
      );
    },
    // Always refetch after error or success:
    onSettled: async (_data, _error, { activeKey, filter }) => {
      queryClient.invalidateQueries(["paginatedPosts", activeKey, filter], {
        refetchPage: () => {
          return true;
        },
      });
      queryClient.invalidateQueries(["paginatedPosts", filter], {
        refetchPage: () => {
          return true;
        },
      });
    },
  });
}
