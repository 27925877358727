import { useQueries, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  getMultipleUsersPostClicksViewsAnalytics,
  getPostClicksAnalytics,
  getPostClicksViewsAnalytics,
  getTotalPageViews,
  getTotalSearchesCount,
  getTotalShoppableClickCount,
  getTotalUniqueUsers,
  getUniqueUsers,
  getpreviousTotalPageViews,
  getpreviousTotalSearchesCount,
  getpreviousTotalShoppableClickCount,
  getpreviousTotalUniqueUsers,
} from "../../../SupabaseServices/AnalyticsDatabase";

function useCompanyAnalytics(
  user: any,
  startDate: any,
  endDate: any,
  previousStartDate: any,
  previousEndDate: any,
  isYesterday: any,
  daysNumber: any
) {
  //Based on usernames
  const { data: uniqueUsers, isLoading: loadingUniqueUsers } = useQuery(
    ["overview-unique-users", user?.label, startDate],
    () => {
      return getTotalUniqueUsers(user, startDate, endDate);
    },
    { refetchOnWindowFocus: true }
  );

  const { data: pageViews, isLoading: loadingPageViews } = useQuery(
    [`overview-page-views`, user?.label, startDate],
    () => {
      return getTotalPageViews(user, startDate, endDate);
    },
    { refetchOnWindowFocus: true }
  );
  const { data: searchesCount, isLoading: loadingSearchesCount } = useQuery(
    [`overview-searches-count`, user?.label, startDate],
    () => {
      return getTotalSearchesCount(user, startDate, endDate);
    },
    { refetchOnWindowFocus: true }
  );
  const { data: shoppableClickCount, isLoading: loadingshoppableClickCount } =
    useQuery(
      [`overview-shoppable-click-count`, user?.label, startDate],
      () => {
        return getTotalShoppableClickCount(user, startDate, endDate);
      },
      { refetchOnWindowFocus: true }
    );

  const { data: previousuniqueUsers, isLoading: loadingpreviousUniqueUsers } =
    useQuery(
      ["overview-previous-unique-users", user?.label, previousStartDate],
      () => {
        return getpreviousTotalUniqueUsers(
          user,
          previousStartDate,
          previousEndDate
        );
      },
      { refetchOnWindowFocus: true }
    );

  const { data: previouspageViews, isLoading: loadingpreviousPageViews } =
    useQuery(
      [`overview-previous-page-views`, user?.label, previousStartDate],
      () => {
        return getpreviousTotalPageViews(
          user,
          previousStartDate,
          previousEndDate
        );
      },
      { refetchOnWindowFocus: true }
    );
  const {
    data: previoussearchesCount,
    isLoading: loadingpreviousSearchesCount,
  } = useQuery(
    [`overview-previous-searches-count`, user?.label, previousStartDate],
    () => {
      return getpreviousTotalSearchesCount(
        user,
        previousStartDate,
        previousEndDate
      );
    },
    { refetchOnWindowFocus: true }
  );
  const {
    data: previousshoppableClickCount,
    isLoading: loadingpreviousshoppableClickCount,
  } = useQuery(
    [`overview-previous-shoppable-click-count`, user?.label, previousStartDate],
    () => {
      return getpreviousTotalShoppableClickCount(
        user,
        previousStartDate,
        previousEndDate
      );
    },
    { refetchOnWindowFocus: true }
  );

  // const { data: postClicksAnalytics, isLoading: loadingPostClicksAnalytics } =
  //   useQuery(
  //     [`overview-postClicks-analytics`, user?.label, startDate],
  //     () => {
  //       return getPostClicksAnalytics(
  //         user,
  //         startDate,
  //         endDate,
  //         user,
  //         isYesterday,
  //         daysNumber
  //       );
  //     },
  //     { refetchOnWindowFocus: true }
  //   );

  const {
    data: postClicksViewsAnalytics,
    isLoading: loadingPostClicksViewsAnalytics,
  } = useQuery(
    [`overview-postClicksViews-analytics`, user?.label, startDate],
    () => {
      // return getPostClicksViewsAnalytics(
      //   user,
      //   startDate,
      //   endDate,
      //   user,
      //   isYesterday,
      //   daysNumber
      // );
      return getMultipleUsersPostClicksViewsAnalytics(
        user,
        startDate,
        endDate,
        user,
        isYesterday,
        daysNumber
      );
    },
    { refetchOnWindowFocus: true }
  );

  return {
    loadingUniqueUsers,
    uniqueUsers,
    loadingPageViews,
    pageViews,
    loadingSearchesCount,
    searchesCount,
    shoppableClickCount,
    loadingshoppableClickCount,
    previousuniqueUsers,
    loadingpreviousUniqueUsers,
    previouspageViews,
    loadingpreviousPageViews,
    previoussearchesCount,
    loadingpreviousSearchesCount,
    previousshoppableClickCount,
    loadingpreviousshoppableClickCount,
    postClicksViewsAnalytics,
    loadingPostClicksViewsAnalytics,
  };
}

export default useCompanyAnalytics;
